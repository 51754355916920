import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { ApiService } from './../../_services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recuperar',
  templateUrl: './recuperar.component.html',
  styleUrls: ['./recuperar.component.css']
})
export class RecuperarComponent implements OnInit {

  iniciarForm: FormGroup;
  isLoading: Boolean = false;
  message: String = '';

  constructor( private modal: NgbModal,private api: ApiService,private toastr: ToastrService) { 
    this.iniciarForm = new FormGroup({
      correo: new FormControl('', [Validators.required, Validators.email]),
      dev: new FormControl(environment.dev),
    });
   }

  ngOnInit(): void {
  }

  recuperar() {
    this.api.postResponse('recuperar/', false, this.iniciarForm.value).pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.toastr.success('LatinosVentas', 'Se ha enviado un correo a ' +  this.iniciarForm.controls['correo'].value);
          this.modal.dismissAll();
        }, err => {
          console.log(err);
          this.toastr.error('LatinosVentas', err.error.text);
          this.modal.dismissAll();
        });

  }

}
