import { AuthService } from './../../_services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApiService } from './../../_services/api.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-mensajes',
  templateUrl: './mensajes.component.html',
  styleUrls: ['./mensajes.component.css']
})
export class MensajesComponent implements OnInit {


  @ViewChild('scrollchat') private myScrollContainer: ElementRef;
  currentData: any;
  mensajeChat: any = '';
  idVendedor: string;
  idAnuncio: string;
  conversacion: any;
  hayMensajes: boolean = false;
  conversacionDetalle: any;
  mensajeForm: FormGroup;
  idMensaje: any;
  idUsuario: string;
  currentItem: any;
  currenUser:any = localStorage.getItem('idUsuario');
  subscription: Subscription;
  search:string ='';
  constructor(private api: ApiService, private auth:AuthService) {
    this.mensajeForm = new FormGroup({
      mensaje: new FormControl('', [Validators.required]),
    });
    const source = interval(10000);
    this.subscription = source.subscribe(val => {
      if (this.search == '') {
        if (this.auth.isAuthenticated()) {
          // this.cargarMisMensajes();
        }
      }
    });

  }

  ngOnInit(): void {
    this.cargarMisMensajes();
  }

  abrirConversacion(item) {
    this.currentItem = item;
    this.idVendedor = item.vendedorId._id;
    this.idMensaje = item._id;
    this.idAnuncio = item.anuncioId._id;
    this.idUsuario = item.usuarioId._id;

    if (this.idMensaje == undefined) {
      this.api.getResponse('mensajesDirecto/' + localStorage.getItem('idUsuario') + '/' + this.idVendedor + '/' + this.idAnuncio, true).pipe(first())
        .subscribe(
          data => {
            this.conversacionDetalle = [];
            this.conversacionDetalle = data;
            setTimeout(() => {
              this.scrollToBottom();
            }, 10);
          }, err => {
          });
    }
    else {
      this.api.getResponse('mensajes/' + this.idMensaje, true).pipe(first())
        .subscribe(
          data => {
            this.conversacionDetalle = [];
            this.conversacionDetalle = data;
            setTimeout(() => {
              this.scrollToBottom();
            }, 10);
          }, err => {
          });

    }
  }

  hole() {
    this.api.getResponse('vistoNotificacion/' + this.idMensaje + '/' + localStorage.getItem('idUsuario'), true).pipe(first())
      .subscribe(
        data => {
          this.cargarMisMensajes();
        }, err => {
        });
  }

  cargarMisMensajes() {
    this.api.getResponse('mismensajes/' + localStorage.getItem('idUsuario'), true).pipe(first())
      .subscribe(
        data => {
          this.conversacion = data.filter(p=> p.anuncioId != null);
          if (this.conversacion.length > 0) {
            this.hayMensajes = true;
          }
          var mensajeFiltrado = [];
          this.conversacion.forEach(item => {
            if(item!.vendedorId){
            if(item.vendedorId._id == this.currenUser && !item.ocultarVendedor){
              mensajeFiltrado.push(item);
            }
          }
          if(item!.usuarioId){
            if(item.usuarioId._id == this.currenUser && !item.ocultarUsuario){
              mensajeFiltrado.push(item);
            }
          }
            this.conversacion = mensajeFiltrado;
          });
        }, err => {
          console.log(err);
          this.conversacion = [];
        });
  }

  getId() {
    return localStorage.getItem('idUsuario');
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  enviar() {
    let params = {
      usuarioId: this.idUsuario,
      vendedorId: this.idVendedor,
      anuncioId: this.idAnuncio,
      mensaje: this.mensajeForm.controls['mensaje'].value
    }

    if (params.usuarioId != localStorage.getItem('idUsuario')) {
      params.usuarioId = this.idVendedor;
      params.vendedorId = this.idUsuario;
    }
    else {
      params.usuarioId = localStorage.getItem('idUsuario');
      params.vendedorId = this.idVendedor;
    }
    // console.log(params);

    this.api.postResponse('enviarmensaje/' + localStorage.getItem('idUsuario'), true, params).pipe(first())
      .subscribe(
        data => {
          let id = '';
          if (params.usuarioId == localStorage.getItem('idUsuario')) {
            id = params.vendedorId;
          }
          else {
            id = params.usuarioId;
          }
          let param = {
            idUsuario: params.vendedorId,
            mensaje: this.mensajeForm.controls['mensaje'].value
          }
          this.api.postResponse('notificacion', true, param).pipe(first())
            .subscribe(
              data => {
                this.mensajeForm.controls['mensaje'].setValue('');
              }, err => {
              });
          this.mensajeChat = '';
          this.abrirConversacion(this.currentItem);
          this.mensajeForm.controls['mensaje'].setValue('');
        }, err => {
        });
  }

  cargarMensajes() {
    if (this.idMensaje == undefined) {
      this.api.getResponse('mensajesDirecto/' + localStorage.getItem('idUsuario') + '/' + this.idVendedor + '/' + this.idAnuncio, true).pipe(first())
        .subscribe(
          data => {
            this.conversacion = [];
            this.conversacion = data;

          }, err => {
          });
    }
    else {
      this.api.getResponse('mensajes/' + this.idMensaje, false).pipe(first())
        .subscribe(
          data => {
            this.conversacion = [];
            this.conversacion = data;

          }, err => {
          });

    }
  }

  eliminar(id) {
    Swal.fire({
      title: '¿Deseas eliminar esta conversación?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.api.deleteResponse('eliminarMensaje/' + id + '/' + localStorage.getItem('idUsuario'), true).pipe(first())
          .subscribe(
            data => {
              Swal.fire('Latinosventas', 'Se ha eliminado!', 'success')
              this.cargarMisMensajes();
              this.conversacionDetalle = false;
            }, err => {
            });

      } else if (result.isDenied) {
        Swal.fire('No se ha podido eliminar', '', 'info')
      }
    })
  }

  buscar(){
    this.conversacion = [];
    this.api.getResponse('mismensajes/' + localStorage.getItem('idUsuario'), true).pipe(first())
    .subscribe(
      data => {
        data.forEach(element => {
          if(element.anuncioId.titulo.includes(this.search.toLocaleLowerCase())){
            this.conversacion.push(element);
          }
        });
        // this.conversacion = data;
        if (this.conversacion.length > 0) {
          this.hayMensajes = true;
        }
        var mensajeFiltrado = [];
        this.conversacion.forEach(item => {
          if(item.vendedorId._id == this.currenUser && !item.ocultarVendedor){
            mensajeFiltrado.push(item);
          }
          if(item.usuarioId._id == this.currenUser && !item.ocultarUsuario){
            mensajeFiltrado.push(item);
          }
          this.conversacion = mensajeFiltrado;
        });
      }, err => {
      });
  }

  ObtenerUsername(nom) {
    if (nom == undefined)
      return 'Anónimo'
    else
      return nom.substr(0, nom.indexOf('@'))
  }
}
