import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { finalize, first, tap } from 'rxjs/operators';
import { ApiService } from './../../_services/api.service';
import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';

import { Observable } from "rxjs";
import { NgxImageCompressService } from 'ngx-image-compress';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-editaranuncio',
  templateUrl: './editaranuncio.component.html',
  styleUrls: ['./editaranuncio.component.css']
})
export class EditaranuncioComponent implements OnInit {
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  categorias: any = [];
  subcategorias: any = [];
  // registerForm: FormGroup;
  isLoading: Boolean = false;
  message: String = '';
  messageSuccess: String = '';
  catActual: String = '';
  subActual: String = '';

  anuncioForm: FormGroup;
  autosForm: FormGroup;
  anuncioGeneralForm: FormGroup;

  categoriaSeleccionada = -1;
  subcategoriaSeleccionada = -1;
  verAutos: boolean = false;
  verInmuebles: boolean = false;
  verTodo: boolean = false;
  imagenes: any = [];

  title = "cloudsSorage";
  selectedFile: File = null;
  fb;
  downloadURL: Observable<string>;
  imagesToUpload: any = [];

  constructor(public api: ApiService, private spinner: NgxSpinnerService, private router: Router,
    
    private imageCompress: NgxImageCompressService,
    private route: ActivatedRoute, private toastr: ToastrService,private translate:TranslateService) {
    this.anuncioForm = new FormGroup({
      _id: new FormControl('', null),
      titulo: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      precio: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+([\.][0-9]+)?$')]),
      categoriaId: new FormControl('', null),
      // subCategoriaId: new FormControl('', null),
      imagenes: new FormControl(),
      tipoInmueble: new FormControl(1, [Validators.required]),
      numHabitaciones: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+([\.][0-9]+)?$')]),
      numBanios: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+([\.][0-9]+)?$')]),
      estacionamientos: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+([\.][0-9]+)?$')]),
      superficie: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+([\.][0-9]+)?$')]),
      usuarioId: new FormControl(),
      ubicacion: new FormControl('', [Validators.required]),
      esGratuito: new FormControl(null, [Validators.required]),
      mostrarTelefono: new FormControl(null, [Validators.required]),
      geometry: new FormControl(),
    });
    this.autosForm = new FormGroup({
      _id: new FormControl('', []),
      titulo: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      precio: new FormControl('', [Validators.required]),
      categoriaId: new FormControl('', null),
      // subCategoriaId: new FormControl('', null),
      imagenes: new FormControl(),
      ubicacion: new FormControl(),
      marca: new FormControl('', [Validators.required]),
      marcaAuto: new FormControl('', [Validators.required]),
      anioAuto: new FormControl('', [Validators.required]),
      transmision: new FormControl('', [Validators.required]),
      combustible: new FormControl('', [Validators.required]),
      kilometraje: new FormControl('', [Validators.required]),
      usuarioId: new FormControl(),
      esGratuito: new FormControl('', [Validators.required]),
      mostrarTelefono: new FormControl('', [Validators.required])
    });
    this.anuncioGeneralForm = new FormGroup({
      _id: new FormControl('', []),
      titulo: new FormControl('', [Validators.required]),
      descripcion: new FormControl('', [Validators.required]),
      precio: new FormControl('', [Validators.required]),
      categoriaId: new FormControl('', null),
      // subCategoriaId: new FormControl('', null),
      imagenes: new FormControl(),
      usuarioId: new FormControl(),
      ubicacion: new FormControl('', [Validators.required]),
      esGratuito: new FormControl(false, [Validators.required]),
      mostrarTelefono: new FormControl(true, [Validators.required])
    });

  }

  ngOnInit(): void {
    this.api.getResponse('categorias').pipe(first()).subscribe(data => {
      this.categorias = data;
      let catInmuebles = this.categorias.find(x => x.nombre == 'Inmuebles');
      let catVehiculos = this.categorias.find(x => x.nombre == 'Vehículos');
      this.categorias.splice(this.categorias.indexOf(catInmuebles), 1);
      this.categorias.splice(this.categorias.indexOf(catVehiculos), 1);
    }, err => { console.log(err) });
    
    this.route.params.subscribe(params => {
      let id = params['id'];
      this.api.getResponse('anuncio/' + id).pipe(first()).subscribe(data => {
        this.cargarSub(data[0].categoriaId, data[0]);
      }, err => { console.log(err) });
    });
  }

  ngAfterViewInit(){
  }

  publicar() {
    this.onFileSelected(this.croppedImage);
    return;
  }

  publicarAutos(img) {
    this.spinner.show();

    if (!this.autosForm.valid) {
      Swal.fire('LatinosVentas', 'Por favor verifica los campos requeridos', 'warning');
      return;
    }

    // this.autosForm.controls['categoriaId'].setValue(this.catActual);
    // this.autosForm.controls['subCategoriaId'].setValue(this.subActual);
    if (img != undefined) {
      if (img == '') {
        Swal.fire('LatinosVentas', 'Por favor agrega una imagen a tu anuncio', 'warning');
        return;
      }

      var arrImg = img;
      this.autosForm.controls['imagenes'].setValue(arrImg);
    }
    this.autosForm.controls['usuarioId'].setValue(localStorage.getItem('_id'));
    this.autosForm.controls['ubicacion'].setValue(this.ubicacionActual);
    this.api.postResponse('editarAnuncio', false, this.autosForm.value).pipe(first())
      .subscribe(
        data => {
          this.spinner.hide();
          Swal.fire('LatinosVentas', 'Tu anuncio se ha publicado', 'success');
          this.router.navigateByUrl('Perfil/MisAnuncios');
        }, err => {
          this.spinner.hide();
          Swal.fire('LatinosVentas', 'Ha ocurrido un error al subir la imagen', 'error');
          // this.nav.navigateBack(['/login']);
        });
  }

  publicarOtros(img) {
    this.spinner.show();

    // var cat = this.anuncioGeneralForm.controls['categoriaId'].value;
    if (!this.anuncioGeneralForm.valid) {
      Swal.fire('LatinosVentas', 'Por favor verifica los campos requeridos', 'warning');
      return;
    }

    // this.anuncioGeneralForm.controls['categoriaId'].setValue(cat._id);
    // this.anuncioGeneralForm.controls['subCategoriaId'].setValue(this.subActual);


    if (img != undefined) {
      if (img == '') {
        Swal.fire('LatinosVentas', 'Por favor agrega una imagen a tu anuncio', 'warning');
        return;
      }
      var arrImg = img;
      this.anuncioGeneralForm.controls['imagenes'].setValue(arrImg);
    }

    this.anuncioGeneralForm.controls['usuarioId'].setValue(localStorage.getItem('_id'));
    this.anuncioGeneralForm.controls['ubicacion'].setValue(this.ubicacionActual);

    this.api.postResponse('editaranuncio', false, this.anuncioGeneralForm.value).pipe(first())
      .subscribe(
        data => {
          this.spinner.hide();
          Swal.fire('LatinosVentas', 'Tu anuncio se ha publicado', 'success');
          this.router.navigateByUrl('Perfil/MisAnuncios');
        }, err => {
          this.spinner.hide();
          Swal.fire('LatinosVentas', 'Ha ocurrido un error al subir la imagen', 'error');
          // this.nav.navigateBack(['/login']);
        });
  }

  publicarInmuebles(img?) {

    if (!this.anuncioForm.valid) {
      Swal.fire('LatinosVentas', 'Por favor verifica los campos requeridos', 'warning');
      return;
    }
    if (img != undefined) {
      var arrImg = img;
      this.anuncioForm.controls['imagenes'].setValue(arrImg);
    }

    this.anuncioForm.controls['usuarioId'].setValue(localStorage.getItem('_id'));
    if (this.ubicacionActual != undefined) {
      this.anuncioForm.controls['ubicacion'].setValue(this.ubicacionActual);
    }
    this.api.postResponse('editarAnuncio', false, this.anuncioForm.value).pipe(first())
      .subscribe(
        data => {
          this.spinner.hide();
          Swal.fire('LatinosVentas', 'Tu anuncio se ha publicado', 'success');
          this.router.navigateByUrl('Perfil/MisAnuncios');
        }, err => {
          this.spinner.hide();
          Swal.fire('LatinosVentas', 'Ha ocurrido un error al subir la imagen', 'error');
        });
  }

  cargarCat(id: String) {
    this.catActual = id;
    this.subActual = '';
    this.subcategoriaSeleccionada = -1;
    this.api.getResponse('subcategorias/' + id).pipe(first()).subscribe(data => {
      this.subcategorias = data;
    }, err => { console.log(err) });
  }

  ubicacionGuardad: any = '';
  cargarSub(id: any, anuncio: any) {
    if (id.nombre === "Inmuebles") {
      this.verInmuebles = true;
      this.verAutos = false;
      this.verTodo = false;
      this.anuncioForm.controls['_id'].setValue(anuncio._id);
      this.anuncioForm.controls['titulo'].setValue(anuncio.titulo);
      this.anuncioForm.controls['descripcion'].setValue(anuncio.descripcion);
      this.anuncioForm.controls['precio'].setValue(anuncio.precio);
      this.anuncioForm.controls['categoriaId'].setValue(anuncio.categoriaId);
      // this.anuncioForm.controls['subCategoriaId'].setValue(anuncio.subCategoriaId);
      this.anuncioForm.controls['imagenes'].setValue(anuncio.imagenes);
      this.anuncioForm.controls['tipoInmueble'].setValue(anuncio.tipoInmueble);
      this.anuncioForm.controls['numHabitaciones'].setValue(anuncio.numHabitaciones);
      this.anuncioForm.controls['numBanios'].setValue(anuncio.numBanios);
      this.anuncioForm.controls['estacionamientos'].setValue(anuncio.estacionamientos);
      this.anuncioForm.controls['superficie'].setValue(anuncio.superficie);
      this.anuncioForm.controls['usuarioId'].setValue(anuncio.usuarioId);
      this.anuncioForm.controls['ubicacion'].setValue(anuncio.ubicacion);
      this.anuncioForm.controls['geometry'].setValue(anuncio.geometry);
      this.ubicacionGuardad = anuncio.ubicacion;
      this.anuncioForm.controls['esGratuito'].setValue(anuncio.esGratuito == undefined ? false : anuncio.esGratuito);
      this.anuncioForm.controls['mostrarTelefono'].setValue(anuncio.mostrarTelefono == undefined ? false : anuncio.mostrarTelefono);
      this.imagenes = this.anuncioForm.controls['imagenes'].value;
      // this.imagesToUpload = this.imagenes;

    } else if (id.nombre === "Vehículos") {
      this.verInmuebles = false;
      this.verAutos = true;
      this.verTodo = false;
      this.autosForm.controls['_id'].setValue(anuncio._id);
      this.autosForm.controls['titulo'].setValue(anuncio.titulo);
      this.autosForm.controls['descripcion'].setValue(anuncio.descripcion);
      this.autosForm.controls['precio'].setValue(anuncio.precio);
      this.autosForm.controls['categoriaId'].setValue(anuncio.categoriaId);
      // this.autosForm.controls['subCategoriaId'].setValue(anuncio.subCategoriaId);
      this.autosForm.controls['imagenes'].setValue(anuncio.imagenes);
      this.autosForm.controls['ubicacion'].setValue(anuncio.ubicacion);
      this.autosForm.controls['marca'].setValue(anuncio.marca);
      this.autosForm.controls['marcaAuto'].setValue(anuncio.marcaAuto);
      this.autosForm.controls['anioAuto'].setValue(anuncio.anioAuto);
      this.autosForm.controls['transmision'].setValue(anuncio.transmision);
      this.autosForm.controls['combustible'].setValue(anuncio.combustible);
      this.autosForm.controls['kilometraje'].setValue(anuncio.kilometraje);
      this.autosForm.controls['usuarioId'].setValue(anuncio.usuarioId);
      this.autosForm.controls['esGratuito'].setValue(anuncio.esGratuito == undefined ? false : anuncio.esGratuito);
      this.autosForm.controls['mostrarTelefono'].setValue(anuncio.mostrarTelefono == undefined ? false : anuncio.mostrarTelefono);
      this.imagenes = this.autosForm.controls['imagenes'].value;
      // this.imagesToUpload = this.imagenes;
    }
    else {
      this.verInmuebles = false;
      this.verAutos = false;
      this.verTodo = true;
      this.anuncioGeneralForm.controls['_id'].setValue(anuncio._id);
      this.anuncioGeneralForm.controls['titulo'].setValue(anuncio.titulo);
      this.anuncioGeneralForm.controls['descripcion'].setValue(anuncio.descripcion);
      this.anuncioGeneralForm.controls['precio'].setValue(anuncio.precio);
      this.anuncioGeneralForm.controls['categoriaId'].setValue(anuncio.categoriaId._id);
      // this.anuncioGeneralForm.controls['subCategoriaId'].setValue(anuncio.subCategoriaId);
      this.anuncioGeneralForm.controls['imagenes'].setValue(anuncio.imagenes);
      this.anuncioGeneralForm.controls['usuarioId'].setValue(anuncio.usuarioId);
      this.anuncioGeneralForm.controls['ubicacion'].setValue(anuncio.ubicacion);
      this.anuncioGeneralForm.controls['esGratuito'].setValue(anuncio.esGratuito == undefined ? false : anuncio.esGratuito);
      this.anuncioGeneralForm.controls['mostrarTelefono'].setValue(anuncio.mostrarTelefono == undefined ? false : anuncio.mostrarTelefono);
      this.imagenes = this.anuncioGeneralForm.controls['imagenes'].value;
      // this.imagesToUpload = this.imagenes;
    }
    this.subActual = id;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imagesToSave: any = [];
  isUploading: boolean = false;
  fileChangeEvent(event: any): void {

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          // this.imagesToUpload.push(event.target.result);
          this.imageCompress.compressFile(event.target.result, 50, 40).then(
            result => {
              this.imgResultAfterCompress = result;
              this.croppedImage = result;
              var img = new Image();
              img.src = this.croppedImage;
              img.onload = async rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];

                if (img_height < 150) {
                  this.toastr.warning('LatinosVentas', 'La imagen tiene que tener una resolución minima de 300x300px');
                  return;
                }
                if (img_width < 150) {
                  this.toastr.warning('LatinosVentas', 'La imagen tiene que tener una resolución minima de 300x300px');
                  return;
                }

                this.isUploading = true;
                var n = Date.now();
                this.imagenes.push(result);
                this.isUploading = false;
              }

            }
          );
          //  this.myForm.patchValue({
          //     fileSource: this.images
          //  });
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }


  ubicacionActual: any;
  ubcacionNombre: any;
  downloadURLs: string;
  handleAddressChange(event) {
    this.anuncioForm.controls['geometry'].setValue(event);
    var ub = {
      description: {
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
      },
      name: event.formatted_address
    }
    this.ubicacionActual = ub;
  }



  
  snapshot: Observable<any>;
  imagenesUrl: any = [];
  async onFileSelected(image) {
    this.spinner.show();
    var auxArr = [];
    for (var i = 0; i < this.imagenes.length; i++) {
      if(this.imagenes[i].includes('base64')){
      const cas = await this.api
        .postResponse("uploadImage", false, {
          img: this.imagenes[i],
        }).toPromise();
        auxArr.push( environment.imageUrl + cas[0].url);
      }else{
        auxArr.push(this.imagenes[i])
      }
      
    }

    this.imagenes = auxArr;


    if (image) {
      this.spinner.show();
      if (this.verInmuebles) {
        this.publicarInmuebles(this.imagenes);
      }
      else if (this.verAutos) {
        this.publicarAutos(this.imagenes);
      }
      else if (this.verTodo) {
        this.publicarOtros(this.imagenes);
      }
    }
    else {
      if (this.verInmuebles) {
        this.publicarInmuebles(undefined);
      }
      else if (this.verAutos) {
        this.publicarAutos(undefined);
      }
      else if (this.verTodo) {
        this.publicarOtros(undefined);
      }
    }
  }

  eliminarImagen(img) {
    const index = this.imagenes.indexOf(img);
    if (index > -1) {
      this.imagenes.splice(index, 1);
    }
  }

  eliminarImgCargar(img) {
    const index = this.imagenes.indexOf(img);
    if (index > -1) {
      this.imagenes.splice(index, 1);
    }
  }

  obtenerIdioma(): string {
    return this.translate.currentLang;
  }


  delay = ms => new Promise(res => setTimeout(res, ms));
}
