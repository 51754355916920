<div *ngIf="!this.isLoading">
  <div *ngIf="anuncios.length <= 0" class="center-div text-center">
    <img src="../../assets/img/notfound.png" alt="" width="100">
    <div class="h3">{{'NOHAYELEMENTOS' | translate}}</div>
  </div>
  <div class="row gallery" *ngIf="anuncios.length > 0">
    <div class="col-md-4 col-sm-6 col-12 col-mr" *ngFor="let item of anuncios">
      <app-anuncio-card [anuncio]="item.anuncioId"></app-anuncio-card>
      <!-- <div class="product-slider shadow-box" style="  height: 350px;">
        <div class="product-item">
          <div class="pi-pic" style="background-color: #f9f9f9;">
            <img [src]="item.anuncioId.imagenes[0]" alt="">
            <div class="icon">
              <i class="icon_heart" style="color: red;" (click)="agregarFavoritos(item)"></i>
              
            </div>
            <ul>
              <li class="w-icon active" ><a href="#"><i class="icon_bag_alt"></i></a></li>
              <li class="quick-view"><a routerLink="/detalle/{{item.anuncioId._id}}">Vista rápida</a></li>
              <li class="w-icon"><a href="#"><i class="fa fa-random"></i></a></li>
            </ul>
          </div>
          <div class="pi-text">
            <a routerLink="/detalle/{{item._id}}">
              <h5>{{item.anuncioId.titulo | slice:0:35}} <span *ngIf="item.anuncioId.titulo.length >=35">...</span></h5>
            </a>
            <div *ngIf="!item.anuncioId.esGratuito" class="product-price">
              {{item.anuncioId.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
            </div>
            <div *ngIf="item.anuncioId.esGratuito" class="product-price">
              Gratis
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>