<div class="container" style="padding: 30px;">
  <div class="row" style="margin-top: 60px;margin-bottom: 60px;">
    <div class="col-lg-12">
      <p class="h1 text-center">
        ¿En qué podemos ayudarte?
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <!-- <img src="../../assets/img/support.jpg" style="width: 100%;"> -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6" style="padding: 45px;">
      <ngb-accordion #myaccordion="ngbAccordion" activeIds="panelOne" closeOthers="true" type="'success'"
        (panelChange)="beforeChange($event)">

        <ngb-panel title="{{'SOPORTE1' | translate}}" id="panelOne">
          <ng-template ngbPanelContent>
            {{'SOPORTE1D' | translate}}
          </ng-template>
        </ngb-panel>
        <ngb-panel title="{{'SOPORTE2' | translate}}" id="panelTwo">
          <ng-template ngbPanelContent>
            {{'SOPORTE2D' | translate}}
          </ng-template>
        </ngb-panel>
        <ngb-panel title="{{'SOPORTE3' | translate}}" id="panelThree">
          <ng-template ngbPanelContent>
            {{'SOPORTE3D' | translate}}
          </ng-template>
        </ngb-panel>
        <ngb-panel title="{{'SOPORTE4' | translate}}" id="panelFive">
          <ng-template ngbPanelContent>
            {{'SOPORTE4D' | translate}}
          </ng-template>
        </ngb-panel>
        <ngb-panel title="{{'SOPORTE5' | translate}}" id="panelSix">
          <ng-template ngbPanelContent>
            {{'SOPORTE5D' | translate}}
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
    <div class="col-lg-6">
      <img src="../../assets/img/support_main.jpg" style="width: 70%;">
    </div>
  </div>
  <hr>
  <div class="row" style="margin-top: 60px;margin-bottom: 60px;">
    <div class="col-lg-4">
      <!-- <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-shopping-cart card-icon"></i></div>
          <div class="col-lg-9">Compra y vende seguro</div>
          <br>
          <p class="text-center">Conoce nuestro proceso de compra y en que podemos facilitarlo</p>
        </div>
      </div> -->
    </div>
    <div class="col-lg-4">
      <!-- <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-ticket card-icon"></i></div>
          <div class="col-lg-9">Anúnciate</div>
          <br>
          <p class="text-center">Quieres que tu negocio aparezca en Latinosventas.com?</p>
        </div>

      </div> -->
      <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-headphone card-icon"></i></div>
          <div class="col-lg-9">{{'DUDAS' | translate}}</div>
          <br>
          <p class="text-center">{{'ESCRIBENOS' | translate}}</p>
          <a href="mailto:soporte@latinosventas.com">soporte@latinosventas.com</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <!-- <div class="card-support">
        <div class="row">
          <div class="col-lg-3"><i class="ti-headphone card-icon"></i></div>
          <div class="col-lg-9">Contacto</div>
          <br>
          <p class="text-center">No encontraste una respuesta, contácta a un asesor</p>
        </div>
      </div> -->
    </div>
  </div>
  <hr>
  <img src="../../assets/img/support.jpg" style="width: 100%;">
</div>
