<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body">
                                <form [formGroup]="loginForm">
                                    <h1>Iniciar sesión</h1>
                                    <p class="text-muted">Ingresa tus datos</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-user"></i></span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="correo"
                                            placeholder="Correo electrónico" autocomplete="username" required>
                                    </div>
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="icon-lock"></i></span>
                                        </div>
                                        <input type="password" class="form-control" formControlName="password"
                                            placeholder="Contraseña" autocomplete="current-password" required>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <button type="submit" [disabled]="loginForm.invalid" (click)="login()"
                                                class="btn btn-primary px-4">Entrar</button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <!-- <button type="button" class="btn btn-link px-0">Olvidé mi contraseña</button> -->
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
                <div class="card-body text-center">
                  <div>
                    <h2>Regístrate</h2>
                    <p>Crea tu cuenta gratuita, sin tarteja de crédito, sin compromiso y obtén créditos gratis</p>
                    <button type="button" (click)="registrar()" class="btn btn-primary active mt-3">Crear mi
                      cuenta</button>
                  </div>
                </div>
              </div> -->
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
<!-- <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }" [template]="">
</ngx-loading> -->