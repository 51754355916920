import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { first } from "rxjs/operators";
import { Anuncio } from "src/app/@models/anuncio";
import { FavoritosService } from "src/app/_services/models/favoritos.service";

@Component({
  selector: "app-anuncio-card",
  templateUrl: "./anuncio-card.component.html",
  styleUrls: ["./anuncio-card.component.css"],
})
export class AnuncioCardComponent implements OnInit, AfterViewInit {
  @Input() anuncio: Anuncio;
  // an: Anuncio;
  constructor(private api: FavoritosService, private toastr: ToastrService) {}

  ngOnInit(): void {}

  agregarFavoritos(item) {
    if (item.tieneFavorito) {
      this.api
        .eliminar("favoritos/" + item._id)
        .pipe(first())
        .subscribe(
          () => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha quitado de tus favoritos"
            );
          },
          (err) => {
          }
        );
    } else {
      this.api
        .crear("favoritos", item)
        .pipe(first())
        .subscribe(
          () => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha agregado a tus favoritos"
            );
          },
          (error) => {
          }
        );
    }
  }

  ngAfterViewInit(): void {
    // this.an = this.anuncio;
  }
}
