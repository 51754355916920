import { ApiService } from './../../_services/api.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { CrudServiceService } from 'src/app/_services/crud-service.service';
import { AnuncioService } from 'src/app/_services/models/anuncio.service';
import { FavoritosService } from 'src/app/_services/models/favoritos.service';
import { Anuncio } from 'src/app/@models/anuncio';
import { Favorito } from 'src/app/@models/favorito';


@Component({
  selector: 'app-favoritos',
  templateUrl: './favoritos.component.html',
  styleUrls: ['./favoritos.component.css']
})
export class FavoritosComponent implements OnInit {

  anuncios: Favorito[] = [];
  isLoading: Boolean = false;

  constructor(private api: AnuncioService, private favoritoService:FavoritosService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.cargarFavoritos();
  }

  cargarFavoritos() {
    this.isLoading = !this.isLoading;
    this.spinner.show();
    this.favoritoService.obtener('favoritos/' + localStorage.getItem('idUsuario')).pipe(first())
      .subscribe(
        data => {
          this.anuncios = data.filter(x => x.anuncioId);
          this.spinner.hide();
          this.isLoading = !this.isLoading;
        }, err => {
          this.spinner.hide();
          this.isLoading = !this.isLoading;
        });
  }

  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };
  }

}
