import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { isMethodSignature } from "typescript";
import { IRepository } from "./IRepository";

@Injectable({
  providedIn: "root",
})
export abstract class CrudServiceService<T, Url>
  implements IRepository<T, Url>
{
  private readonly APIUrl = environment.apiUrl;

  constructor(protected httpClient: HttpClient) {
  }

  crear(url: Url, t: T): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': this.crearToken() }),
    };
    return this.httpClient.post<T>(this.APIUrl + url, t,httpOptions);
  }

  actualizar(url: Url, t: T): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this.crearToken() }),
    };
    return this.httpClient.put<T>(this.APIUrl + url, t, {});
  }

  obtener(url: Url): Observable<T[]> {
    const httpOptions = {
      headers: new HttpHeaders({ Authorization: this.crearToken() }),
    };
    return this.httpClient.get<T[]>(this.APIUrl + url, httpOptions);
  }

  eliminar(url: Url): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization':this.crearToken() }),
    };
    return this.httpClient.delete<T[]>(this.APIUrl + url,httpOptions);
  }

  crearToken():string{
    var token =  localStorage.getItem('token');
    if(token == null || token == 'null' || token == undefined){
      token = '';
    }
    else{
      token = localStorage.getItem("token");
    }
    return token;
  }
}
