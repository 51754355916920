<div class="container" style="margin-top: 15px;">
    <div>
        <br>
        <h3 class="section-title">Resultados para:
            <span *ngIf="anuncios[0]?.usuarioId.nomusuario" class="breadcrumb-item active" aria-current="page">
                {{anuncios[0].usuarioId.nomusuario}}</span>
            <span *ngIf="!anuncios[0]?.usuarioId.nomusuario" class="breadcrumb-item active" aria-current="page">
                {{anuncios[0].usuarioId.nomusuario}}</span>
        </h3>
    </div>
    <div class="sub-list">
        <div class="row ">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 " *ngFor="let item of anuncios">
                <app-anuncio-card [anuncio]="item" class="borbox"></app-anuncio-card>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>