// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'latinosventasca',
    appId: '1:916552060628:web:01a116bd53756fea04b541',
    storageBucket: 'latinosventasca.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAAZb4IGWj8cQtNWK-AxAdmbv9OgS6OOeA',
    authDomain: 'latinosventasca.firebaseapp.com',
    messagingSenderId: '916552060628',
    measurementId: 'G-4G70H85CV5',
  },
  production: true,
  imageUrl:'https://api.latinosventas.ca',
  apiUrl: 'https://api.latinosventas.ca/api/',
  // imageUrl:'http://localhost:3000',
  // apiUrl: 'http://localhost:3000/api/',
  // apiUrl:'https://dev.latinosventas.ca/api/',
  // imageUrl:'https://dev.latinosventas.ca',
  dev:false,
  local:false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
