import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AppComponent } from "src/app/app.component";
import { LoginComponent } from "../login/login.component";

@Component({
  selector: "app-invitar",
  templateUrl: "./invitar.component.html",
  styleUrls: ["./invitar.component.css"],
})
export class InvitarComponent implements OnInit {

  img:string = "";
  constructor(
    private modal: NgbActiveModal,
    private modalService: NgbModal,
    private translate: TranslateService
  ) {

    if (navigator.language.slice(0, 2) == "en") {
      this.img = "../../../assets/img/invitacion_en.png"
    } else {
      this.img = "../../../assets/img/invitacion.png"
    }

  }

  ngOnInit(): void {}

  login() {
    this.modalService.open(LoginComponent);
    this.modal.close();
  }
}
