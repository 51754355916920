<div class="container">
  <div class="text-center m-1">

  </div>

  <div>
    <br>
    <h3 class="section-title">Resultados</h3>
  </div>
  <div class="sub-list">
    <div class="row ">
      <div *ngIf="anuncios.length <= 0" class="center-div text-center">
        <img src="../../assets/img/notfound.png" alt="">
        <div class="h1">No encontramos resultados</div>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-6 " *ngFor="let item of anuncios">
        <app-anuncio-card [anuncio]="item"></app-anuncio-card>
      </div>
    </div>
  </div>
</div>
<div class="m-1 ">
  <div id="adv" style="  margin: auto;width: 50%;padding: 10px;"></div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#57b1b9" type = "ball-clip-rotate" [fullScreen] = "true"><p style="color: #57b1b9" > Loading... </p></ngx-spinner>
