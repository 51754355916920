import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ApiService } from '../_services/api.service';

@Component({
  selector: 'app-logindev',
  templateUrl: './logindev.component.html',
  styleUrls: ['./logindev.component.css']
})
export class LogindevComponent   {

  loginForm: FormGroup;
  items: Observable<any[]>;
  public loading = false;

  constructor(private user:ApiService,private toastr: ToastrService, private router: Router, private http: HttpClient) {
    this.loginForm = new FormGroup({
      correo: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      password: new FormControl('', [Validators.required]),
    });
  }

  login() {
    this.loading = true;
    this.user.loginDev('admin/login', false, this.loginForm.value).pipe(first()).subscribe(data => {
      localStorage.setItem('tokenDev',data.token);
      this.router.navigateByUrl('');
      this.loading = false;
    }, err => {
      this.loading = false;
      this.toastr.warning(err.error);
    });
  }
}
