import { first } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { DeviceDetectorService } from "ngx-device-detector";
import { ApiService } from "src/app/_services/api.service";
import { AppComponent } from "src/app/app.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-negocios",
  templateUrl: "./negocios.component.html",
  styleUrls: ["./negocios.component.css"],
})
export class NegociosComponent implements OnInit {
  negocios: any = [];
  provincias: any = [];
  ciudades: any = [];
  categorias: any = [];
  categoriasNegocio: any = [];
  busquedaString: string = "";
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private app: AppComponent,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      this.busquedaString = params["query"];
      if (params["query"] === "") {
        this.obtenerNegocios(0, 0, "undefined");
      } else {
        this.obtenerNegocios(0, 0, params["query"]);
      }
    });
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    // this.obtenerNegocios(0,0,);
    this.obtenerProvincias();
    this.obtenerCategoriasNegocio();
  }

  obtenerNegocios(ciudadId, catId, busqueda?) {
    this.api
      .getResponse("negocios/" + ciudadId + "/" + catId + "/" + busqueda, true)
      .pipe(first())
      .subscribe(
        (data) => {
          this.negocios = data;
          this.spinner.hide();
        },
        (err) => {
          console.log(err), this.spinner.hide();
        }
      );
  }

  obtenerProvincias() {
    this.api
      .getResponse("provincia", true)
      .pipe(first())
      .subscribe(
        (data) => {
          this.provincias = data;
          this.spinner.hide();
        },
        (err) => {
          console.log(err), this.spinner.hide();
        }
      );
  }

  obtenerCategoriasNegocio() {
    this.api
      .getResponse("obtenerCategoriasNegocio", true)
      .pipe(first())
      .subscribe(
        (data) => {
          this.categoriasNegocio = data;
          this.spinner.hide();
        },
        (err) => {
          console.log(err), (this.ciudades = []);
          this.spinner.hide();
        }
      );
  }

  seleccionarProvincia(evt) {
    this.api
      .getResponse("ciudad/" + evt, true)
      .pipe(first())
      .subscribe(
        (data) => {
          this.ciudades = data;
          this.spinner.hide();
        },
        (err) => {
          console.log(err), (this.ciudades = []);
          this.spinner.hide();
        }
      );
  }

  handleAddressChange(evt) {
    //this.filtrarCat(evt,0);
    if (evt.name === "") {
      this.filtrarCat(0, 0);
      return;
    }
    var ub = {
      description: {
        latitude: evt.geometry.location.lat(),
        longitude: evt.geometry.location.lng(),
      },
      name: evt.formatted_address,
    };
    this.filtrarCat(ub.name, 0);
  }

  filtrarCat(evt, catId) {
    this.api
      .getResponse(
        "negocios/" + evt + "/" + catId + "/" + this.busquedaString,
        true
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.negocios = data;
          this.spinner.hide();
        },
        (err) => {
          console.log(err), this.spinner.hide();
        }
      );
  }

  obtenerIdioma(): string {
    return this.translate.currentLang;
  }
}
