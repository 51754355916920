<div class="container-fluid" style="padding: 30px;">
    <div class="col-lg-12 text-right">
        <button type="button" style="width:300px; margin-bottom:24px;margin-left: 0px;" class="btn-post" routerLink="/Publicar">Publicar Individual</button>
      </div>
    <header>
        <div>
            <h3>Publicar masivo</h3>
        </div>
    </header>
    <br>
    <form [formGroup]="adsTable">
        <div style="overflow-x: scroll;">
            <table class="table table-border" [ngClass]="{'table-dark': mode}">
                <thead class="text-nowrap">
                    <th>Categoría<span class="ui-column-resizer" dragableColumn> </span></th>
                    <th>Imágenes<span class="ui-column-resizer" dragableColumn> </span></th>
                    <th>Título<span class="ui-column-resizer" dragableColumn> </span></th>
                    <th>Descripción<span class="ui-column-resizer" dragableColumn> </span></th>
                    <th>Ubicación</th>
                    <th>Dirección</th>
                    <th>Precio</th>
                    <th>Es gratis</th>
                    <th>Mostrar mi teléfono</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </thead>
                <tbody>
                    <ng-container formArrayName="tableRows"
                        *ngFor="let group of getFormControls.controls ; let i=index">
                        <tr *ngIf="group.get('tipo').value === 'Otros'" [formGroupName]="i">
                            <td>{{group.get('nombreCategoria').value}}</td>
                            <td>
                                <div class="form-group form-inline">
                                    <label class="btn btn-secondary btn-file">
                                        <input name="file" multiple="true" max="10" type="file" style="width: 100px;"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            (change)="onFileChange($event,group)">
                                        <input type="hidden" name="fileHidden" formControlName="imagenes" />
                                        <!-- Validation Field -->
                                    </label>
                                </div>
                                <div class="" [sortablejs]="group.get('imagenes').value" style="position: inherit;display: flex;margin-left: 10px;">
                                    <div class="" *ngFor="let item of group.get('imagenes').value; let i = index"
                                        style="margin-top: 5px;">
                                        <div>
                                            <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item, group)"></i>
                                            <span class="icon-relw">{{i + 1}}</span>
                                            <img [src]="item" alt="" class="img-edit img-fluid img-r">
                                        </div>
                                    </div>
        
                                </div>
                                <!-- <div class="row" [sortablejs]="group.get('imagenes').value" style="position: inherit;">
                                    <div class="col-2" *ngFor="let item of group.get('imagenes').value; let i = index"
                                        style="margin-top: 5px;">
                                        <div>
                                            <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item)"></i>
                                            <span class="icon-relw">{{i + 1}}</span>
                                            <img [src]="item" alt="" class="img-edit img-fluid img-r">
                                        </div>
                                    </div>

                                </div> -->
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="titulo"
                                        placeholder="{{'TITULO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert-danger"
                                        *ngIf="group.get('titulo').invalid  && group.get('titulo').dirty">
                                        <div *ngIf="group.get('titulo').errors.required">
                                            Ingresa un título de anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="descripcion"
                                        placeholder="{{'DESCRIPCIONANUNCIO' | translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert-danger"
                                        *ngIf="group.get('descripcion').invalid  && group.get('descripcion').dirty">
                                        <div *ngIf="group.get('descripcion').errors.required">
                                            Ingresa una descripción de tu anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-control" ngx-google-places-autocomplete [options]=''
                                        placeholder="{{'UBICACION' | translate}} {{'REQUERIDO' | translate}}"
                                        [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}"
                                        #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event,group)"
                                        formControlName="ubicacionFront" />
                                    <div class="alert-danger"
                                        *ngIf="group.get('ubicacionFront').invalid  && (group.get('ubicacionFront').dirty || group.get('ubicacionFront').touched)">
                                        <div *ngIf="group.get('ubicacionFront').errors.required">
                                            Ingresa una ubicación
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="direccion"
                                        placeholder="{{'DIRECCIONANUNCIO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert-danger"
                                        *ngIf="group.get('direccion').invalid  && group.get('direccion').dirty">
                                        <div *ngIf="group.get('direccion').errors.required">
                                            Ingresa una direccion a tu anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="precio"
                                        placeholder="{{'PRECIO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert-danger"
                                        *ngIf="group.get('precio').invalid  && group.get('precio').dirty">
                                        <div *ngIf="group.get('precio').errors.required">
                                            Ingresa un precio a tu anuncio
                                        </div>
                                        <div *ngIf="group.get('precio').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" (change)="null" formControlName="esGratuito"
                                            class="custom-control-input" id="customCheck2">
                                        <label class="custom-control-label"
                                            for="customCheck2">{{'OFRECERGRATIS'|translate}}</label>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" formControlName="mostrarTelefono"
                                            class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">{{'MOSTRARTELEFONO'|
                                            translate}}</label>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <i class="icon_trash_alt" style="cursor: pointer;" (click)="deleteRow(i,group)"></i>
                            </td>
                        </tr>
                        <tr *ngIf="group.get('tipo').value === 'Inmuebles'" [formGroupName]="i">
                            <td>{{group.get('tipo').value}}</td>
                            <td>
                                <div class="form-group form-inline">
                                    <label class="btn btn-secondary btn-file">
                                        <input name="file" multiple="true" type="file" style="width: 100px;"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            (change)="onFileChange($event,group)">
                                        <input type="hidden" name="fileHidden" formControlName="imagenes" />
                                        <!-- Validation Field -->
                                    </label>
                                </div>
                                <div class="row" [sortablejs]="group.get('imagenes').value">
                                    <div class="col-4" *ngFor="let item of group.get('imagenes').value; let i = index"
                                        style="margin-top: 5px;">
                                        <div>
                                            <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item,group)"></i>
                                            <span class="icon-relw">{{i + 1}}</span>
                                            <img [src]="item" alt="" class="img-edit img-fluid img-r">
                                        </div>
                                    </div>

                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="titulo"
                                        placeholder="{{'TITULO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('titulo').invalid  && group.get('titulo').dirty">
                                        <div *ngIf="group.get('titulo').errors.required">
                                            Ingresa un título de anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="descripcion"
                                        placeholder="{{'DESCRIPCIONANUNCIO' | translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('descripcion').invalid  && group.get('descripcion').dirty">
                                        <div *ngIf="group.get('descripcion').errors.required">
                                            Ingresa una descripción de tu anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-control" ngx-google-places-autocomplete [options]=''
                                        placeholder="{{'UBICACION' | translate}} {{'REQUERIDO' | translate}}"
                                        [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}"
                                        #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event,group)"
                                        formControlName="ubicacionFront" />
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('ubicacionFront').invalid  && (group.get('ubicacionFront').dirty || group.get('ubicacionFront').touched)">
                                        <div *ngIf="group.get('ubicacionFront').errors.required">
                                            Ingresa una ubicación
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="direccion"
                                        placeholder="{{'DIRECCIONNEGOCIO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert-danger"
                                        *ngIf="group.get('direccion').invalid  && group.get('direccion').dirty">
                                        <div *ngIf="group.get('direccion').errors.required">
                                            Ingresa una direccion a tu anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="precio"
                                        placeholder="{{'PRECIO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('precio').invalid  && group.get('precio').dirty">
                                        <div *ngIf="group.get('precio').errors.required">
                                            Ingresa un precio a tu anuncio
                                        </div>
                                        <div *ngIf="group.get('precio').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" (change)="null" formControlName="esGratuito"
                                            class="custom-control-input" id="customCheck2">
                                        <label class="custom-control-label"
                                            for="customCheck2">{{'OFRECERGRATIS'|translate}}</label>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" formControlName="mostrarTelefono"
                                            class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">{{'MOSTRARTELEFONO'|
                                            translate}}</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <select class="custom-select form-control" formControlName="tipoInmueble">
                                        <option value="1">{{'CASA' | translate}}</option>
                                        <option value="2">{{'DEPARTAMENTO'| translate}}</option>
                                        <option value="3">{{'TERRENO'| translate}}</option>
                                        <option value="4">{{'OFICINA'| translate}}</option>
                                        <option value="5">{{'BODEGA'| translate}}</option>
                                        <option value="6">{{'OTROS'| translate}}</option>
                                    </select>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('tipoInmueble').invalid  && (group.get('tipoInmueble').dirty )">
                                        <div *ngIf="group.get('tipoInmueble').errors.required">
                                            Ingresa una tipo de inmueble
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="number" class="form-control" formControlName="numHabitaciones"
                                        placeholder="{{'NUMHABITACIONES' | translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('numHabitaciones').invalid  && group.get('numHabitaciones').dirty">
                                        <div *ngIf="group.get('numHabitaciones').errors.required">
                                            Ingrese un número de habitaciones
                                        </div>
                                        <div *ngIf="group.get('numHabitaciones').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="number" class="form-control" formControlName="numBanios"
                                        placeholder="{{'NUMBANIOS'|translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('numBanios').invalid  && group.get('numBanios').dirty">
                                        <div *ngIf="group.get('numBanios').errors.required">
                                            Ingresa un número de baños
                                        </div>
                                        <div *ngIf="group.get('numBanios').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="number" class="form-control" formControlName="estacionamientos"
                                        placeholder="{{'NUMESTACIONAMIENTOS'|translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('estacionamientos').invalid  && (group.get('estacionamientos').dirty )">
                                        <div *ngIf="group.get('estacionamientos').errors.required">
                                            Ingresa un número de estacionamientos
                                        </div>
                                        <div *ngIf="group.get('estacionamientos').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="number" class="form-control" formControlName="superficie"
                                        placeholder="{{'SUPERFICIE'|translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('superficie').invalid  && (group.get('superficie').dirty )">
                                        <div *ngIf="group.get('superficie').errors.required">
                                            Ingresa una superficie
                                        </div>
                                        <div *ngIf="group.get('superficie').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td></td>
                            <td></td>

                            <td>
                                <i class="icon_trash_alt" style="cursor: pointer;" (click)="deleteRow(i,group)"></i>
                            </td>
                        </tr>
                        <tr *ngIf="group.get('tipo').value === 'Vehículos'" [formGroupName]="i">
                            <td>{{group.get('tipo').value}}</td>
                            <td>
                                <div class="form-group form-inline">
                                    <label class="btn btn-secondary btn-file">
                                        <input name="file" multiple="true" type="file" style="width: 100px;"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            (change)="onFileChange($event,group)">
                                        <input type="hidden" name="fileHidden" formControlName="imagenes" />
                                        <!-- Validation Field -->
                                    </label>
                                </div>
                                <div class="row" [sortablejs]="group.get('imagenes').value">
                                    <div class="col-4" *ngFor="let item of group.get('imagenes').value; let i = index"
                                        style="margin-top: 5px;">
                                        <div>
                                            <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item,group)"></i>
                                            <span class="icon-relw">{{i + 1}}</span>
                                            <img [src]="item" alt="" class="img-edit img-fluid img-r">
                                        </div>
                                    </div>

                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="titulo"
                                        placeholder="{{'TITULO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('titulo').invalid  && group.get('titulo').dirty">
                                        <div *ngIf="group.get('titulo').errors.required">
                                            Ingresa un título de anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="descripcion"
                                        placeholder="{{'DESCRIPCIONANUNCIO' | translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('descripcion').invalid  && group.get('descripcion').dirty">
                                        <div *ngIf="group.get('descripcion').errors.required">
                                            Ingresa una descripción de tu anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input class="form-control" ngx-google-places-autocomplete [options]=''
                                        placeholder="{{'UBICACION' | translate}} {{'REQUERIDO' | translate}}"
                                        [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}"
                                        #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event,group)"
                                        formControlName="ubicacionFront" />
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('ubicacionFront').invalid  && (group.get('ubicacionFront').dirty || group.get('ubicacionFront').touched)">
                                        <div *ngIf="group.get('ubicacionFront').errors.required">
                                            Ingresa una ubicación
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="direccion"
                                        placeholder="{{'DIRECCIONANUNCIO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert-danger"
                                        *ngIf="group.get('direccion').invalid  && group.get('direccion').dirty">
                                        <div *ngIf="group.get('direccion').errors.required">
                                            Ingresa una direccion a tu anuncio
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="precio"
                                        placeholder="{{'PRECIO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('precio').invalid  && group.get('precio').dirty">
                                        <div *ngIf="group.get('precio').errors.required">
                                            Ingresa un precio a tu anuncio
                                        </div>
                                        <div *ngIf="group.get('precio').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" (change)="null" formControlName="esGratuito"
                                            class="custom-control-input" id="customCheck2">
                                        <label class="custom-control-label"
                                            for="customCheck2">{{'OFRECERGRATIS'|translate}}</label>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" formControlName="mostrarTelefono"
                                            class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">{{'MOSTRARTELEFONO'|
                                            translate}}</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="marca"
                                        placeholder="{{'MARCA' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('marca').invalid  && group.get('marca').dirty">
                                        <div *ngIf="group.get('marca').errors.required">
                                            Ingrese un número de habitaciones
                                        </div>
                                        <div *ngIf="group.get('marca').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="marcaAuto"
                                        placeholder="{{'MODELO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('marcaAuto').invalid  && group.get('marcaAuto').dirty">
                                        <div *ngIf="group.get('marcaAuto').errors.required">
                                            Ingresa un número de baños
                                        </div>
                                        <div *ngIf="group.get('marcaAuto').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="number" class="form-control" formControlName="anioAuto"
                                        placeholder="{{'ANIO' | translate}} {{'REQUERIDO' | translate}}" required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('anioAuto').invalid  && (group.get('anioAuto').dirty )">
                                        <div *ngIf="group.get('anioAuto').errors.required">
                                            Ingresa un número de estacionamientos
                                        </div>
                                        <div *ngIf="group.get('anioAuto').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="transmision"
                                        placeholder="{{'TRANSMISION' | translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('transmision').invalid  && (group.get('transmision').dirty )">
                                        <div *ngIf="group.get('transmision').errors.required">
                                            Ingresa una superficie
                                        </div>
                                        <div *ngIf="group.get('transmision').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="combustible"
                                        placeholder="{{'COMBUSTIBLE' | translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('combustible').invalid  && (group.get('combustible').dirty )">
                                        <div *ngIf="group.get('combustible').errors.required">
                                            Ingresa una superficie
                                        </div>
                                        <div *ngIf="group.get('combustible').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <input type="text" class="form-control" formControlName="kilometraje"
                                        placeholder="{{'KILOMETRAJE' | translate}} {{'REQUERIDO' | translate}}"
                                        required>
                                    <div class="alert alert-danger"
                                        *ngIf="group.get('kilometraje').invalid  && (group.get('kilometraje').dirty )">
                                        <div *ngIf="group.get('kilometraje').errors.required">
                                            Ingresa una superficie
                                        </div>
                                        <div *ngIf="group.get('kilometraje').hasError('pattern')">
                                            Ingresa sólo números
                                        </div>
                                    </div>
                                </div>
                            </td>

                            <td></td>
                            <td>
                                <i class="icon_trash_alt" style="cursor: pointer;" (click)="deleteRow(i,group)"></i>
                            </td>
                        </tr>
                        <!-- <div class="" [sortablejs]="group.get('imagenes').value" style="position: inherit;display: flex;margin-left: 10px;">
                            <div class="" *ngFor="let item of group.get('imagenes').value; let i = index"
                                style="margin-top: 5px;">
                                <div>
                                    <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item)"></i>
                                    <span class="icon-relw">{{i + 1}}</span>
                                    <img [src]="item" alt="" class="img-edit img-fluid img-r">
                                </div>
                            </div>

                        </div> -->
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div class="action-container">
            <br>
            <div class="row">
                    <div class="col-lg-2">
                        <select #catId class="form-control" placeholder="Categoría Id"  >
                            <option value="0">{{'CATEGORIAS' | translate}}</option>
                            <option value="{{item._id}}" *ngFor="let item of categorias">
                                <div *ngIf="obtenerIdioma() == 'en'">
                                    {{item.nombreEN}}
                                </div>
                                <div *ngIf="obtenerIdioma() == 'es'">
                                    {{item.nombre}}
                                </div>
                            </option>
                        </select>
                    </div>
                    <div class="col-lg-2">
                        <button class="" color="primary" type="utton"
                        (click)="filtrarCat(catId.value )">Agregar</button>
                    </div>
                
                <div class="col-lg-8 text-right">
                    <button class="" color="primary" [disabled]="adsTable.invalid" type="submit"
                        (click)="submitForm()">Cargar anuncios</button>
                </div>
            </div>
        </div>
    </form>

    <!-- {{adsTable.value | json}} -->
</div>

<ngx-spinner bdColor="rgba(0,0,0,0.01)" name="spinner-popular" size="default" color="#57b1b9" type="ball-beat"
    [fullScreen]="false">
    <p style="color: white"> Loading... </p>
</ngx-spinner>