import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.css']
})
export class LoadingButtonComponent implements OnInit {

  @Input() label: string = 'adsasd';
  @Output() onClick = new EventEmitter<any>();
  
  constructor() {

  }

  ngOnInit(): void {

  }



  onClickButton(event) {
    this.onClick.emit(event);
  }


}
