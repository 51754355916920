import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Favorito } from "src/app/@models/favorito";
import { CrudServiceService } from "../crud-service.service";

@Injectable({
  providedIn: "root",
})
export class FavoritosService extends CrudServiceService<Favorito, string> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
}
