import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class DevGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (environment.dev === true && this.auth.isDevAuthenticated() === false) {
      this.router.navigateByUrl("loginDev");
      return;
    }
    return true;
  }

  isMobile(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.deviceService.isMobile()) {
      this.router.navigateByUrl("/terminosuso");
      return false;
    }
    return true;
  }
}
