import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormArray, FormBuilder } from '@angular/forms';
import { finalize, first } from 'rxjs/operators';
import { ApiService } from './../../../_services/api.service';
import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';

import { Observable } from "rxjs";
import { NgxImageCompressService } from 'ngx-image-compress';
import { AppComponent } from 'src/app/app.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-post-masive",
  templateUrl: "./post-masive.component.html",
  styleUrls: ["./post-masive.component.css"],
})
export class PostMasiveComponent implements OnInit {
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  categorias: any = [];
  subcategorias: any = [];
  // registerForm: FormGroup;
  isLoading: Boolean = false;
  message: String = "";
  messageSuccess: String = "";
  catActual: String = "";
  subActual: String = "";

  anuncioForm: FormGroup;
  autosForm: FormGroup;
  anuncioGeneralForm: FormGroup;

  categoriaSeleccionada = -1;
  subcategoriaSeleccionada = -1;
  verAutos: boolean = false;
  verInmuebles: boolean = false;
  verTodo: boolean = false;
  isUploading: boolean = false;

  title = "cloudsSorage";
  selectedFile: File = null;
  downloadURL: Observable<string>;
  publicando: boolean = false;

  adsTable: FormGroup;
  ads: FormArray;
  mode: boolean;
  touchedRows: any;

  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public app: AppComponent,
    private imageCompress: NgxImageCompressService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private fb: FormBuilder
  ) {
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
    
  }


  ngOnInit(): void {
    this.touchedRows = [];
    this.adsTable = this.fb.group({
      tableRows: this.fb.array([]),
    });

    this.api
      .getResponse("categorias")
      .pipe(first())
      .subscribe(
        (data) => {
          this.categorias = data.filter((p) => p.activa && !p.eliminada);
        },
        (err) => {
        }
      );
  }

  deleteRow(i,form) {
    const control = this.adsTable.get("tableRows") as FormArray;
    control.removeAt(control.controls.indexOf(form));
  }

  submitForm() {
    const control = this.adsTable.get("tableRows") as FormArray;
    this.spinner.show("spinner-popular");
    control.controls.forEach((element) => {
      var inde = control.controls.indexOf(element);
      this.onFileSelected(element, inde);
    });
    this.spinner.hide("spinner-popular");
  }

  get getFormControls() {
    const control = this.adsTable.get("tableRows") as FormArray;
    return control;
  }

  initiateForm(catID,nombre): FormGroup {
    return this.fb.group({
      titulo: new FormControl("", [Validators.required]),
      descripcion: new FormControl("", [Validators.required]),
      precio: new FormControl("", [Validators.required]),
      categoriaId: new FormControl(catID, null),
      subCategoriaId: new FormControl("", null),
      imagenes: new FormControl([], [Validators.required]),
      usuarioId: new FormControl(),
      ubicacion: new FormControl("", [Validators.required]),
      direccion: new FormControl("", [Validators.required]),
      ubicacionFront: new FormControl("", [Validators.required]),
      esGratuito: new FormControl(false, [Validators.required]),
      mostrarTelefono: new FormControl(false, [Validators.required]),
      tipo: new FormControl("Otros"),
      nombreCategoria : new FormControl(nombre)
    });
  }

  initiateForm2(catID): FormGroup {
    return this.fb.group({
      titulo: new FormControl("", [Validators.required]),
      descripcion: new FormControl("", [Validators.required]),
      precio: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      categoriaId: new FormControl(catID, null),
      subCategoriaId: new FormControl("", null),
      imagenes: new FormControl(),
      tipoInmueble: new FormControl(1, [Validators.required]),
      numHabitaciones: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      numBanios: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      estacionamientos: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      superficie: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      usuarioId: new FormControl(),
      ubicacion: new FormControl("", [Validators.required]),
      direccion: new FormControl("", [Validators.required]),
      ubicacionFront: new FormControl("", [Validators.required]),
      esGratuito: new FormControl(false, [Validators.required]),
      mostrarTelefono: new FormControl(false, [Validators.required]),
      tipo: new FormControl("Inmuebles"),
    });
  }

  initiateForm3(catID): FormGroup {
    return this.fb.group({
      titulo: new FormControl("", [Validators.required]),
      descripcion: new FormControl("", [Validators.required]),
      precio: new FormControl("", [Validators.required]),
      categoriaId: new FormControl(catID, null),
      subCategoriaId: new FormControl("", null),
      imagenes: new FormControl([],[Validators.required]),
      ubicacion: new FormControl("",[Validators.required]),
      direccion: new FormControl("", [Validators.required]),
      ubicacionFront: new FormControl("", [Validators.required]),
      marca: new FormControl("", [Validators.required]),
      marcaAuto: new FormControl("", [Validators.required]),
      anioAuto: new FormControl("", [Validators.required]),
      transmision: new FormControl("", [Validators.required]),
      combustible: new FormControl("", [Validators.required]),
      kilometraje: new FormControl("", [Validators.required]),
      usuarioId: new FormControl(),
      esGratuito: new FormControl(false, [Validators.required]),
      mostrarTelefono: new FormControl(false, [Validators.required]),
      tipo: new FormControl("Vehículos"),
    });
  }

  publicarAutos(img, form, index) {
    this.spinner.show();

    if (!form.valid) {
      Swal.fire(
        "LatinosVentas",
        "Por favor verifica los campos requeridos",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    if (img == "") {
      Swal.fire(
        "LatinosVentas",
        "Por favor agrega una imagen a tu anuncio",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    var arrImg = img;
    form.controls["imagenes"].setValue(arrImg);

    form.controls["usuarioId"].setValue(localStorage.getItem("_id"));
    this.api
      .postResponse("anuncio", false, this.autosForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.toastr.success("LatinosVentas", "Tu anuncio se ha publicado");
          this.publicando = false;
          this.deleteRow(index,form);
        },
        (err) => {
          this.spinner.hide();
          this.publicando = false;
          Swal.fire(
            "LatinosVentas",
            "Ha ocurrido un error al subir la imagen",
            "error"
          );
        }
      );
  }

  publicarOtros(img, form, index) {
    this.spinner.show();

    if (!form.valid) {
      Swal.fire(
        "LatinosVentas",
        "Por favor verifica los campos requeridos",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    if (img == "") {
      Swal.fire(
        "LatinosVentas",
        "Por favor agrega una imagen a tu anuncio",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    var arrImg = img;
    form.controls["imagenes"].setValue(arrImg);

    form.controls["usuarioId"].setValue(localStorage.getItem("_id"));
    this.api
      .postResponse("anuncio", false, form.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hide();
          this.toastr.success("LatinosVentas", "Tu anuncio se ha publicado");
          this.publicando = false;
          this.deleteRow(index,form);
        },
        (err) => {
          this.spinner.hide();
          Swal.fire(
            "LatinosVentas",
            "Ha ocurrido un error al subir la imagen",
            "error"
          );
          this.publicando = false;
        }
      );
  }

  croppedImage: any = "";
  imagesToUpload: any = [];
 

  handleAddressChange(event, form) {
    var ub = {
      description: {
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
      },
      name: event.formatted_address,
    };
    form.controls["ubicacion"].setValue(ub);
  }

  ObtenerUbicacion(location) {
    var ub = {
      description: {
        latitude: location.geometry.location.lat(),
        longitude: location.geometry.location.lng(),
      },
      name: location.formatted_address,
    };

    return ub;
  }

  async onFileSelected(form: any, index) {
    this.publicando = true;
    this.spinner.show();
    var n = Date.now();
    // const files = this.imagesToUpload;
    var imagesUpload = [];

    for (var i = 0; i < form.controls["imagenes"].value.length; i++) {
      const cas = await this.api
        .postResponse("uploadImage", false, {
          img: form.controls["imagenes"].value[i],
        })
        .toPromise();
      imagesUpload.push(environment.imageUrl + cas[0].url);
    }

    this.verTodo = true;
    this.verInmuebles = false;
    this.verAutos = false;
    if (this.verInmuebles) {
      if (!this.anuncioForm.valid) {
        Swal.fire(
          "LatinosVentas",
          "Por favor verifica los campos requeridos",
          "warning"
        );
        this.spinner.hide();
        return;
      }

      this.spinner.show();
      var arrImg = imagesUpload;
      form.controls["imagenes"].setValue(arrImg);

      form.controls["usuarioId"].setValue(localStorage.getItem("_id"));

      this.api
        .postResponse("anuncio", false, form.value)
        .pipe(first())
        .subscribe(
          (data) => {
            this.spinner.hide();
            // Swal.fire("LatinosVentas", "Tu anuncio se ha publicado", "success");
            this.toastr.success("LatinosVentas", "Tu anuncio se ha publicado");
            this.publicando = false;
            this.deleteRow(index,form);
            // this.router.navigateByUrl("Perfil/MisAnuncios");
          },
          (err) => {
            this.spinner.hide();
            this.publicando = false;
            Swal.fire(
              "LatinosVentas",
              "Ha ocurrido un error al subir la imagen",
              "error"
            );
          }
        );
    } else if (this.verAutos) {
      this.publicarAutos(imagesUpload, form, index);
    } else if (this.verTodo) {
      this.publicarOtros(imagesUpload, form, index);
    }
    // this.spinner.hide();
    // // await this.delay(5000);
    // return;
  }

  eliminarImgCargar(img,form) {
    
    let arrayImg = form.controls['imagenes'].value;
    const index = arrayImg.indexOf(img);
    if (index > -1) {
      arrayImg.splice(index, 1);
      form.controls['imagenes'].setValue(arrayImg);
    }
  }

  onFileChange(event, group: FormGroup) {
    var imgArray = [];
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.imageCompress
            .compressFile(event.target.result, 50, 50, 40)
            .then((result) => {
              this.imgResultAfterCompress = result;
              this.croppedImage = result;
              var img = new Image();
              img.src = this.croppedImage;
              img.onload = async (rs) => {
                const img_height = rs.currentTarget["height"];
                const img_width = rs.currentTarget["width"];

                if (img_height < 150) {
                  this.toastr.warning(
                    "LatinosVentas",
                    "La imagen tiene que tener una resolución minima de 300x300px"
                  );
                  return;
                }
                if (img_width < 150) {
                  this.toastr.warning(
                    "LatinosVentas",
                    "La imagen tiene que tener una resolución minima de 300x300px"
                  );
                  return;
                }

                this.isUploading = true;
                var n = Date.now();
                imgArray.push(result);
                // this.imagesToUpload
                group.get("imagenes").setValue(imgArray);
                this.isUploading = false;
              };
            });
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  obtenerIdioma(): string {
    return this.translate.currentLang;
  }

  
  filtrarCat(catId) {
    let cat = this.categorias.filter((x) => x._id == catId)[0];
    if (cat.nombre === "Inmuebles") {
      const control = this.adsTable.get("tableRows") as FormArray;
      control.push(this.initiateForm2(cat._id));
    } else if (cat.nombre === "Vehículos") {
      const control = this.adsTable.get("tableRows") as FormArray;
      control.push(this.initiateForm3(cat._id));
    } else {
      const control = this.adsTable.get("tableRows") as FormArray;
      control.push(this.initiateForm(cat._id, cat.nombre));
    }
  }

  // delay = (ms) => new Promise((res) => setTimeout(res, ms));
}
