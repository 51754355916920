<!-- <app-post-masive></app-post-masive> -->
<div class="container" style="padding: 15px;">
  <div class="row" *ngIf="this.masivo ">
    <div class="col-lg-12 text-right">
      <button type="button" *ngIf="masivo" style="margin-bottom:24px;margin-left: 0px;" class="btn-post" routerLink="/PublicarMasivo">Publicar
        Masivo</button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-3 col-md-3" style="padding: 15px;">
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let item of categorias; let i = index" (click)="cargarCat(item)"
          style="cursor: pointer;" [ngClass]="{'active': categoriaSeleccionada === i}" [value]="item.value"
          (click)='categoriaSeleccionada = i;'>
          <div *ngIf="obtenerIdioma() == 'en'">
            {{item.nombreEN}}
          </div>
          <div *ngIf="obtenerIdioma() == 'es'">
            {{item.nombre}}
          </div>
        </li>
      </ul>
    </div>
    <div class="col-lg-9 col-md-9" style="padding: 15px;" [hidden]="!this.subActual">
      <input *ngIf="!isUploading && imagesToUpload.length < 5 " id="file" type="file" class="btn btn-secondary"
        multiple="" accept="image/x-png,image/gif,image/jpeg" (change)="onFileChange($event)">
      <label *ngIf="isUploading" for="">Cargando imagen...</label>
      <div class="row" [sortablejs]="imagesToUpload">
        <div class="col-4" *ngFor="let item of imagesToUpload; let i = index" style="margin-top: 5px;">
          <div>
            <i class="icon_trash_alt icon-rel" (click)="eliminarImgCargar(item)"></i>
            <span class="icon-relw">{{i + 1}}</span>
            <img [src]="item" alt="" style="cursor: pointer;" class="img-edit img-fluid" width="200">
          </div>
        </div>

      </div>
      <br>
      <span *ngIf="imagesToUpload.length > 2" class="text-center" style="color: #eb445a;">{{'ARRASTRAIMAGEN' |
        translate}}</span>
      <br>
      <!-- Inmuebles -->
      <form [formGroup]="anuncioForm" (ngSubmit)="publicar()" *ngIf="this.verInmuebles">

        <div style="margin-top: 5px;">
          <select class="custom-select form-control" formControlName="tipoInmueble">
            <option value="1">{{'CASA' | translate}}</option>
            <option value="2">{{'DEPARTAMENTO'| translate}}</option>
            <option value="3">{{'TERRENO'| translate}}</option>
            <option value="4">{{'OFICINA'| translate}}</option>
            <option value="5">{{'BODEGA'| translate}}</option>
            <option value="6">{{'OTROS'| translate}}</option>
          </select>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('tipoInmueble').invalid  && (anuncioForm.get('tipoInmueble').dirty )">
            <div *ngIf="anuncioForm.get('tipoInmueble').errors.required">
              Ingresa una tipo de inmueble
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="numHabitaciones"
            placeholder="{{'NUMHABITACIONES' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('numHabitaciones').invalid  && anuncioForm.get('numHabitaciones').dirty">
            <div *ngIf="anuncioForm.get('numHabitaciones').errors.required">
              Ingrese un número de habitaciones
            </div>
            <div *ngIf="anuncioForm.get('numHabitaciones').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="numBanios"
            placeholder="{{'NUMBANIOS'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('numBanios').invalid  && anuncioForm.get('numBanios').dirty">
            <div *ngIf="anuncioForm.get('numBanios').errors.required">
              Ingresa un número de baños
            </div>
            <div *ngIf="anuncioForm.get('numBanios').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="estacionamientos"
            placeholder="{{'NUMESTACIONAMIENTOS'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('estacionamientos').invalid  && (anuncioForm.get('estacionamientos').dirty )">
            <div *ngIf="anuncioForm.get('estacionamientos').errors.required">
              Ingresa un número de estacionamientos
            </div>
            <div *ngIf="anuncioForm.get('estacionamientos').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="superficie"
            placeholder="{{'SUPERFICIE'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('superficie').invalid  && (anuncioForm.get('superficie').dirty )">
            <div *ngIf="anuncioForm.get('superficie').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="anuncioForm.get('superficie').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="titulo"
            placeholder="{{'TITULO'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger" *ngIf="anuncioForm.get('titulo').invalid  && anuncioForm.get('titulo').dirty">
            <div *ngIf="anuncioForm.get('titulo').errors.required">
              Ingresa un título de anuncio
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="descripcion"
            placeholder="{{'DESCRIPCIONANUNCIO'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('descripcion').invalid  && anuncioForm.get('descripcion').dirty">
            <div *ngIf="anuncioForm.get('descripcion').errors.required">
              Ingresa una descripción de tu anuncio
            </div>
          </div>
        </div>


        <div style="margin-top: 5px;">
          <input class="form-control" ngx-google-places-autocomplete
            placeholder="{{'UBICACION'|translate}} {{'REQUERIDO' | translate}}"
            [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}" #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)" formControlName="ubicacion" />
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('ubicacion').invalid  && (anuncioForm.get('ubicacion').dirty || anuncioForm.get('ubicacion').touched)">
            <div *ngIf="anuncioForm.get('ubicacion').errors.required">
              Ingresa una ubicación
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="direccion"
            placeholder="{{'DIRECCIONANUNCIO'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('direccion').invalid  && anuncioForm.get('direccion').dirty">
            <div *ngIf="anuncioForm.get('direccion').errors.required">
              Ingresa una direccion de tu anuncio
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" (change)="ocultarPrecio()" formControlName="esGratuito" class="custom-control-input"
              id="customCheck5">
            <label class="custom-control-label" for="customCheck5">{{'OFRECERGRATIS' | translate}}</label>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" [hidden]="ocultarPrecioInmuebles" formControlName="precio"
            placeholder="{{'PRECIO' | translate}}(requerido)" required>
          <div class="alert alert-danger" *ngIf="anuncioForm.get('precio').invalid  && anuncioForm.get('precio').dirty">
            <div *ngIf="anuncioForm.get('precio').errors.required">
              Ingresa un precio a tu anuncio
            </div>
            <div *ngIf="anuncioForm.get('precio').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="mostrarTelefono" class="custom-control-input" id="customCheck6">
            <label class="custom-control-label" for="customCheck6">{{'MOSTRARTELEFONO' | translate}}</label>
          </div>
        </div>

        <div class="text-center">
          <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
            {{this.message}}
          </div>

          <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
            {{this.messageSuccess}}
          </div>

          <button type="submit" class="btn btn-lt-danger">
            <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
            </div>
            <span>{{'PUBLICAR' | translate}}</span>
          </button>

        </div>
      </form>

      <!-- Autos -->
      <form [formGroup]="autosForm" (ngSubmit)="publicar()" *ngIf="this.verAutos">

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="marca"
            placeholder="{{'MARCA' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger" *ngIf="autosForm.get('marca').invalid  && autosForm.get('marca').dirty">
            <div *ngIf="autosForm.get('marca').errors.required">
              Ingrese un número de habitaciones
            </div>
            <div *ngIf="autosForm.get('marca').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="marcaAuto"
            placeholder="{{'MODELO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('marcaAuto').invalid  && autosForm.get('marcaAuto').dirty">
            <div *ngIf="autosForm.get('marcaAuto').errors.required">
              Ingresa un número de baños
            </div>
            <div *ngIf="autosForm.get('marcaAuto').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="number" class="form-control" formControlName="anioAuto"
            placeholder="{{'ANIO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('anioAuto').invalid  && (autosForm.get('anioAuto').dirty )">
            <div *ngIf="autosForm.get('anioAuto').errors.required">
              Ingresa un número de estacionamientos
            </div>
            <div *ngIf="autosForm.get('anioAuto').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="transmision"
            placeholder="{{'TRANSMISION' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('transmision').invalid  && (autosForm.get('transmision').dirty )">
            <div *ngIf="autosForm.get('transmision').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="autosForm.get('transmision').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="combustible"
            placeholder="{{'COMBUSTIBLE' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('combustible').invalid  && (autosForm.get('combustible').dirty )">
            <div *ngIf="autosForm.get('combustible').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="autosForm.get('combustible').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="kilometraje"
            placeholder="{{'KILOMETRAJE' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('kilometraje').invalid  && (autosForm.get('kilometraje').dirty )">
            <div *ngIf="autosForm.get('kilometraje').errors.required">
              Ingresa una superficie
            </div>
            <div *ngIf="autosForm.get('kilometraje').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="titulo"
            placeholder="{{'TITULO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger" *ngIf="autosForm.get('titulo').invalid  && autosForm.get('titulo').dirty">
            <div *ngIf="autosForm.get('titulo').errors.required">
              Ingresa un título de anuncio
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="descripcion"
            placeholder="{{'DESCRIPCIONANUNCIO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="autosForm.get('descripcion').invalid  && autosForm.get('descripcion').dirty">
            <div *ngIf="autosForm.get('descripcion').errors.required">
              Ingresa una descripción de tu anuncio
            </div>
          </div>
        </div>


        <div style="margin-top: 5px;">
          <input class="form-control" ngx-google-places-autocomplete
            placeholder="{{'UBICACION' | translate}} {{'REQUERIDO' | translate}}"
            [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}" #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)" formControlName="ubicacion" />
          <div class="alert alert-danger"
            *ngIf="autosForm.get('ubicacion').invalid  && (autosForm.get('ubicacion').dirty || autosForm.get('ubicacion').touched)">
            <div *ngIf="autosForm.get('ubicacion').errors.required">
              Ingresa una ubicación
            </div>
          </div>
        </div>
        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="direccion"
            placeholder="{{'DIRECCIONNEGOCIO'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('direccion').invalid  && anuncioForm.get('direccion').dirty">
            <div *ngIf="anuncioForm.get('direccion').errors.required">
              Ingresa una direccion de tu anuncio
            </div>
          </div>
        </div>
        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" (change)="ocultarPrecio()" formControlName="esGratuito" class="custom-control-input"
              id="customCheck3">
            <label class="custom-control-label" for="customCheck3">{{'OFRECERGRATIS' | translate}}</label>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" [hidden]="ocultarPrecioInmuebles" formControlName="precio"
            placeholder="{{'PRECIO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger" *ngIf="autosForm.get('precio').invalid  && autosForm.get('precio').dirty">
            <div *ngIf="autosForm.get('precio').errors.required">
              Ingresa un precio a tu anuncio
            </div>
            <div *ngIf="autosForm.get('precio').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="mostrarTelefono" class="custom-control-input" id="customCheck4">
            <label class="custom-control-label" for="customCheck4">{{'MOSTRARTELEFONO' | translate}}</label>
          </div>
        </div>

        <div class="text-center">
          <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
            {{this.message}}
          </div>
          <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
            {{this.messageSuccess}}
          </div>
          <button type="submit" class="btn btn-lt-danger">
            <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
            </div>
            <span>{{'PUBLICAR' | translate}}</span>
          </button>

        </div>
      </form>

      <!-- Otros -->
      <form [formGroup]="anuncioGeneralForm" (ngSubmit)="publicar()" *ngIf="this.verTodo">

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="titulo"
            placeholder="{{'TITULO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('titulo').invalid  && anuncioGeneralForm.get('titulo').dirty">
            <div *ngIf="anuncioGeneralForm.get('titulo').errors.required">
              Ingresa un título de anuncio
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="descripcion"
            placeholder="{{'DESCRIPCIONANUNCIO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('descripcion').invalid  && anuncioGeneralForm.get('descripcion').dirty">
            <div *ngIf="anuncioGeneralForm.get('descripcion').errors.required">
              Ingresa una descripción de tu anuncio
            </div>
          </div>
        </div>


        <div style="margin-top: 5px;">
          <input class="form-control" ngx-google-places-autocomplete [options]=''
            placeholder="{{'UBICACION' | translate}} {{'REQUERIDO' | translate}}"
            [options]="{ types: ['(cities)'], componentRestrictions: { country: 'CA' }}" #placesRef="ngx-places"
            (onAddressChange)="handleAddressChange($event)" formControlName="ubicacion" />
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('ubicacion').invalid  && (anuncioGeneralForm.get('ubicacion').dirty || anuncioGeneralForm.get('ubicacion').touched)">
            <div *ngIf="anuncioGeneralForm.get('ubicacion').errors.required">
              Ingresa una ubicación
            </div>
          </div>
        </div>
        <div style="margin-top: 5px;">
          <input type="text" class="form-control" formControlName="direccion"
            placeholder="{{'DIRECCIONNEGOCIO'|translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioForm.get('direccion').invalid  && anuncioForm.get('direccion').dirty">
            <div *ngIf="anuncioForm.get('direccion').errors.required">
              Ingresa una direccion de tu anuncio
            </div>
          </div>
        </div>
        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" (change)="ocultarPrecio()" formControlName="esGratuito" class="custom-control-input"
              id="customCheck2">
            <label class="custom-control-label" for="customCheck2">{{'OFRECERGRATIS'|translate}}</label>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <input type="text" class="form-control" [hidden]="ocultarPrecioInmuebles" formControlName="precio"
            placeholder="{{'PRECIO' | translate}} {{'REQUERIDO' | translate}}" required>
          <div class="alert alert-danger"
            *ngIf="anuncioGeneralForm.get('precio').invalid  && anuncioGeneralForm.get('precio').dirty">
            <div *ngIf="anuncioGeneralForm.get('precio').errors.required">
              Ingresa un precio a tu anuncio
            </div>
            <div *ngIf="anuncioGeneralForm.get('precio').hasError('pattern')">
              Ingresa sólo números
            </div>
          </div>
        </div>

        <div style="margin-top: 5px;">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" formControlName="mostrarTelefono" class="custom-control-input" id="customCheck1">
            <label class="custom-control-label" for="customCheck1">{{'MOSTRARTELEFONO'| translate}}</label>
          </div>
        </div>

        <div class="text-center">
          <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
            {{this.message}}
          </div>
          <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
            {{this.messageSuccess}}
          </div>
          <button type="submit" class="btn btn-lt-danger">
            <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
            </div>
            <span>{{'PUBLICAR' | translate}}</span>
          </button>

        </div>
      </form>

    </div>
    <div class="row">

    </div>
  </div>
</div>