<div class="container">
  <div class="messaging" style="    height: 400px;">
    <div class="inbox_msg">
      <div class="inbox_people" style="    height: 400px;">
        <div class="headind_srch">
          <div class="recent_heading">
            <h4>{{'RECIENTES' | translate}}</h4>
          </div>
          <div class="srch_bar">
            <div class="stylish-input-group">
              <input type="text" [(ngModel)]="search" class="search-bar" placeholder="{{'BUSCAR' | translate}}">
              <span class="input-group-addon">
                <button type="button" (click)="buscar()"> <i class="fa fa-search" aria-hidden="true"></i> </button>
              </span> </div>
          </div>
        </div>
        <div class="inbox_chat" style="overflow: scroll;height: 350px;">
          <div *ngFor="let item of conversacion ">
            <div class="chat_list active_chat"> 

              <div  class="chat_people"
                style="cursor: pointer;" (click)="abrirConversacion(item);hole()">
                <!-- <div *ngIf="item.anuncioId">
                  <div class="chat_img"> <img [src]="item.anuncioId.imagenes[0]" alt="sunil"> </div>
                  <div class="chat_ib">
                    <h5>{{item.anuncioId.titulo}} <span class="chat_date">{{item.fecha | date}}</span></h5>
                    <p>Comprador: {{item.usuarioId.correo}}
                      <span *ngIf="item.vendedorId._id == currenUser && !item.vistoVendedor"
                        class="badge badge-danger">Nuevo</span>
                      <span *ngIf="item.usuarioId._id == currenUser && !item.vistoUsuario"
                        class="badge badge-danger">Nuevo</span></p>
                  </div>
                  <i class="ti-trash" style="color:red;" (click)="eliminar(item._id)"></i>
                </div>
                <div *ngIf="item.anuncioId == null">El anuncio ya no está disponible
                  <i class="ti-trash" style="color:red;" (click)="eliminar(item._id)"></i>
                </div> -->
              </div>
              <!-- <div *ngIf="item.usuarioId._id == currenUser && !item.ocultarUsuario && item.anuncioId" -->
                <div class="chat_people" style="cursor: pointer;" (click)="abrirConversacion(item);hole()">
                <div *ngIf="item.anuncioId">
                  <div class="chat_img"> <img [src]="item.anuncioId?.imagenes[0]" alt="sunil"> </div>
                  <div class="chat_ib">
                    <h5>{{item.anuncioId.titulo}} <span class="chat_date">{{item.fecha | date}}</span></h5>
                    <p>{{'COMPRADOR' | translate}}:
                      <span *ngIf="item.usuarioId.nomusuario">{{item.usuarioId.nomusuario}}</span>
                      <span *ngIf="!item.usuarioId.nomusuario">{{this.ObtenerUsername(item.usuarioId.correo)}}</span>
                      <span *ngIf="item.vendedorId._id == currenUser && !item.vistoVendedor" class="badge badge-danger">Nuevo</span>
                      <span *ngIf="item.usuarioId._id == currenUser && !item.vistoUsuario"
                        class="badge badge-danger">{{'NUEVO' | translate}}</span></p>
                  </div>
                  <i class="ti-trash" style="color:red;" (click)="eliminar(item._id)"></i>
                </div>
                <div *ngIf="item.anuncioId == null">El anuncio ya no está disponible
                  <i class="ti-trash" style="color:red;" (click)="eliminar(item._id)"></i>
                </div>
              </div>
            </div>
            <ngx-spinner bdColor="rgba(0,0,0,0.01)" size="default" color="#57b1b9" type="ball-beat"
              [fullScreen]="false">
              <p style="color: white">{{'CARGANO' | translate}}</p>
            </ngx-spinner>
          </div>
        </div>
      </div>
      <div class="mesgs" *ngIf="conversacionDetalle" style="height: 400px;overflow-y: scroll;" #scrollchat>
        <div>
        <div class="msg_history" *ngFor="let mensaje of conversacionDetalle.conversacion">
          <div class="incoming_msg" *ngIf="mensaje.selector !== getId()">
            <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil">
            </div>
            <div class="received_msg">
              <div class="received_withd_msg">
                <p>{{mensaje.mensaje}}</p>
                <span class="time_date">{{mensaje.fecha | date:'dd/MM/yyyy HH:mm'}} Hrs.</span>
              </div>
            </div>
          </div>
          <div class="outgoing_msg" *ngIf="mensaje.selector === getId()">
            <div class="sent_msg">
              <p>{{mensaje.mensaje}}</p>
              <span class="time_date">{{mensaje.fecha | date:'dd/MM/yyyy HH:mm'}} Hrs.</span>
            </div>
          </div>
        </div>
      </div>
        <div class="type_msg">
          <div class="input_msg_write">
            <form [formGroup]="mensajeForm" (ngSubmit)="enviar()">
              <input type="text" formControlName="mensaje" class="write_msg" placeholder="{{'ESCRIBEMENSAJE' | translate}}" />
              <button class="msg_send_btn" type="submit"><i class="fa fa-paper-plane-o" aria-hidden="true"></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
