import { AfterViewInit, Component, OnInit } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/_services/api.service';
// import * as firebase from 'firebase';
import { WindowsService } from "../../_services/windows.service";
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginComponent } from 'src/app/@modals/login/login.component';

@Component({
  selector: 'app-editarnegocio',
  templateUrl: './editarnegocio.component.html',
  styleUrls: ['./editarnegocio.component.css']
})
export class EditarnegocioComponent implements  OnInit {
  // windowRef:any;
  // verificationCode: string;
  user:any;

  registerForm: FormGroup;
  isloading: Boolean = false;
  message: String = '';
  messageSuccess: String = '';
  imagesToUpload: any = [];
  isUploading: boolean = false;

  provincias: any = [];
  ciudades: any = [];
  categoriasNegocio = [];

  selectedCompanies;
  companies: any[] = [];
  form :any;
  subs;
  constructor(
    private api: ApiService, 
    // public authf: AngularFireAuth,
    private win: WindowsService, private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    // private storage: AngularFireStorage,
     private imageCompress: NgxImageCompressService,
  ) {
  


    this.registerForm = new FormGroup({
      _id: new FormControl('', []),
      nombre: new FormControl('', [Validators.required, Validators.maxLength(90)]),
      direccion: new FormControl('', [Validators.required, Validators.maxLength(90)]),
      propietario: new FormControl('', [Validators.required, Validators.maxLength(90)]),
      telefono: new FormControl('', [Validators.required, Validators.pattern("^[0-9]{10}$")]),
      correoNegocio: new FormControl('', [Validators.required, Validators.email]),
      descripcion: new FormControl('', [Validators.required, Validators.maxLength(240)]),
      tags: new FormControl('', [Validators.required]),
      usuarioId: new FormControl('', []),
      categoriaNegocioId: new FormControl(0, [Validators.required]),
      ubicacion:new FormControl('',[Validators.required]),
      logo: new FormControl('', []),
      verificationCode: new FormControl('', [])
    });
  }

  obtenerNegocioPorId(id: number) {
    this.api.getResponse("negocioPorId/" + id, true).pipe(first()).subscribe(data => {
      this.form = {
        _id : data._id,
        nombre:data.nombre,
        direccion:data.direccion,
        propietario:data.propietario,
        telefono:data.telefono,
        correoNegocio:data.correoNegocio,
        descripcion:data.descripcion,
        tags:data.tags,
        usuarioId:data.usuarioId,
        categoriaNegocioId:data.categoriaNegocioId,
        // ciudadId:data.ciudadId._id,
        // provinciaId:data.ciudadId.provinciaId,
        ubicacion:data.ubicacion,
        logo:data.logo,
        verificationCode: ''
      }
      
      this.registerForm.setValue(this.form);
      this.seleccionarProvinciaId(this.form.provinciaId);
    }, err => { console.log(err), this.ciudades = []; this.spinner.hide() });
  }
  
  
  phoneRecaptchaVerifier: any;
  captcha:any;
  
  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      this.setCaptcha();
      this.obtenerCategoriasNegocio();
      this.obtenerNegocioPorId(id);
      this.obtenerProvincias();
      
    });
    

    
  }

  setCaptcha(){
    // this.windowRef = this.win.windowRef;
    // this.windowRef.recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier('phone-sign-in-recaptcha');

    // this.windowRef.recaptchaVerifier.render()
  }

  addTagFn(name) {
    return { name: name, tag: true };
  }


  obtenerCategoriasNegocio() {
    this.api.getResponse("categoriasNegocio", true).pipe(first()).subscribe(data => {
      this.categoriasNegocio = data;
      this.spinner.hide();
    }, err => { console.log(err), this.ciudades = []; this.spinner.hide() });
  }



  eliminarImgCargar(img) { }


  obtenerProvincias() {
    this.api.getResponse('provincia', true).pipe(first()).subscribe(data => {
      this.provincias = data;
      this.spinner.hide();
    }, err => { console.log(err), this.spinner.hide() });
  }

  seleccionarProvincia(evt) {
    this.api.getResponse("ciudad/" + evt, true).pipe(first()).subscribe(data => {
      this.ciudades = data;
      this.spinner.hide();
    }, err => { console.log(err), this.ciudades = []; this.spinner.hide() });
  }

  seleccionarProvinciaId(id) {
    // this.api.getResponse("ciudad/" + id, true).pipe(first()).subscribe(data => {
    //   this.ciudades = data;
    //   this.spinner.hide();
    // }, err => { console.log(err), this.ciudades = []; this.spinner.hide() });
  }


  imagenesUrl:string;
  
  async guardar() {
    this.isloading = true;
    var n = Date.now();
    for (var i = 0; i < this.imagesToUpload.length; i++) {
    }

    await this.delay(5000);

    this.registerForm.controls['usuarioId'].setValue(localStorage.getItem('idUsuario'));
    if(this.imagenesUrl){
    this.registerForm.controls['logo'].setValue(this.imagenesUrl);
    }
    else{
      this.registerForm.controls['logo'].setValue(this.form.logo);
    }
    this.api.putResponse("editarNegocio/", true, this.registerForm.value).pipe(first()).subscribe(data => {
      Swal.fire('LatinosVentas', 'Tu negocio se ha actualizado correctamente', 'success');
      this.isloading = false;
      this.router.navigateByUrl('Perfil/MisNegocios');
    }, err => { 
      this.toastr.warning('LatinosVentas', err.error.text);
      this.eliminarImagenFirebase(this.imagenesUrl);
      this.ciudades = []; 
      this.isloading = false;
    });
  }

  
  imgResultAfterCompress:any;
  croppedImage:any;
  ComprimirImagenSeleccionada(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.imageCompress.compressFile(event.target.result, 50, 40).then(
            result => {
              this.imgResultAfterCompress = result;
              this.croppedImage = result;
              var img = new Image();
              img.src = this.croppedImage;
              img.onload = async rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];

                
                if (img_height < 150) {
                  this.toastr.warning('LatinosVentas', 'La imagen tiene que tener una resolución minima de 300x300px');
                  return;
                }
                if (img_width < 150) {
                  this.toastr.warning('LatinosVentas', 'La imagen tiene que tener una resolución minima de 300x300px');
                  return;
                }

                
                this.isUploading = true;
                var n = Date.now();
                this.imagesToUpload.push(result);
                this.isUploading = false;
              }

            }
          );

        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }


  eliminarImagenFirebase(downloadUrl) {
    // return this.storage.storage.refFromURL(downloadUrl).delete();
  }

  ubicacionActual: any;
  ubcacionNombre: any;
  handleAddressChange(event) {
    var ub = {
      description: {
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
      },
      name: event.formatted_address
    }
    this.ubicacionActual = ub.name;
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

}
