import { RestablecerComponent } from "./@modals/restablecer/restablecer.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MessagingService } from "./_services/messaging.service";
import { AuthService } from "./_services/auth.service";
import { RegisterComponent } from "./@modals/register/register.component";
import { LoginComponent } from "./@modals/login/login.component";
import { ApiService } from "./_services/api.service";
import { Component, Inject } from "@angular/core";
import { first } from "rxjs/operators";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { interval, Subscription } from "rxjs";
import { DOCUMENT, LocationStrategy } from "@angular/common";
import { DeviceDetectorService } from "ngx-device-detector";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { InvitarComponent } from "./@modals/invitar/invitar.component";
declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "taochy";
  images = [
    "file:///E:/Proyectos/teslasoft/taochy/theme/fashi/img/products/women-4.jpg",
  ];
  categorias: any = [];
  isLoggedIn: any;
  message;
  noLeidas: number = 0;
  noLeidasUS: number = 0;
  subscription: Subscription;
  busquedaForm: FormGroup;

  constructor(
    private api: ApiService,
    private router: Router,
    private modal: NgbModal,
    private auth: AuthService,
    private messagingService: MessagingService,
    @Inject(DOCUMENT) document: any,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) {
    
    this.busquedaForm = new FormGroup({
      query: new FormControl("", [Validators.required]),
    });
    this.auth.isLoggedIn.subscribe((val) => (this.isLoggedIn = val));
    this.cargarCategorias();
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage;

    const source = interval(5000);
    this.subscription = source.subscribe((val) => {
      this.obtenerNotificaciones();
    });
    let currentUrl = document.location.href;
    if (currentUrl == "https://latinosventas.ca/") {
      window.location.href = "https://latinosventas.com";
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (navigator.language.slice(0, 2) == "en") {
      this.translate.setDefaultLang("en");
      this.translate.use("en");
    } else {
      this.translate.setDefaultLang("es");
      this.translate.use("es");
    }

    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        gtag("config", "UA-{ID}", { page_path: y.url });
        fbq("track", "PageView");
      }
    });
  }

  deviceInfo;
  isMobile;
 
  activarMobiel(isM: boolean) {
    this.isMobile = isM;
  }

  ngOnInit() {
    if (!this.deviceService.isMobile()) {
      this.invitar();
    }
    if (!this.isMobile) {
    }
  }

  cargarCategorias() {
    this.api
      .getResponse("categorias")
      .pipe(first())
      .subscribe(
        (data) => {
          this.categorias = data.filter((p) => p.activa && !p.eliminada);
        },
        (err) => {}
      );
  }

  irCategoria(categoria) {
    this.router.navigate(["Categoria/" + categoria]);
  }

  abrirProductos(id): string {
    return "/Anuncios/" + id;
  }

  login() {
    this.modal.open(LoginComponent, { windowClass: "modal-holder" });
  }

  invitar() {
    if (localStorage.getItem("mdFt") == undefined) {
      localStorage.setItem("mdFt", "true");
      this.modal.open(InvitarComponent, { size: "md" });
    }
  }

  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  register() {
    this.modal.open(RegisterComponent, { windowClass: "modal-holder" });
  }

  getCorreo(): String {
    return this.auth.getAutenticatedEmail();
  }

  logout() {
    this.auth.logout();
  }

  obtenerNotificaciones() {
    if (this.isLoggedIn) {
      this.api
        .getResponse(
          "obtenerNotificaciones/" + localStorage.getItem("idUsuario"),
          true
        )
        .pipe(first())
        .subscribe(
          (data) => {
            if (data == 0) {
              this.noLeidas = 0;
            } else {
              if (data > 0) this.noLeidas = data;
              else this.noLeidas = 0;
            }
          },
          (err) => {}
        );
    }
  }

  obtenerNotificacionesUsuario() {
    this.api
      .getResponse(
        "obtenerNotificacionesUsuario/" + localStorage.getItem("idUsuario")
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.noLeidas = data.length;
        },
        (err) => {}
      );
  }

  publicar() {
    this.router.navigateByUrl("/Publicar");
  }

  buscar() {
    if (this.router.url.includes("/directorio")) {
      this.router.navigateByUrl(
        "/directorio/" + this.busquedaForm.controls["query"].value
      );
    } else {
      this.router.navigateByUrl(
        "/buscar/" + this.busquedaForm.controls["query"].value
      );
    }
  }

  abrirPerfil() {
    this.router.navigateByUrl("/Perfil");
  }

  ads = [
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=prime_up&banner=14FS3RN4YQD1CA33DAR2&f=ifr&linkID=9b20504002d54bce21b875239fe59ad7&t=latinosventas-20&tracking_id=latinosventas-20",
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=primevideo&banner=0AE5M5KXRJK87H99Z7R2&f=ifr&linkID=fabb8e6db5e7309ebbaaf54e9b438c79&t=latinosventas-20&tracking_id=latinosventas-20",
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=29&l=ur1&category=software&banner=04ND7DYYFYTW9CPS6482&f=ifr&linkID=0ec5e8bff33474fafaf49027518db65b&t=latinosventas-20&tracking_id=latinosventas-20",
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=29&l=ur1&category=software&banner=1SYA56WHG58AGJXPBP82&f=ifr&linkID=747923bdca59bc1fa830559dd649976e&t=latinosventas-20&tracking_id=latinosventas-20",
    "//rcm-na.amazon-adsystem.com/e/cm?o=1&p=14&l=ur1&category=software&banner=13ESHRZHJFEHMT3KY1R2&f=ifr&linkID=319dd383e90097b3a7b348151bc6a28d&t=latinosventas-20&tracking_id=latinosventas-20",
  ];

  adUrl: any = "";
  ngAfterViewInit() {
    this.transformYet = false;
    var index = Math.floor(this.ads.length * Math.random());
    this.adUrl = this.transform(this.ads[index]);
  }

  transformYet: boolean = true;
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  obtenerIdioma(): string {
    return this.translate.currentLang;
  }

  isDevAuth(): Boolean {
    if (!this.auth.isDevAuthenticated()) {
      return false;
    }
    return true;
  }
}
