<div class="container">
    <div class="sub-list">
        <div class="row" style="margin-bottom: 100px;">
            <div class="center-div text-center">
                <a style="cursor:pointer;padding:8px;" class="login-panel" routerLink="/nuevonegocio">
                    <button type="button" class="btn-post">{{'ANUNCIATEGRATIS' | translate}}</button>
                </a>
                <hr>
            </div>

            <div class="col-lg-11 col-md-4 col-sm-6 col-xs-6 " *ngFor="let item of negocios">

                <div class="product-slider ">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                                <img [src]="item.logo" alt="" class="img-anuncio img-fluid">

                                <ul>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="product-item">
                                <div>
                                    <span
                                        style="font-size: 30px; text-decoration: none; color:#e7ab3c;">{{item.nombre}}</span>
                                    <p style="font-size: 20px; text-decoration: none; color:#292929 ;">
                                        {{item.direccion}} - TEL: {{item.telefono}}</p>
                                    <p style="font-size: 30px; text-decoration: none; color:#6d6d6d;">
                                        {{item.descripcion}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1 col-md-4 col-sm-2">
                            <div class="icon">
                                <i class="icon_trash_alt" style="cursor: pointer;" (click)="eliminar(item._id)"></i>
                                <i class="icon_pencil-edit" style="cursor: pointer;" (click)="editar(item._id)"></i>
                            </div>
                        </div>

                    </div>
                    <div class="alert alert-danger d-flex align-items-center" role="alert" *ngIf="!item.negocioValido">
                        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                            <use xlink:href="#exclamation-triangle-fill" />
                        </svg>
                        <div>
                            Tu negocio está siendo validado.
                        </div>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div>
</div>