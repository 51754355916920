import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { JwtModule } from "@auth0/angular-jwt";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NguCarouselModule } from "@ngu/carousel";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "angularx-social-login";
import { NgxImageCompressService } from "ngx-image-compress";
import { ImageCropperModule } from "ngx-image-cropper";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AuthInterceptor } from "./@interceptors/auth.interceptor";
import { LoginComponent } from "./@modals/login/login.component";
import { RecuperarComponent } from "./@modals/recuperar/recuperar.component";
import { RegisterComponent } from "./@modals/register/register.component";
import { RestablecerComponent } from "./@modals/restablecer/restablecer.component";
import { FilterPipe } from "./@pipes/filter.pipe";
import { AnunciosComponent } from "./anuncios/anuncios.component";
import { AnunciosusuarioComponent } from "./anunciosusuario/anunciosusuario.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BuscaranunciosComponent } from "./buscaranuncios/buscaranuncios.component";
import { EditaranuncioComponent } from "./cuenta/editaranuncio/editaranuncio.component";
import { EditarperfilComponent } from "./cuenta/editarperfil/editarperfil.component";
import { EulaComponent } from "./cuenta/eula/eula.component";
import { FavoritosComponent } from "./cuenta/favoritos/favoritos.component";
import { MensajesComponent } from "./cuenta/mensajes/mensajes.component";
import { MisanunciosComponent } from "./cuenta/misanuncios/misanuncios.component";
import { MisnegociosComponent } from "./cuenta/misnegocios/misnegocios.component";
import { PerfilComponent } from "./cuenta/perfil/perfil.component";
import { PrivacidadComponent } from "./cuenta/privacidad/privacidad.component";
import { RestablecerPasswordComponent } from "./cuenta/restablecer-password/restablecer-password.component";
import { TerminosComponent } from "./cuenta/terminos/terminos.component";
import { DetalleproductoComponent } from "./detalleproducto/detalleproducto.component";
import { EditarnegocioComponent } from "./directorio/editarnegocio/editarnegocio.component";
import { NegociosComponent } from "./directorio/negocios/negocios.component";
import { NuevonegocioComponent } from "./directorio/nuevonegocio/nuevonegocio.component";
import { UiModule } from "./modules/UI/ui.module";
import { PrincipalComponent } from "./principal/principal.component";
import { SeleccionarcategoriaComponent } from "./publicacion/seleccionarcategoria/seleccionarcategoria.component";
import { SoporteComponent } from "./soporte/soporte.component";
import {
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import { SortablejsModule } from "ngx-sortablejs";
import { NgSelectModule } from "@ng-select/ng-select";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { PinchZoomModule } from "ngx-pinch-zoom";
import { LogindevComponent } from "./logindev/logindev.component";
import { AngularFireAnalyticsModule } from "@angular/fire/compat/analytics";
import { PostMasiveComponent } from "./modules/UI/post-masive/post-masive.component";
import { InvitarComponent } from './@modals/invitar/invitar.component';
//latinosventas-DOS
var firebaseConfig = {
  apiKey: "AIzaSyAAZb4IGWj8cQtNWK-AxAdmbv9OgS6OOeA",
  authDomain: "latinosventasca.firebaseapp.com",
  projectId: "latinosventasca",
  storageBucket: "latinosventasca.appspot.com",
  messagingSenderId: "916552060628",
  appId: "1:916552060628:web:01a116bd53756fea04b541",
  measurementId: "G-4G70H85CV5",
};
// AdsenseModule.forRoot({
//   adClient: 'ca-pub-7744668111711401',
//   adSlot: 7259870550,
// }),

export function tokenGetter() {
  return localStorage.getItem("token");
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PrincipalComponent,
    DetalleproductoComponent,
    AnunciosComponent,
    LoginComponent,
    RegisterComponent,
    PerfilComponent,
    MisanunciosComponent,
    MensajesComponent,
    FavoritosComponent,
    SeleccionarcategoriaComponent,
    SoporteComponent,
    FilterPipe,
    BuscaranunciosComponent,
    RecuperarComponent,
    RestablecerComponent,
    RestablecerPasswordComponent,
    EditarperfilComponent,
    EditaranuncioComponent,
    TerminosComponent,
    PrivacidadComponent,
    AnunciosusuarioComponent,
    EulaComponent,
    NegociosComponent,
    NuevonegocioComponent,
    MisnegociosComponent,
    EditarnegocioComponent,
    LogindevComponent,
    PostMasiveComponent,
    InvitarComponent
  ],
  imports: [
    RouterModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: environment.production,
    // }),
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    GooglePlaceModule,

    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    // FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: [""],
        disallowedRoutes: [""],
      },
    }),
    ImageCropperModule,

    // AngularFireStorageModule,
    // FilterPipeModule,
    PinchZoomModule,
    ToastrModule.forRoot({ positionClass: "toast-bottom-right" }),
    NgbModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgSelectModule,
    UiModule,
    NguCarouselModule,
    SocialLoginModule,
    CommonModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAnalytics(() => getAnalytics()),
    // provideAuth(() => getAuth()),
    // provideFirestore(() => getFirestore()),
    AngularFireAuthModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgxImageCompressService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AppComponent,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("838487599911972"),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "916552060628-orja8f203p26981qtabqt07gtrjbbah1.apps.googleusercontent.com"
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule],
})
export class AppModule {}
