<div *ngIf="espanol" class="container" style="background-color: #fff;">
  <div class="container" style="padding: 60px;background-color: #fff;text-align: justify;">
    <p class="h1 text-center">Términos y condiciones de Uso</p>
    <p class="h3">Introducción</p>
    <p>Al ingresar, utlizar y acceder este sitio y Apps de LATINOSVENTAS, estás aceptando los Términos y condiciones de
      uso
      contenidos y estipulados en esta sección y declara expresamente su aceptación utilizando para tal efecto medios
      electrónicos, en términos de lo dispuesto en la legislación actual. Además, cuando usted
      utilice cualquier servicio actual o futuro de LATINOSVENTAS también estará sujeto a los lineamientos y condiciones
      aplicables a tal servicio o negocio.
      La utilización de LATINOSVENTAS, Plataforma, Sitio Web, Applicacion, o cualquier otro software o servicio
      desarollador
      en el futuro le da la condicion de usuario, (en adelante será referido como usuarios o usuarios), mismo que esta
      aceptando por completo todas y cada una de los terminos y condiciones de uso publicados por LATINOSVENTAS.
      Cualquier
      modificacion podra ser realizada por LATINOSVENTAS cuando lo cosidere apropiado, siendo responsabilidad del
      usuarios o
      los usuarios asegurarse de tomar conocimiento de las modificaciones.

    </p>
    <p class="h3">Acuerdo</p>
    <p>Acuerdo de adhesión para el uso de LATINOSVENTAS que celebran: por una parte, LATINOSVENTAS y, por la otra, el
      usuario,
      sujetándose, ambas partes, a lo establecido en este documento.</p>
    <p class="h3">Licencia y Acceso al sitio.</p>
    <p>LATINOSVENTAS le otorga una licencia limitada para acceder y hacer uso personal de este sitio web y no para
      descargar
      (otra cosa que páginas caché) o modificarlo o cualquier parte del mismo, salvo con el consentimiento expreso por
      escrito de LATINOSVENTAS. Esta licencia no incluye cualquier reventa o uso comercial de este sitio web,
      Aplicaciones o
      de su contenido; cualquier uso derivado de este sitio web o su contenido; o cualquier uso de la búsqueda de datos,
      robots o métodos similares de recolección o extracción de datos. Este sitio web o cualquier parte de este sitio
      web
      no podrá ser reproducido, duplicado, copiado, vendido, revendido, visitado o de otra manera explotado para
      cualquier
      fin comercial sin el consentimiento expreso por escrito de LATINOSVENTAS. Usted no podrá enmarcar o utilizar
      técnicas
      de
      enmarcado (framing) para adjuntar cualquier marca comercial, logotipo u otra información propietaria (incluyendo
      imágenes, textos, diseños de página o forma) de LATINOSVENTAS o de sus filiales sin el consentimiento expreso y
      por escrito de LATINOSVENTAS. Usted no podrá utilizar ninguna etiqueta o cualquier otro "texto oculto" que utilice
      el nombre o marcas comerciales de LATINOSVENTAS o de sus filiales sin el consentimiento expreso y por escrito de
      LATINOSVENTAS. Cualquier uso no autorizado da por terminado el permiso o la licencia concedida por LATINOSVENTAS.
      Se le
      concede un derecho limitado, revocable y no exclusivo para crear un hipervínculo a la página de
      inicio de arc-LATINOSVENTAS.com siempre y cuando el vínculo no represente a LATINOSVENTAS, sus filiales o sus
      productos o en forma falsa, engañosa, despectiva o de otra manera ofensiva. Usted no podrá utilizar ningún
      logotipo
      u otro gráfico propietario o marca comercial de LATINOSVENTAS o de sus filiales como parte del vínculo sin nuestro
      permiso expreso por escrito.El usuario sólo podrá imprimir y/o copiar cualquier información contenida o publicada
      en
      el sitio y Applicaciones de LATINOSVENTAS exclusivamente para uso personal, queda terminantemente prohibido el uso
      comercial de dicha información. En caso de ser persona moral se sujetará a lo dispuesto por el artículo 148,
      fracción IV de la Ley Federal del Derecho de Autor.
    </p>
    <p class="h3">Privacidad</p>
    <p>Favor de revisar nuestro Aviso de Privacidad, que también rige su visita a LATINOSVENTAS, a fin de entender
      nuestras
      prácticas.</p>
    <p class="h3">Comunicaciones electrónicas</p>
    <p>Al visitar LATINOSVENTAS o enviarnos correos electrónicos, usted se está comunicando con nosotros
      electrónicamente.
      Usted otorga su consentimiento para recibir comunicaciones electrónicas por parte nuestra. Nosotros nos
      comunicaremos con usted por correo electrónico o mensajeria electronica. Usted acuerda que todos los acuerdos,
      avisos, divulgaciones y otras comunicaciones que le proporcionemos electrónicamente satisfacen cualquier requisito
      legal.</p>
    <p class="h3">Derechos de autor y propiedad Industrial</p>
    <p>Todo el contenido incluido en este sitio web, Aplicaciones y otros servicios de LATINOSVENTAS, como texto,
      gráficos,
      logotipos, iconos, imágenes, grabaciones de audio, descargas digitales, compilaciones de datos y software, es
      propiedad de LATINOSVENTAS o de sus proveedores de contenido y está protegido por las leyes de los Estados Unidos
      Méxicanos y las leyes internacionales en materia de propiedad intelectual y derechos de autor. La compilación de
      todo el contenido en este sitio web es propiedad exclusiva de LATINOSVENTAS y está protegido por las leyes de los
      Estados Unidos Méxicanos y las leyes internacionales de derechos de autor. Todo el software utilizado en este
      sitio
      web es propiedad de LATINOSVENTAS o de sus proveedores de software y está protegido por las leyes de los Estados
      Méxicanos y las leyes internacionales en materia de propiedad intelectual y derechos de autor.Se prohíbe
      expresamente al usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas,
      nombres
      comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de la
      información contenida en el sitio señalado.
      <br>
      En caso de que el usuario transmita a LATINOSVENTAS cualquier información, fotografias, videos, audios, programas,
      aplicaciones, software o en general cualquier material que requiera ser licenciado a través de la plataforma
      LATINOSVENTAS, el usuario otorga con este acto a LATINOSVENTAS una licencia perpetua, universal, gratuita, no
      exclusiva,
      mundial y libre de regalías, que incluye los derechos de sublicenciar, vender, reproducir, distribuir, transmitir,
      crear trabajos derivados, exhibirlos y ejecutarlos públicamente.
      <br>
      Lo establecido en el párrafo anterior se aplicará igualmente a cualquier otra información que el usuario envíe o
      transmita a LATINOSVENTAS, incluyendo, sin limitación alguna, ideas para renovar o mejorar el sitio web,
      plataforma, o
      aplicaciones de LATINOSVENTAS, ya sea que éstas hayan sido incluidas o que sean desarrollados en el futuro.
      Por lo anterior, el usuario renuncia expresamente con este acto a llevar a cabo cualquier acción, demanda o
      reclamación en contra de LATINOSVENTAS, sus afiliados o proveedores por cualquier actual o eventual violación de
      cualquier derecho de autor o propiedad intelectual derivado de la información, programas, aplicaciones, software,
      ideas y demás material que el propio usuario envíe a la plataforma, sitio web o aplicaciones de LATINOSVENTAS.
      Es nuestra política actuar contra las violaciones que en materia de propiedad intelectual se pudieran generar
      según
      lo estipulado en la legislación y en otras leyes de propiedad intelectual aplicables, incluyendo la eliminación o
      el
      bloqueo del acceso a material que se encuentra sujeto a actividades que infrinjan el derecho de propiedad
      intelectual de terceros. En caso de que algún usuario o tercero consideren que cualquiera de los contenidos que se
      encuentren o sean introducidos en la plataforma, sitio web, aplicaciones y cualquiera de los servicios de
      LATINOSVENTAS,
      violen sus derechos de propiedad intelectual deberán enviar una notificación a la siguiente dirección
      hola@arc-LATINOSVENTAS.com, en la que indiquen: i) datos personales verídicos (nombre, dirección, número de
      teléfono y
      dirección de correo electrónico del reclamante); ii) firma autógrafa con los datos personales del titular de los
      derechos de propiedad intelectual; iii) indicación precisa y completa del (los) contenido (s) protegido (s)
      mediante
      los derechos de propiedad intelectual supuestamente infringidos, así como la localización de dichas violaciones en
      el sitio web referido; iv) declaración expresa y clara de que la introducción del (los) contenido (s) indicado (s)
      se ha realizado sin el consentimiento del titular de los derechos de propiedad intelectual supuestamente
      infringidos; v) declaración expresa, clara y bajo la responsabilidad del reclamante de que la información
      proporcionada en la notificación es exacta y de que la introducción del (los) contenido (s) constituye una
      violación
      de dichos derechos.

    </p>
    <p class="h3">Reglas para el uso del sitio web LATINOSVENTAS</p>
    <p>El usuario esta de acuerdo que al utilizar LATINOSVENTAS se sujetará a las siguientes reglas:

      La información, conceptos y opiniones publicadas en dicho sitio no necesariamente reflejan la posición de
      LATINOSVENTAS,
      ni de sus empleados, oficiales, directores, accionistas, licenciatarios y concesionarios (en lo sucesivo los
      «afiliados»). Por esta razón, LATINOSVENTAS no se hace responsable por ninguna de las informaciones, opiniones y
      conceptos que se emitan en la página web referida. En este caso, se recomienda al usuario consultar con un
      especialista y/o profesional en la materia. Asimismo, LATINOSVENTAS no se hace responsable de la información
      contenida
      en la página de Internet, plataforma y aplicaciones, incluidas las subpáginas, en el entendido de que el uso y
      seguimiento de la misma es bajo riesgo y responsabilidad del usuario.
      LATINOSVENTAS se reserva el derecho de bloquear el acceso o remover en forma parcial o total toda información,
      comunicación o material que a su exclusivo juicio pueda resultar abusivo, difamatorio u obsceno; fraudulento,
      artificioso o engañoso; violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o
      cualquier
      derecho de propiedad intelectual de un tercero; ofensivo o; que de cualquier forma contravenga lo establecido en
      este convenio. Si el usuario desea obtener mayor información de un tema en específico proveído por LATINOSVENTAS o
      sus
      afiliados deberá consultarlo directamente con cada uno de ellos, según corresponda, y/o con un especialista en la
      materia.

      El usuario reconoce que LATINOSVENTAS no controla o censura previamente el contenido disponible en la plataforma,
      sitio
      web, aplicaciones y otros sercicios. Por tal motivo, LATINOSVENTAS no asume ninguna responsabilidad por el
      contenido
      provisto a dicha página por proveedores independientes o ajenos y no tiene el control editorial sobre el
      contenido,
      información y/o material generado y/o provisto por terceros. Todas las opiniones, consejos, declaraciones,
      servicios, ofertas u otras informaciones o contenidos expresados o puestos a disposición del público por terceros,
      pertenecen a su respectivo autor y LATINOSVENTAS no asume responsabilidad alguna frente a ello. De la misma forma,
      LATINOSVENTAS no garantiza la exactitud, veracidad, amplitud y/o utilidad de cualquier contenido provisto por
      tales
      terceros. Adicionalmente, LATINOSVENTAS no es responsable ni garantiza la exactitud, exhaustividad, veracidad y/o
      confiabilidad de cualquier opinión, información, consejo o declaración expresados a través de su página de
      Internet,
      plataforma, aplicativos y/o servicios; Bajo ninguna circunstancia LATINOSVENTAS será responsable de cualquier daño
      y/o
      perjuicio, directo o indirecto, causado en virtud de la confianza que el usuario deposite en información obtenida
      a
      través de su sitio web. LATINOSVENTAS se reserva el derecho de suprimir o modificar el contenido de esta página
      que, a
      exclusivo juicio de LATINOSVENTAS no cumpla con sus estándares o que pudiera resultar contrario al ordenamiento
      jurídico
      vigente y, por consiguiente, no será responsable por cualquier falla o tardanza que se genere al eliminar tal
      material.
    </p>
    <p class="h3">Su actividad en LATINOSVENTAS</p>
    <p>LATINOSVENTAS no está diseñado para ser utilizado por niños. Si usted es menor de 18 años, usted podrá utilizar
      los
      servicios de LATINOSVENTAS únicamente con la participación de un padre o tutor. LATINOSVENTAS se reservan el
      derecho a
      negarse a prestar el servicio, cerrar cuentas o eliminar o editar contenido a su entera discreción.</p>
    <p class="h3">Material publicitario</p>
    <p>El usuario reconoce y acepta que LATINOSVENTAS es una organización independiente de terceros patrocinadores,
      afiliados
      y anunciantes cuya información, imágenes, anuncios y demás material publicitario o promocional puede ser publicado
      en la plataforma, sitio web, aplicaciones y servicios de LATINOSVENTAS.
      El usuario reconoce y acepta que el material publicitario no forma parte del contenido principal que se publica en
      dicho sitio. Asimismo, reconoce y acepta con este acto que este material se encuentra protegido por las leyes que
      en
      materia de propiedad intelectual e industrial resulten aplicables.
    </p>
    <p class="h3">Exclusión de garantías y limitación de la responsabilidad</p>
    <p>Este sitio web, plataforma, aplicaciones son proporcionado por LATINOSVENTAS "tal como está" y "según
      disponibilidad".
      LATINOSVENTAS no manifiesta ninguna representación o garantía de ningún tipo, expresa o implícita, en relación con
      la
      operación de este sitio web, plataforma y aplicaciones o la información, contenido, materiales o productos
      incluidos. El usuario acepta expresamente que el uso de esta plataforma, sitio web, aplicaciones y servicios
      provehidos por LATINOSVENTAS es bajo su propio riesgo. LATINOSVENTAS se reserva el derecho a remover o eliminar
      cualquier
      información de este sitio web, en cualquier momento, a su entera discreción. En la medida permitida por la ley
      aplicable, LATINOSVENTAS renuncia a toda garantía, expresa o implícita, incluyendo, sin limitación, las garantías
      implícitas de comercialización e idoneidad para algún propósito en particular. LATINOSVENTAS no garantiza que este
      plataforma, sitio web, aplicaciones o servicios, sus servidores o el correo electrónico enviado por LATINOSVENTAS
      están
      libres de virus o de cualquier otro elemento perjudicial. LATINOSVENTAS no será responsable por ningún daño o
      perjuicio,
      de ningún tipo, que derive del uso de este sitio web, incluyendo, sin limitación, daños y perjuicios directos,
      indirectos, incidentales, punitivos y consecuenciales. El usuario está de acuerdo que la utilización de la
      plataforma, sitio web, aplicaciones y servicios de LATINOSVENTASse realiza bajo su propio riesgo y que los
      servicios y
      productos que ahí se prestan y ofrecen se prevén sobre una base «tal cual» y «según sean disponibles».
      LATINOSVENTAS
      no
      garantiza que la página señalada satisfaga los requerimientos del usuario o que los servicios que en ella se
      ofrecen
      no sufran interrupciones, sean seguros o estén exentos de errores. LATINOSVENTAS se libera de cualquier
      responsabilidad
      y condiciones, si la disponibilidad de uso de la plataforma, sitio web, aplicaciones y servicios LATINOSVENTAS se
      ve
      interrumpida total o parcilamente. LATINOSVENTAS y sus afilidados podrán actualizar el contenido de la página
      constantemente, por lo que se solicita al usuario tomar en cuenta que algunas informaciones publicitadas o
      contenidas en o a través de este sitio web pueden haber quedado obsoletas y/o contener imprecisiones o errores
      tipográficos u ortográficos.</p>
    <p class="h3">Modificaciones a la plataforma, sitio web, aplicaciones y servicios LATINOSVENTAS</p>
    <p>SPOTLIIGHT podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de avisar al usuario,
      realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios,
      áreas, bases de datos y demás elementos en la plataforma, sitio web, aplicaciones y servicios ofrecidos por
      LATINOSVENTAS, sin que ello de lugar ni derecho a ninguna reclamación o indemnización, ni que esto implique
      reconocimiento de responsabilidad alguna a favor del usuario.</p>
    <p class="h3">Modificaciones al convenio</p>
    <p>LATINOSVENTAS se reserva el derecho de modificar los Términos y condiciones de uso de este convenio en cualquier
      momento, siendo efectivas dichas modificaciones de forma inmediata por medio de este apartado, el usuario está de
      acuerdo en revisar dicho convenio periódicamente con la finalidad de mantenerse al tanto de dichas modificaciones.
      No obstante lo anterior, cada vez que el usuario acceda al sitio señalado se considerará como una aceptación
      absoluta a las modificaciones del presente convenio.</p>
    <p class="h3">Indemnización</p>
    <p>El usuario está de acuerdo en indemnizar a LATINOSVENTAS, sus afiliados, proveedores, vendedores y asesores por
      cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de
      cualquier incumplimiento por parte del usuario al presente convenio; incluyendo, sin limitación de alguna de las
      derivadas de:
      • Cualquier aspecto relativo al uso de la plataforma, sitio web, aplicaciones y servicios que ofrece
      LATINOSVENTAS.
      • La información contenida o disponible en o a través de dicha plataforma, sitio web, aplicaciones y servicios que
      LATINOSVENTAS ofrece sea objeto de injurias, difamación o cualquier otra conducta violatoria del presente convenio
      por
      parte del usuario con LATINOSVENTAS
      • La violación a las leyes aplicables o tratados internacionales relativos a los derechos de autor o propiedad
      intelectual, contenidos o disponibles en, o a través de la plataforma, sitio web, aplicaciones y servicios que
      LATINOSVENTAS ofrece.

    </p>
    <p class="h3">Terminación</p>
    <p>LATINOSVENTAS se reserva el derecho sin necesidad de aviso o notificación al usuario, para Terminar
      definitivamente
      el
      presente convenio, Descontinuar o dejar de publicar definitivamente el sitio web, aplicaciones y otros servicios
      de
      LATINOSVENTAS sin responsabilidad alguna para LATINOSVENTAS, sus afiliados o proveedores.</p>
    <p class="h3">Subsistencia</p>
    <p>Estos Términos y condiciones de uso, así como los términos adicionales indicados en esta sección sustituye
      cualquier otro acuerdo o convenio celebrado con anterioridad. Este convenio podrá modificarse a elección de
      LATINOSVENTAS, Sin embargo, las cláusulas estipuladas en esta sección mantendrán su obligatoriedad y validez.</p>
    <p class="h3">No renuncia de derechos</p>
    <p>La inactividad por parte de LATINOSVENTAS, en ningún momento deberá interpretarse como renuncia a dichos derechos
      o
      acciones.</p>
    <p class="h3">Legislación aplicable y jurisdicción</p>
    <p>Este convenio estará sujeto y será interpretado de acuerdo con las leyes y ante los tribunales de la Ciudad de
      Guadalajara, Jalisco, México.</p>



  </div>
</div>

<div *ngIf="!espanol" class="container" style="background-color: #fff;">
  <p class="h1 text-center">Terms and conditions of use</p>
  <p class="h3">Introduction</p>


  <p>By entering, using and accessing this site and LATINOSVENTAS Apps, you are accepting the Terms and conditions of
    use contained and stipulated in this section and expressly declare your acceptance using electronic means for this
    purpose, in terms of the provisions of current legislation. In addition, when you use any current or future
    LATINOSVENTAS service, you will also be subject to the guidelines and conditions applicable to such service or
    business. The use of LATINOSVENTAS, Platform, Website, Application, or any other software or developer service in
    the future gives you the status of user, (hereinafter it will be referred to as users or users), which is fully
    accepting each and every one. of the terms and conditions of use published by LATINOSVENTAS. Any modification may be
    made by LATINOSVENTAS when it deems it appropriate, being the responsibility of the user or users to ensure that
    they are aware of the modifications.</p>

  <p>Agreement</p>

  <p>Adhesion agreement for the use of LATINOSVENTAS that they celebrate: on the one hand, LATINOSVENTAS and, on the
    other, the user, subjecting both parties to the provisions of this document.</p>

  <p>License and Access to the site.</p>

  <p>LATINOSVENTAS grants you a limited license to access and make personal use of this website and not to download
    (other than cache pages) or modify it or any part of it, except with the express written consent of LATINOSVENTAS.
    This license does not include any resale or commercial use of this website, Applications or their content; any
    derivative use of this website or its content; or any use of data search, robots or similar data collection or
    extraction methods. This website or any part of this website may not be reproduced, duplicated, copied, sold,
    resold, visited or otherwise exploited for any commercial purpose without the express written consent of
    LATINOSVENTAS. You may not frame or use framing techniques to attach any trademark, logo or other proprietary
    information (including images, texts, page designs or forms) of LATINOSVENTAS or its affiliates without the express
    written consent of LATINOSVENTAS . You may not use any label or any other &quot;hidden text&quot; that uses the name
    or trademarks of LATINOSVENTAS or its affiliates without the express written consent of LATINOSVENTAS. Any
    unauthorized use terminates the permission or license granted by LATINOSVENTAS. You are granted a limited, revocable
    and non-exclusive right to create a hyperlink to the home page of arc-LATINOSVENTAS.com as long as the link does not
    represent LATINOSVENTAS, its affiliates or its products or in a false, misleading, derogatory or otherwise
    offensive. You may not use any logo or other proprietary graphic or trademark of LATINOSVENTAS or its affiliates as
    part of the link without our express written permission. The user may only print and / or copy any information
    contained or published on the site and Applications of LATINOSVENTAS exclusively for personal use, the commercial
    use of said information is strictly prohibited. In case of being a legal person, it will be subject to the
    provisions of article 148, section IV of the Federal Copyright Law.</p>

  <p>Privacy</p>

  <p>Please review our Privacy Notice, which also governs your visit to LATINOSVENTAS, in order to understand our
    practices.</p>

  <p>Electronic communications</p>

  <p>By visiting LATINOSVENTAS or sending emails to us, you are communicating with us electronically. You consent to
    receive electronic communications from us. We will communicate with you by email or email. You agree that all
    agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal
    requirement.</p>

  <p>Copyright and Industrial Property</p>

  <p>All content included in this website, Applications and other LATINOSVENTAS services, such as text, graphics, logos,
    icons, images, audio recordings, digital downloads, data and software compilations, is the property of LATINOSVENTAS
    or its content providers and is protected by the laws of the United States of Mexico and international laws on
    intellectual property and copyright. The compilation of all content on this website is the exclusive property of
    LATINOSVENTAS and is protected by the laws of the United States of Mexico and international copyright laws. All
    software used on this website is the property of LATINOSVENTAS or its software providers and is protected by the
    laws of the Mexican States and international laws andn matters of intellectual property and copyright. The user is
    expressly prohibited from modifying, altering or deleting, either in whole or in part, the notices, trademarks,
    trade names, signs, advertisements, logos or in general any indication that refers to to the ownership of the
    information contained in the indicated site. In the event that the user transmits to LATINOSVENTAS any information,
    photographs, videos, audios, programs, applications, software or in general any material that requires to be
    licensed through the LATINOSVENTAS platform, the user grants LATINOSVENTAS a perpetual license with this act. ,
    universal, free, non-exclusive, worldwide and royalty-free, which includes the rights to sublicense, sell,
    reproduce, distribute, transmit, create derivative works, display and perform them publicly. The provisions of the
    previous paragraph will also apply to any other information that the user sends or transmits to LATINOSVENTAS,
    including, without limitation, ideas to renew or improve the LATINOSVENTAS website, platform, or applications,
    whether these have been included or to be developed in the future. Therefore, the user expressly waives with this
    act to carry out any action, demand or claim against LATINOSVENTAS, its affiliates or suppliers for any current or
    eventual violation of any copyright or intellectual property derived from the information, programs , applications,
    software, ideas and other material that the user himself sends to the LATINOSVENTAS platform, website or
    applications. It is our policy to act against intellectual property violations that may be generated as stipulated
    in the legislation and other applicable intellectual property laws, including the elimination or blocking of access
    to material that is subject to activities that violate the intellectual property rights of third parties. In the
    event that any user or third party considers that any of the content that is found or is entered on the platform,
    website, applications and any of the LATINOSVENTAS services, violates their intellectual property rights, they must
    send a notification to the following address hola@arc-LATINOSVENTAS.com, in which they indicate: i) true personal
    data (name, address, telephone number and email address of the claimant); ii) autograph signature with the personal
    data of the owner of the intellectual property rights; iii) precise and complete indication of the content (s)
    protected by the intellectual property rights allegedly infringed, as well as the location of said violations on the
    aforementioned website; iv) express and clear declaration that the introduction of the content (s) indicated (s) has
    been made without the consent of the owner of the intellectual property rights allegedly infringed; v) express,
    clear statement and under the responsibility of the claimant that the information provided in the notification is
    accurate and that the introduction of the content (s) constitutes a violation of said rights.</p>

  <p>Rules for the use of the LATINOSVENTAS website</p>

  <p>The user agrees that when using LATINOSVENTAS he will be subject to the following rules: The information, concepts
    and opinions published on said site do not necessarily reflect the position of LATINOSVENTAS, nor of its employees,
    officers, directors, shareholders, licensees and concessionaires (in hereinafter the &quot;affiliates&quot;). For
    this reason, LATINOSVENTAS is not responsible for any of the information, opinions and concepts that are issued on
    the referred website. In this case, the user is recommended to consult a specialist and / or professional in the
    field. Likewise, LATINOSVENTAS is not responsible for the information contained in the Internet page, platform and
    applications, including the subpages, with the understanding that the use and monitoring of the same is at the risk
    and responsibility of the user. LATINOSVENTAS reserves the right to block access or partially or totally remove all
    information, communication or material that in its sole judgment may be abusive, defamatory or obscene; fraudulent,
    contrived or deceptive; violation of copyrights, trademarks, confidentiality, industrial secrets or any intellectual
    property rights of a third party; offensive or; that in any way contravenes the provisions of this agreement. If the
    user wishes to obtain more information on a specific topic provided by LATINOSVENTAS or its affiliates, they should
    consult directly with each of them, as appropriate, and / or with a specialist in the matter. The user acknowledges
    that LATINOSVENTAS does not previously control or censor the content available on the platform, website,
    applications and other services. By taFor this reason, LATINOSVENTAS does not assume any responsibility for the
    content provided to said page by independent or external providers and does not have editorial control over the
    content, information and / or material generated and / or provided by third parties. All opinions, advice,
    statements, services, offers or other information or content expressed or made available to the public by third
    parties, belong to their respective author and LATINOSVENTAS does not assume any responsibility for it. In the same
    way, LATINOSVENTAS does not guarantee the accuracy, veracity, breadth and / or usefulness of any content provided by
    such third parties. Additionally, LATINOSVENTAS is not responsible or guarantees the accuracy, completeness,
    veracity and / or reliability of any opinion, information, advice or statement expressed through its website,
    platform, applications and / or services; Under no circumstances will LATINOSVENTAS be responsible for any direct or
    indirect damage and / or harm caused by virtue of the trust that the user places in information obtained through its
    website. LATINOSVENTAS reserves the right to delete or modify the content of this page that, in the sole judgment of
    LATINOSVENTAS, does not comply with its standards or that could be contrary to the current legal system and,
    therefore, will not be responsible for any failure or delay that occurs. generated by removing such material.</p>

  <p>Your activity in LATINOSVENTAS</p>

  <p>LATINOSVENTAS is not designed to be used by children. If you are under 18 years of age, you may use LATINOSVENTAS
    services only with the participation of a parent or guardian. LATINOSVENTAS reserves the right to refuse to provide
    the service, close accounts or delete or edit content at their sole discretion.</p>

  <p>Advertising material</p>

  <p>The user acknowledges and accepts that LATINOSVENTAS is an independent organization of third-party sponsors,
    affiliates and advertisers whose information, images, advertisements and other advertising or promotional material
    may be published on the LATINOSVENTAS platform, website, applications and services. The user acknowledges and
    accepts that the advertising material is not part of the main content published on said site. Likewise, you
    acknowledge and accept with this act that this material is protected by the applicable laws on intellectual and
    industrial property.</p>

  <p>Exclusion of guarantees and limitation of liability</p>

  <p>This website, platform, applications are provided by LATINOSVENTAS &quot;as is&quot; and &quot;as available&quot;.
    LATINOSVENTAS does not express any representation or guarantee of any kind, express or implicit, in relation to the
    operation of this website, platform and applications or the information, content, materials or products included.
    The user expressly accepts that the use of this platform, website, applications and services provided by
    LATINOSVENTAS is at their own risk. LATINOSVENTAS reserves the right to remove or delete any information from this
    website, at any time, at its sole discretion. To the extent permitted by applicable law, LATINOSVENTAS disclaims all
    warranties, express or implied, including, without limitation, the implied warranties of merchantability and fitness
    for a particular purpose. LATINOSVENTAS does not guarantee that this platform, website, applications or services,
    its servers or the email sent by LATINOSVENTAS are free of viruses or any other harmful element. LATINOSVENTAS will
    not be liable for any loss or damage of any kind arising from the use of this website, including, without
    limitation, direct, indirect, incidental, punitive and consequential damages. The user agrees that the use of the
    LATINOSVENTAS platform, website, applications and services is carried out at their own risk and that the services
    and products provided and offered there are provided on an &quot;as is&quot; and &quot;as is&quot; basis. available
    &raquo;. LATINOSVENTAS does not guarantee that the indicated page satisfies the user's requirements or that the
    services offered therein are uninterrupted, safe or free of errors. LATINOSVENTAS is released from any
    responsibility and conditions, if the availability of use of the LATINOSVENTAS platform, website, applications and
    services is totally or partially interrupted. LATINOSVENTAS and its affiliates may update the content of the page
    constantly, so the user is asked to take into account that some information published or contained in or through
    this website may have become obsolete and / or contain inaccuracies or typographical or spelling errors. .</p>

  <p>Modifications to the LATINOSVENTAS platform, website, applications and services</p>

  <p>SPOTLIIGHT may at any time and when it deems appropriateentity, without the need to notify the user, make
    corrections, additions, improvements or modifications to the content, presentation, information, services, areas,
    databases and other elements on the platform, website, applications and services offered by LATINOSVENTAS, without
    This gives rise to or the right to any claim or compensation, nor does this imply recognition of any responsibility
    in favor of the user.</p>

  <p>Modifications to the agreement</p>

  <p>LATINOSVENTAS reserves the right to modify the Terms and conditions of use of this agreement at any time, such
    modifications being effective immediately through this section, the user agrees to review said agreement
    periodically in order to keep abreast of said modifications. Notwithstanding the foregoing, each time the user
    accesses the indicated site, it will be considered as an absolute acceptance of the modifications of this agreement.
  </p>

  <p>Compensation</p>

  <p>The user agrees to indemnify LATINOSVENTAS, its affiliates, suppliers, vendors and advisers for any action, demand
    or claim (including legal fees and legal costs) derived from any breach by the user to this agreement; including,
    without limitation, any of those derived from: &bull; Any aspect related to the use of the platform, website,
    applications and services offered by LATINOSVENTAS. &bull; The information contained or available on or through said
    platform, website, applications and services that LATINOSVENTAS offers is subject to insults, defamation or any
    other conduct that violates this agreement by the user with LATINOSVENTAS &bull; The violation of applicable laws or
    International treaties related to copyright or intellectual property, content or available on, or through the
    platform, website, applications and services that LATINOSVENTAS offers.</p>

  <p>Termination</p>

  <p>LATINOSVENTAS reserves the right, without the need for notice or notification to the user, to definitively
    terminate this agreement, discontinue or definitively stop publishing the website, applications and other services
    of LATINOSVENTAS without any liability for LATINOSVENTAS, its affiliates or suppliers.</p>

  <p>Subsistence</p>

  <p>These Terms and conditions of use, as well as the additional terms indicated in this section supersede any other
    agreement or agreement previously entered into. This agreement may be modified at the option of LATINOSVENTAS.
    However, the clauses stipulated in this section will remain binding and valid.</p>

  <p>No waiver of rights</p>

  <p>Inactivity by LATINOSVENTAS shall at no time be interpreted as a waiver of said rights or actions.</p>

  <p>Applicable law and jurisdiction</p>

  <p>This agreement will be subject to and will be interpreted in accordance with the laws and before the courts of the
    City of Guadalajara, Jalisco, Mexico.</p>
</div>