import { AuthService } from "./../../_services/auth.service";
// import { first } from 'rxjs/operators';
import { Usuario } from "../../@models/usuario";
import { ApiService } from "./../../_services/api.service";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { first } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LoginComponent } from "../login/login.component";
import { TranslateService } from "@ngx-translate/core";

// import * as firebase from 'firebase';
// import { Component, OnInit } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { faMobileAlt, faBell, faSearch, faEnvelope, faMapMarkerAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
// import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.css"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  isLoading: Boolean = false;
  message: String = "";
  messageSuccess: String = "";

  constructor(private api: ApiService, private toastr: ToastrService,private modal: NgbModal,private translateService:TranslateService) {
    this.registerForm = new FormGroup({
      nombre: new FormControl("", [Validators.required]),
      apellido: new FormControl("", [Validators.required]),
      nomusuario: new FormControl("", [Validators.required]),
      telefono: new FormControl("", []),
      correo: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("", [Validators.required]),
      dev: new FormControl(environment.dev),
    });
  }

  ngOnInit(): void {}

  signInWithFB(): void {
    // this.auth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider()).then(data => {
    //   console.log(data);
    // }).catch(err => { console.log('errorFB', err) });
  }

  registerWithEmail(): void {
    if (this.registerForm.valid) {
      if (
        this.registerForm.controls["password"].value !=
        this.registerForm.controls["confirmPassword"].value
      ) {
        this.message = "Las contraseñas no coinciden";
        return;
      }
      let usuario: Usuario;
      usuario = this.registerForm.value;
      usuario.usuarioFb = false;

      this.api
        .postResponse("registro/", false, usuario)
        .pipe(first())
        .subscribe(
          (data) => {
            this.message = "";
            this.toastr.success(
              "LatinosVentas",
              this.translateService.instant("HEMOSENVIADO") +
                this.registerForm.controls["correo"].value +
                this.translateService.instant("INGRESATUCUENTA")
            );
            this.modal.dismissAll();
            this.modal.open(LoginComponent);
          },
          (error) => {
            this.messageSuccess = "";
            // if(error.error.mensaje.includes("El correo ya ha sido registrado anteriormente"))
            // {
              this.message = this.translateService.instant("CORREOREGISTRADO");
            // }
            // else{
            // this.message = error.error.mensaje;
            // }
          }
        );
    }
  }
}
