<div class="modal-header">
  <h4 class="modal-title">{{'CREARCUENTA' | translate}}</h4>
</div>
<div class="modal-body">
  <form [formGroup]="registerForm">
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="nombre" placeholder="{{'NOMBRE' | translate}}" required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('nombre').invalid  && (registerForm.get('nombre').dirty )">
        <div *ngIf="registerForm.get('nombre').errors.required">
          {{'CAMPOOBLIGATORIO' | translate}}
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="apellido" placeholder="{{'APELLIDO' | translate}}"
        required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('apellido').invalid  && (registerForm.get('apellido').dirty )">
        <div *ngIf="registerForm.get('apellido').errors.required">
          {{'CAMPOOBLIGATORIO' | translate}}
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="nomusuario" placeholder="{{'NOMBREUSUARIO' | translate}}"
        required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('nomusuario').invalid  && (registerForm.get('nomusuario').dirty )">
        <div *ngIf="registerForm.get('nomusuario').errors.required">
          {{'CAMPOOBLIGATORIO' | translate}}
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="telefono"
        placeholder="{{'TELEFONO' | translate}} {{'OPCIONAL' | translate}}">
      <!-- <div class="alert alert-danger"
        *ngIf="registerForm.get('telefono').invalid  && (registerForm.get('telefono').dirty )">
        <div *ngIf="registerForm.get('telefono').errors.required">
          Ingresa un nombre de usuario
        </div>
      </div> -->
    </div>
    <div style="margin-top: 5px;">
      <input type="text" class="form-control" formControlName="correo" placeholder="{{'EMAIL' | translate}}" required>
      <div class="alert alert-danger" *ngIf="registerForm.get('correo').invalid  && registerForm.get('correo').dirty">
        <div *ngIf="registerForm.get('correo').errors.required">
          {{'CAMPOOBLIGATORIO' | translate}}
        </div>
        <div *ngIf="registerForm.get('correo').errors.email">
          {{'CAMPOOBLIGATORIO' | translate}}
        </div>
      </div>
    </div>
    <div style="margin-top: 5px;">
      <input type="password" class="form-control" formControlName="password" placeholder="{{'CONTRASENA' | translate}}"
        required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('password').invalid  && (registerForm.get('password').dirty )">
        <div *ngIf="registerForm.get('password').errors.required">
          {{'CAMPOOBLIGATORIO' | translate}}
        </div>
      </div>
    </div>

    <div style="margin-top: 5px;">
      <input type="password" class="form-control" formControlName="confirmPassword"
        placeholder="{{'REPETIRCONTRASENA' | translate}}" required>
      <div class="alert alert-danger"
        *ngIf="registerForm.get('confirmPassword').invalid  && (registerForm.get('confirmPassword').dirty )">
        <div *ngIf="registerForm.get('confirmPassword').errors.required">
          {{'CAMPOOBLIGATORIO' | translate}}
        </div>
      </div>
    </div>

    <div class="text-center">
      <!-- <div class="btn-continue-pr center-div" style="margin-top: 15px;" (click)="continue()">Continuar</div> -->
      <!-- [disabled]="registerForm.invalid" -->
      <div class="alert alert-danger" *ngIf="this.message && !this.messageSuccess" style="margin-top: 10px;">
        {{this.message}}
      </div>

      <div class="alert alert-success" *ngIf="this.messageSuccess && !this.message" style="margin-top: 10px;">
        {{this.messageSuccess}}
      </div>

      <button type="button" [disabled]="registerForm.invalid" (click)="this.registerWithEmail()"
        class="btn btn-lt-danger">
        <div *ngIf="isLoading" class="spinner-border btn-spinner" role="status">
        </div>
        <span>{{'CREARCUENTA' | translate}}</span>
      </button>

    </div>
  </form>
</div>