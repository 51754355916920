import { PrivacidadComponent } from './cuenta/privacidad/privacidad.component';
import { TerminosComponent } from './cuenta/terminos/terminos.component';
import { EditaranuncioComponent } from './cuenta/editaranuncio/editaranuncio.component';
import { EditarperfilComponent } from './cuenta/editarperfil/editarperfil.component';
import { RestablecerPasswordComponent } from './cuenta/restablecer-password/restablecer-password.component';
import { RestablecerComponent } from './@modals/restablecer/restablecer.component';
import { BuscaranunciosComponent } from './buscaranuncios/buscaranuncios.component';
import { SoporteComponent } from './soporte/soporte.component';
import { SeleccionarcategoriaComponent } from './publicacion/seleccionarcategoria/seleccionarcategoria.component';
import { AuthguardGuard } from './_services/authguard.guard';
import { FavoritosComponent } from './cuenta/favoritos/favoritos.component';
import { MensajesComponent } from './cuenta/mensajes/mensajes.component';
import { MisanunciosComponent } from './cuenta/misanuncios/misanuncios.component';
import { LoginComponent } from './@modals/login/login.component';
import { PerfilComponent } from './cuenta/perfil/perfil.component';
import { AnunciosComponent } from './anuncios/anuncios.component';
import { DetalleproductoComponent } from './detalleproducto/detalleproducto.component';
import { PrincipalComponent } from './principal/principal.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate  } from '@angular/router';
import { AnunciosusuarioComponent } from './anunciosusuario/anunciosusuario.component';
import { EulaComponent } from './cuenta/eula/eula.component';
import { NegociosComponent } from './directorio/negocios/negocios.component';
import { NuevonegocioComponent } from './directorio/nuevonegocio/nuevonegocio.component';
import { MisnegociosComponent } from './cuenta/misnegocios/misnegocios.component';
import { EditarnegocioComponent } from './directorio/editarnegocio/editarnegocio.component';
import { LogindevComponent } from './logindev/logindev.component';
import { DevGuard } from './_services/dev.guard';
import { PostMasiveComponent } from './modules/UI/post-masive/post-masive.component';


const routes: Routes = [
  { path: '', canActivate:[DevGuard], component: PrincipalComponent },
  { path: 'loginDev', component: LogindevComponent },
  { path: 'restablecer/:id',canActivate:[DevGuard],  component: RestablecerPasswordComponent },
  { path: 'detalle/:id',canActivate:[DevGuard],  component: DetalleproductoComponent},

  { path: 'Anuncios/:subcat',canActivate:[DevGuard],  component: AnunciosComponent  },
  { path: 'AnunciosUsuario/:user',canActivate:[DevGuard],  component: AnunciosusuarioComponent  },
  {
    path: 'Perfil',
    component: PerfilComponent,
    
    children: [
      {path: '', redirectTo: 'MisAnuncios', pathMatch :'full'},
      { path: 'MisAnuncios',   component: MisanunciosComponent},
      { path: 'Mensajes',  component: MensajesComponent}, 
      { path: 'Favoritos',  component: FavoritosComponent},
      { path: 'Editar',  component: EditarperfilComponent},
      { path: 'MisNegocios',  component: MisnegociosComponent}
    ]
  },
  { path: 'Publicar', component: SeleccionarcategoriaComponent, canActivate : [AuthguardGuard,DevGuard]  },
  { path: 'PublicarMasivo', component: PostMasiveComponent, canActivate : [AuthguardGuard,DevGuard]  },
  { path: 'Publicacion/Editar/:id', component: EditaranuncioComponent, canActivate : [AuthguardGuard,DevGuard] },
  { path: 'soporte',canActivate:[DevGuard],  component: SoporteComponent },
  { path: 'buscar/:query', canActivate:[DevGuard], component: BuscaranunciosComponent },
  { path: 'terminos',canActivate:[DevGuard],  component: TerminosComponent },
  { path: 'privacidad', canActivate:[DevGuard], component: PrivacidadComponent },
  { path: 'terminosuso', canActivate:[DevGuard], component: EulaComponent },
  { path: 'directorio', canActivate:[AuthguardGuard,DevGuard], component: NegociosComponent},
  { path: 'directorio/:query',canActivate:[DevGuard],  component: NegociosComponent },
  { path: 'nuevonegocio', component: NuevonegocioComponent, canActivate : [AuthguardGuard,DevGuard] },
  { path: 'editarnegocio/:id', component: EditarnegocioComponent, canActivate : [AuthguardGuard,DevGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
