import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from './../_services/api.service';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Anuncio } from '../@models/anuncio';
import { CrudServiceService } from '../_services/crud-service.service';
import { AnuncioService } from '../_services/models/anuncio.service';
import { DOCUMENT } from '@angular/common';
import postscribe from 'postscribe';

@Component({
  selector: "app-anuncios",
  templateUrl: "./anuncios.component.html",
  styleUrls: ["./anuncios.component.css"],
})
export class AnunciosComponent implements OnInit {
  anuncios: Anuncio[] = [];

  constructor(
    private api: ApiService,
    private anuncioService: AnuncioService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private app: AppComponent,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
    this.activatedRoute.params.subscribe((params) => {
      this.cargarAnuncios(params["subcat"]);
    });
  }
  ngOnInit(): void {}

  ngAfterViewInit() {
    
    postscribe(
      "#adv",
      `<script type="text/javascript">
    amzn_assoc_placement = "adunit0";
    amzn_assoc_search_bar = "false";
    amzn_assoc_tracking_id = "latinosventas-20";
    amzn_assoc_ad_mode = "manual";
    amzn_assoc_ad_type = "smart";
    amzn_assoc_marketplace = "amazon";
    amzn_assoc_region = "US";
    amzn_assoc_title = "";
    amzn_assoc_linkid = "e321148ff32a320503fc8a69cade28e0";
    amzn_assoc_asins = "B08FYTSXGQ,B08N2MSSZY,B089B7LX7N,B07PFL29FR";
    </script>
    <script src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US"></script>`,{
      done: function() {
      }}
    );
  }

  cargarAnuncios(subcategoria): void {
    this.spinner.show();
    this.anuncioService
      .obtener(
        "obtenerAnunciosporCategoria/" +
          subcategoria +
          "/" +
          localStorage.getItem("idUsuario")
      )
      .pipe(first())
      .subscribe(
        (data) => {
          this.anuncios = data;
          this.spinner.hide();
        },
        (err) => {
          console.log(err), this.spinner.hide();
        }
      );
  }

  Agregarfavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem("idUsuario"),
      anuncioId: item._id,
    };

    if (item.tieneFavorito) {
      this.api
        .deleteResponse("favoritos/" + item._id, false)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            Swal.fire(
              "LatinosVentas",
              "Se ha quitado a tus favoritos",
              "success"
            );
          },
          (err) => {}
        );
    } else {
      this.api
        .postResponse("favoritos", false, usuario)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            Swal.fire(
              "LatinosVentas",
              "Se ha agregado a tus favoritos",
              "success"
            );
          },
          (error) => {}
        );
    }
  }

  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem("idUsuario"),
      anuncioId: item._id,
    };


    if (item.tieneFavorito) {
      this.api
        .deleteResponse("favoritos/" + item._id, true)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha quitado de tus favoritos"
            );
            //Swal.fire('LatinosVentas','Se ha quitado a tus favoritos','success');
          },
          (err) => {}
        );
    } else {
      this.api
        .postResponse("favoritos", true, usuario)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha agregado a tus favoritos"
            );
            //Swal.fire('LatinosVentas','Se ha agregado a tus favoritos','success');
          },
          (error) => {}
        );
    }
  }
}
