import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../_services/auth.service';
import { first } from 'rxjs/operators';
import { Usuario } from './../../@models/usuario';
import { ApiService } from './../../_services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { faMobileAlt, faBell, faSearch, faEnvelope, faMapMarkerAlt, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-restablecer',
  templateUrl: './restablecer.component.html',
  styleUrls: ['./restablecer.component.css']
})
export class RestablecerComponent implements OnInit {
  public ids:any;
  registerForm: FormGroup;
  isLoading: Boolean = false;
  message: String = '';
  messageSuccess: String = '';
  id:any;
  @Input() data;
  constructor(
    // public auth: AngularFireAuth, 
    private api: ApiService,private activatedRoute: ActivatedRoute,
    private modal: NgbModal,private toastr: ToastrService, private router:Router) {
    
    this.registerForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
      id: new FormControl('', []),
    });
  }

  ngOnInit(): void {
    // this.activatedRoute.params.subscribe(params => {
    //   console.log('activatedRoute', params['id']);
    //   this.id = params['id'];
    //   this.registerForm.controls['id'].setValue(params["id"]);
    // });
  }

  // signInWithFB(): void {
  //   this.auth.signInWithPopup(new firebase.default.auth.FacebookAuthProvider()).then(data => {
  //     console.log(data);
  //   }).catch(err => { console.log('errorFB', err) });
  // }

  registerWithEmail(): void {
    if (this.registerForm.valid) {
      if (this.registerForm.controls['password'].value != this.registerForm.controls['confirmPassword'].value) {
        this.message = 'Las contraseñas no coinciden';
        return;
      }

      this.registerForm.controls['id'].setValue(this.data);
      console.log(this.registerForm.value);
      this.api.postResponse('restablecer', false, this.registerForm.value).pipe(first())
        .subscribe(
          data => {
            this.modal.dismissAll();
            this.toastr.success('Tu contraseña ha sido actualizada, ya puedes iniciar sesión','Latinosventas');
            this.router.navigateByUrl("/");
          }, error => {
            this.toastr.error(error.error,'Latinosventas');
            this.message = error.error.mensaje;
          })
    }
  }
}
