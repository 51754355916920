import { LoginComponent } from './../@modals/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class AuthguardGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private modal: NgbModal,
    private deviceService: DeviceDetectorService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (environment.dev === true && this.auth.isDevAuthenticated() === false) {
      this.router.navigateByUrl("loginDev");
      return;
    }
    if (!this.auth.isAuthenticated()) {
      this.modal.open(LoginComponent);
      return false;
    }
    return true;
  }

  isMobile(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.deviceService.isMobile()) {
      this.router.navigateByUrl("/terminosuso");
      return false;
    }
    return true;
  }
}
