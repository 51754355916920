import { ToastrService } from 'ngx-toastr';
import { LoginComponent } from './../@modals/login/login.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private modal: NgbModal,private toastr: ToastrService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(tap(() => { },
      (err: any) => {
        

        if (err instanceof HttpErrorResponse) {
          
          if (err.status === 401) {
            
            this.toastr.warning('LatinosVentas', 'Inicia sesión para continuar.');
            // this.modal.open(LoginComponent);
            return;
          }
          if (err.status == 500) {
            
            this.toastr.error('LatinosVentas', 'Ha ocurrido un error. Estamos trabajando para solucionarlo lo antes posible.');
            return;
          }
        }
      }));
  }
}
