import { AfterViewInit, Component, OnInit } from '@angular/core';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireStorage } from '@angular/fire/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AppComponent } from 'src/app/app.component';
import { ApiService } from 'src/app/_services/api.service';
// import * as firebase from 'firebase';
import { WindowsService } from "../../_services/windows.service";
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-nuevonegocio',
  templateUrl: './nuevonegocio.component.html',
  styleUrls: ['./nuevonegocio.component.css']
})
export class NuevonegocioComponent implements OnInit {
  windowRef:any;
  verificationCode: string;
  user:any;

  registerForm: FormGroup;
  isloading: Boolean = false;
  message: String = '';
  messageSuccess: String = '';
  imagesToUpload: any = [];
  isUploading: boolean = false;

  provincias: any = [];
  ciudades: any = [];
  categoriasNegocio = [];

  selectedCompanies;
  companies: any[] = [];
  companiesNames = ['Uber', 'Microsoft', 'Flexigen'];

  subs;
  constructor(private api: ApiService, 
    // public authf: AngularFireAuth,
    private win: WindowsService,private router: Router,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    // private storage: AngularFireStorage,
    private translate:TranslateService,
    private imageCompress: NgxImageCompressService,
    ) {
    this.registerForm = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.maxLength(90)]),
      direccion: new FormControl('', [ Validators.maxLength(90)]),
      propietario: new FormControl('', [Validators.required, Validators.maxLength(90)]),
      telefono: new FormControl('', [Validators.required,Validators.pattern("^[0-9]{10}$")]),
      correoNegocio: new FormControl('', [Validators.required, Validators.email]),
      descripcion: new FormControl('', [Validators.required, Validators.maxLength(180)]),
      tags: new FormControl('', [Validators.required]),
      usuarioId: new FormControl('', []),
      categoriaNegocioId: new FormControl(0, [Validators.required]),
      provinciaId: new FormControl(0, []),
      logo: new FormControl('', []),
      verificationCode:new FormControl('', []),
      ubicacion:new FormControl("",[Validators.required])
    });

  }

  
  
  phoneRecaptchaVerifier: any;
  captcha:any;
  
  ngOnInit(): void {
    this.obtenerCategoriasNegocio();
    this.setCaptcha();
   
    this.obtenerProvincias();
    this.companiesNames.forEach((c, i) => {
      this.companies.push({ id: i, name: c });
    });
  }

  ngAfterViewInit(){
    if(environment.local == true){
      
      this.registerForm.controls["descripcion"].setValue("asdasdasda");
      this.registerForm.controls["direccion"].setValue("asdasdasda");
      this.registerForm.controls["correoNegocio"].setValue("oslesam3@gmail.com");
      this.registerForm.controls["nombre"].setValue("katana");
      this.registerForm.controls["telefono"].setValue("3411701817");
      this.registerForm.controls["propietario"].setValue("juan lopez");
    }
  }
  setCaptcha(){
    this.windowRef = this.win.windowRef;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-sign-in-recaptcha');

    this.windowRef.recaptchaVerifier.render()
  }

  addTagFn(name) {
    return { name: name, tag: true };
  }

   async EnviarTelefono() {
    
    if(this.imagesToUpload.length <= 0 ){
      this.toastr.warning('LatinosVentas', 'No has ingresado una imagen para tu negocio');
      return;
    }
    
    try {
      this.isloading = true;
      const appVerifier = this.windowRef.recaptchaVerifier;
      const ver =  appVerifier.verify();
      await this.delay(1000);
      if(ver["i"] == 'undefined' || ver["i"] == undefined){
        this.toastr.warning('LatinosVentas', 'Por favor verifica el captcha.');
        this.isloading = false;
        return;
      }
    }
    catch (error) {
      if (error.code === "auth/invalid-verification-code") {
        this.toastr.error('LatinosVentas', 'El código ingresado no es válido, por favor ingresa el código correcto');
      }
      else if (error.code === "auth/code-expired") {
        this.toastr.error('LatinosVentas', 'El código ingresado ha expirado, por favor vuelva a ingresar el formulario');
      }
      else if(error.code ==="auth/invalid-phone-number"){
        this.toastr.error('LatinosVentas', 'El número de teléfono que ingresaste es inválido, solo son validos numeros de canadá (+1)');
      }
      else{
        this.toastr.error('LatinosVentas', error.code);
      }
      this.isloading = false;
    }
  }
  codigoEnviado: Boolean = false;
  activarReenviar:Boolean = false;
  async enviarSMS() {

    if (this.registerForm.controls["telefono"].value === "") {
      alert("Por favor ingresa el número celular de tu negocio");
      return;
    }
    this.isloading = true;
    const params = new FormData();
    if(environment.local){
      params.append("To", "+52" + this.registerForm.controls["telefono"].value);
    }
    else{
      params.append("To", "+1" + this.registerForm.controls["telefono"].value);
    }
    params.append("Channel", "sms");
    this.api
      .sendTwilioVerify(
        "https://verify.twilio.com/v2/Services/VA1162abab2eb03487f6ced6e0c238f4d8/Verifications",
        true,
        params
      )
      .pipe(first())
      .subscribe(
        (data) => {
          alert(
            "Hemos enviado un codigo a tu celular, por favor ingrésalo a continuación"
          );
          this.codigoEnviado = true;
          this.activarReenviar = false;
          this.isloading = false;
        },
        (err) => {
          if(err.error.code === 60200){
            alert('Por favor verifica que tu número telefónico sea correcto y sea un número Canadiense');
          }
          if(err.error.code === 60203){
            alert(err.error.message);
          }
          this.isloading = false;
        }
      );
  }

  verifyLoginCode() {
    if (this.registerForm.controls["verificationCode"].value === "") {
      alert("Por favor ingresa el código de verificación");
      return;
    }
    this.isloading = true;

    const params = new FormData();
    if(environment.local){
      params.append("To", "+52" + this.registerForm.controls["telefono"].value);
    }
    else{
      params.append("To", "+1" + this.registerForm.controls["telefono"].value);
    }
    params.append("Code", this.registerForm.controls["verificationCode"].value);
    this.api
      .checkTwilioVerify(
        "https://verify.twilio.com/v2/Services/VA1162abab2eb03487f6ced6e0c238f4d8/VerificationCheck",
        true,
        params
      )
      .pipe(first())
      .subscribe(
        (data) => {
          if(data.valid === true){
            this.guardar();
          }
          else{
            this.toastr.error('LatinosVentas', 'Ha ocurrido un error al validar el teléfono, verifica que el código sea correcto');
            this.isloading = false;
          }
          // this.guardar();
        },
        (err) => {
          this.isloading = false;
          if(err.error.code === 20404){
            this.toastr.error('LatinosVentas', 'El código es incorrecto o ha caducado, verifícalo o solicita un nuevo código');
          }
          else{
          this.toastr.error('LatinosVentas', 'Ha ocurrido un error al validar el teléfono, verifica que el código sea correcto');
          }
        }
      );
  }


  verifyLoginCodwe() {
    if (this.registerForm.controls['verificationCode'].value === "") {
      this.toastr.warning('LatinosVentas', 'Por favor ingresa el código de verificación');
      return;
    }
    this.isloading = true;
    this.windowRef.confirmationResult
      .confirm(this.registerForm.controls['verificationCode'].value)
      .then(result => {
        // this.user = result.user;
        this.isloading = false;
        this.guardar();
      })
      .catch(error => {
        if (error.code === "auth/invalid-verification-code") {
          this.toastr.error('LatinosVentas', 'El código ingresado no es válido, por favor ingresa el código correcto');
        }
        else if (error.code === "auth/code-expired") {
          this.toastr.error('LatinosVentas', 'El código ingresado ha expirado, por favor vuelva a ingresar el formulario');
        }
        else if(error.code ==="auth/invalid-phone-number"){
          this.toastr.error('LatinosVentas', 'El número de teléfono que ingresaste es inválido, solo son validos numeros de canadá (+1)');
        }
        else{
          this.toastr.error('LatinosVentas', error.code);
        }
        this.isloading = false;
      });
  }


  obtenerCategoriasNegocio() {
    this.api.getResponse("categoriasNegocio", true).pipe(first()).subscribe(data => {
      this.categoriasNegocio = data;
      
      this.spinner.hide();
    }, err => { console.log(err), this.ciudades = []; this.spinner.hide() });
  }



  eliminarImgCargar(img) { }


  obtenerProvincias() {
    this.api.getResponse('provincia', true).pipe(first()).subscribe(data => {
      this.provincias = data;
      this.spinner.hide();
    }, err => { console.log(err), this.spinner.hide() });
  }

  seleccionarProvincia(evt) {
    this.api.getResponse("ciudad/" + evt, true).pipe(first()).subscribe(data => {
      this.ciudades = data;
      this.spinner.hide();
    }, err => { console.log(err), this.ciudades = []; this.spinner.hide() });
  }


  imagenesUrl:string;
  
  async guardar() {
    this.isloading = true;
    var n = Date.now();
    for (var i = 0; i < this.imagesToUpload.length; i++) {
      const cas = await this.api
      .postResponse("uploadImage", false, {
        img: this.imagesToUpload[i],
      }).toPromise();
      this.imagenesUrl = environment.imageUrl + cas[0].url;
    }

    // await this.delay(5000);

    this.registerForm.controls['usuarioId'].setValue(localStorage.getItem('idUsuario'));
    this.registerForm.controls['logo'].setValue(this.imagenesUrl);
  
    
    this.api.postResponse("nuevoNegocio/", true, this.registerForm.value).pipe(first()).subscribe(data => {
      Swal.fire('LatinosVentas', 'Tu negocio se ha registrado correctamente, y lo estamos validando, puedes verlo en Mi cuenta.', 'success');
      this.isloading = false;
      this.router.navigateByUrl('Perfil/MisNegocios');
    }, err => { 
      this.toastr.warning('LatinosVentas', err.error.text);
      this.eliminarImagenFirebase(this.imagenesUrl);
      this.ciudades = []; 
      this.isloading = false;
    });
  }

  
  imgResultAfterCompress:any;
  croppedImage:any;
  ComprimirImagenSeleccionada(event) {
    if(this.imagesToUpload.length >= 1){
      this.toastr.warning('LatinosVentas', 'Solo se permite subir una imágen');
      return;
    }
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.imageCompress.compressFile(event.target.result, 50, 40).then(
            result => {
              this.imgResultAfterCompress = result;
              this.croppedImage = result;
              var img = new Image();
              img.src = this.croppedImage;
              img.onload = async rs => {
                const img_height = rs.currentTarget['height'];
                const img_width = rs.currentTarget['width'];
                
                if (img_height < 240) {
                  this.toastr.warning('LatinosVentas', 'La imagen tiene que tener una resolución minima de 600x600px');
                  return;
                }
                if (img_width < 240) {
                  this.toastr.warning('LatinosVentas', 'La imagen tiene que tener una resolución minima de 600x600px');
                  return;
                }

                
                this.isUploading = true;
                var n = Date.now();
                this.imagesToUpload.push(result);
                this.isUploading = false;
              }

            }
          );

        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }


  eliminarImagenFirebase(downloadUrl) {
    // return this.storage.storage.refFromURL(downloadUrl).delete();
  }

  reenviar(){
    // this.windowRef.confirmationResult = false;
  }

  
  ubicacionActual: any;
  ubcacionNombre: any;
  handleAddressChange(event) {
    var ub = {
      description: {
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
      },
      name: event.formatted_address
    }
    this.ubicacionActual = ub.name;
    this.registerForm.controls['ubicacion'].setValue( ub.name);
  }

  delay = ms => new Promise(res => setTimeout(res, ms));

  obtenerIdioma(): string {
    return this.translate.currentLang;
  }
}

