import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { finalize, first } from 'rxjs/operators';
import { ApiService } from './../../_services/api.service';
import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';

import { Observable } from "rxjs";
import { NgxImageCompressService } from 'ngx-image-compress';
import { AppComponent } from 'src/app/app.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: "app-seleccionarcategoria",
  templateUrl: "./seleccionarcategoria.component.html",
  styleUrls: ["./seleccionarcategoria.component.css"],
  
})
export class SeleccionarcategoriaComponent implements OnInit {
  imgResultBeforeCompress: string;
  imgResultAfterCompress: string;
  categorias: any = [];
  subcategorias: any = [];
  // registerForm: FormGroup;
  isLoading: Boolean = false;
  message: String = "";
  messageSuccess: String = "";
  catActual: String = "";
  subActual: String = "";

  anuncioForm: FormGroup;
  autosForm: FormGroup;
  anuncioGeneralForm: FormGroup;

  categoriaSeleccionada = -1;
  subcategoriaSeleccionada = -1;
  verAutos: boolean = false;
  verInmuebles: boolean = false;
  verTodo: boolean = false;
  isUploading: boolean = false;

  title = "cloudsSorage";
  selectedFile: File = null;
  fb;
  downloadURL: Observable<string>;
  publicando: boolean = false;
  masivo: boolean = false;
  constructor(
    public api: ApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private app: AppComponent,
    // private storage: AngularFireStorage,
    private imageCompress: NgxImageCompressService,
    private toastr: ToastrService,
    private translate:TranslateService
  ) {
    if(localStorage.getItem("msv") == "undefined"){
    this.masivo = false;
    }
    else{
      this.masivo =(localStorage.getItem("msv") === 'true');
    }
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
    this.anuncioForm = new FormGroup({
      titulo: new FormControl("", [Validators.required]),
      descripcion: new FormControl("", [Validators.required]),
      precio: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      categoriaId: new FormControl("", null),
      subCategoriaId: new FormControl("", null),
      imagenes: new FormControl(),
      tipoInmueble: new FormControl(1, [Validators.required]),
      numHabitaciones: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      numBanios: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      estacionamientos: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      superficie: new FormControl(null, [
        Validators.required,
        Validators.pattern("^[0-9]+([.][0-9]+)?$"),
      ]),
      usuarioId: new FormControl(),
      ubicacion: new FormControl("", [Validators.required]),
      direccion: new FormControl("", [Validators.required]),
      esGratuito: new FormControl(false, [Validators.required]),
      mostrarTelefono: new FormControl(false, [Validators.required]),
    });
    this.autosForm = new FormGroup({
      titulo: new FormControl("", [Validators.required]),
      descripcion: new FormControl("", [Validators.required]),
      precio: new FormControl("", [Validators.required]),
      categoriaId: new FormControl("", null),
      subCategoriaId: new FormControl("", null),
      imagenes: new FormControl(),
      ubicacion: new FormControl(),
      direccion: new FormControl("", [Validators.required]),
      marca: new FormControl("", [Validators.required]),
      marcaAuto: new FormControl("", [Validators.required]),
      anioAuto: new FormControl("", [Validators.required]),
      transmision: new FormControl("", [Validators.required]),
      combustible: new FormControl("", [Validators.required]),
      kilometraje: new FormControl("", [Validators.required]),
      usuarioId: new FormControl(),
      esGratuito: new FormControl(false, [Validators.required]),
      mostrarTelefono: new FormControl(false, [Validators.required]),
    });
    this.anuncioGeneralForm = new FormGroup({
      titulo: new FormControl("", [Validators.required]),
      descripcion: new FormControl("", [Validators.required]),
      precio: new FormControl("", [Validators.required]),
      categoriaId: new FormControl("", null),
      subCategoriaId: new FormControl("", null),
      imagenes: new FormControl(),
      usuarioId: new FormControl(),
      ubicacion: new FormControl("", [Validators.required]),
      direccion: new FormControl("", [Validators.required]),
      esGratuito: new FormControl(false, [Validators.required]),
      mostrarTelefono: new FormControl(false, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.api
      .getResponse("categorias")
      .pipe(first())
      .subscribe(
        (data) => {
          this.categorias = data.filter((p) => p.activa && !p.eliminada);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  publicar() {
    this.onFileSelected(this.croppedImage);
    return;
  }

  publicarAutos(img) {
    this.spinner.show();

    if (!this.autosForm.valid) {
      Swal.fire(
        "LatinosVentas",
        "Por favor verifica los campos requeridos",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    this.autosForm.controls["categoriaId"].setValue(this.catActual);
    // this.autosForm.controls['subCategoriaId'].setValue(this.subActual);

    if (img == "") {
      Swal.fire(
        "LatinosVentas",
        "Por favor agrega una imagen a tu anuncio",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    var arrImg = img;
    this.autosForm.controls["imagenes"].setValue(arrImg);

    this.autosForm.controls["usuarioId"].setValue(localStorage.getItem("_id"));
    this.autosForm.controls["ubicacion"].setValue(this.ubicacionActual);
    this.api
      .postResponse("anuncio", false, this.autosForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hide();
          Swal.fire("LatinosVentas", "Tu anuncio se ha publicado", "success");
          this.publicando = false;
          this.router.navigateByUrl("Perfil/MisAnuncios");
        },
        (err) => {
          this.spinner.hide();
          this.publicando = false;
          Swal.fire(
            "LatinosVentas",
            "Ha ocurrido un error al subir la imagen",
            "error"
          );
          // this.nav.navigateBack(['/login']);
        }
      );
  }

  publicarOtros(img) {
    this.spinner.show();

    if (!this.anuncioGeneralForm.valid) {
      Swal.fire(
        "LatinosVentas",
        "Por favor verifica los campos requeridos",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    this.anuncioGeneralForm.controls["categoriaId"].setValue(this.catActual);
    // this.anuncioGeneralForm.controls['subCategoriaId'].setValue(this.subActual);

    if (img == "") {
      Swal.fire(
        "LatinosVentas",
        "Por favor agrega una imagen a tu anuncio",
        "warning"
      );
      this.spinner.hide();
      return;
    }

    var arrImg = img;
    this.anuncioGeneralForm.controls["imagenes"].setValue(arrImg);

    this.anuncioGeneralForm.controls["usuarioId"].setValue(
      localStorage.getItem("_id")
    );
    this.anuncioGeneralForm.controls["ubicacion"].setValue(
      this.ubicacionActual
    );
    this.api
      .postResponse("anuncio", false, this.anuncioGeneralForm.value)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hide();
          Swal.fire("LatinosVentas", "Tu anuncio se ha publicado", "success");
          this.publicando = false;
          this.router.navigateByUrl("Perfil/MisAnuncios");
        },
        (err) => {
          this.spinner.hide();
          Swal.fire(
            "LatinosVentas",
            "Ha ocurrido un error al subir la imagen",
            "error"
          );
          this.publicando = false;
          // this.nav.navigateBack(['/login']);
        }
      );
  }

  cargarCat(id: String) {
    this.catActual = id;
    this.subActual = "";
    this.subcategoriaSeleccionada = -1;
    this.cargarSub(id);
    window.scrollTo(0, 0);
  }

  cargarSub(id: any) {
    this.ocultarPrecioInmuebles = false;
    if (id.nombre === "Inmuebles") {
      this.verInmuebles = true;
      this.verAutos = false;
      this.verTodo = false;
      this.anuncioForm.controls["esGratuito"].setValue(false);
    } else if (id.nombre === "Vehículos") {
      this.verInmuebles = false;
      this.verAutos = true;
      this.verTodo = false;
      this.autosForm.controls["esGratuito"].setValue(false);
    } else {
      this.verInmuebles = false;
      this.verAutos = false;
      this.verTodo = true;
      this.anuncioGeneralForm.controls["esGratuito"].setValue(false);
    }
    this.subActual = id;
  }

  imageChangedEvent: any = "";
  croppedImage: any = "";
  imagesToUpload: any = [];
  fileChwangeEvent(event: any): void {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imgResultBeforeCompress = image;
      this.imageCompress.compressFile(image, 50, 50).then((result) => {
        this.imgResultAfterCompress = result;
        this.croppedImage = result;
        var img = new Image();
        img.src = this.croppedImage;
        img.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          if (img_height < 150) {
            this.toastr.warning(
              "LatinosVentas",
              "La imagen tiene que tener una resolución minima de 300x300px"
            );
            return;
          }
          if (img_width < 150) {
            this.toastr.warning(
              "LatinosVentas",
              "La imagen tiene que tener una resolución minima de 300x300px"
            );
            return;
          }

          this.isUploading = true;
          var n = Date.now();

          const filePath = `Anuncios/post_${new Date().getTime()}.jpg`;
        };
      });
    });
  }

  ubicacionActual: any;
  ubcacionNombre: any;
  handleAddressChange(event) {
    var ub = {
      description: {
        latitude: event.geometry.location.lat(),
        longitude: event.geometry.location.lng(),
      },
      name: event.formatted_address,
    };
    this.ubicacionActual = ub;
  }

  imagenesUrl: any = [];
  async onFileSelected(image) {
    this.publicando = true;
    this.spinner.show();
    var n = Date.now();
    const files = this.imagesToUpload;


    for (var i = 0; i < this.imagesToUpload.length; i++) {
      const cas = await this.api
        .postResponse("uploadImage", false, {
          img: this.imagesToUpload[i],
        }).toPromise();
        this.imagenesUrl.push( environment.imageUrl + cas[0].url);
    }
    
    if (this.verInmuebles) {
      if (!this.anuncioForm.valid) {
        Swal.fire(
          "LatinosVentas",
          "Por favor verifica los campos requeridos",
          "warning"
        );
        this.spinner.hide();
        return;
      }

      this.spinner.show();
      this.anuncioForm.controls["categoriaId"].setValue(this.catActual);
      // this.anuncioForm.controls['subCategoriaId'].setValue(this.subActual);
      var arrImg = this.imagenesUrl;
      this.anuncioForm.controls["imagenes"].setValue(arrImg);

      this.anuncioForm.controls["usuarioId"].setValue(
        localStorage.getItem("_id")
      );
      this.anuncioForm.controls["ubicacion"].setValue(this.ubicacionActual);
      this.api
        .postResponse("anuncio", false, this.anuncioForm.value)
        .pipe(first())
        .subscribe(
          (data) => {
            this.spinner.hide();
            Swal.fire("LatinosVentas", "Tu anuncio se ha publicado", "success");
            this.publicando = false;
            this.router.navigateByUrl("Perfil/MisAnuncios");
          },
          (err) => {
            this.spinner.hide();
            this.publicando = false;
            Swal.fire(
              "LatinosVentas",
              "Ha ocurrido un error al subir la imagen",
              "error"
            );
          }
        );
    } else if (this.verAutos) {
      this.publicarAutos(this.imagenesUrl);
    } else if (this.verTodo) {
      this.publicarOtros(this.imagenesUrl);
    }
    // this.spinner.hide();
    // // await this.delay(5000);
    // return;
  }

  ocultarPrecioInmuebles: boolean = true;
  ocultarPrecio() {
    this.ocultarPrecioInmuebles = !this.ocultarPrecioInmuebles;
    this.autosForm.controls["precio"].setValue(0);
    this.anuncioForm.controls["precio"].setValue(0);
    this.anuncioGeneralForm.controls["precio"].setValue(0);
  }

  eliminarImgCargar(img) {
    const index = this.imagesToUpload.indexOf(img);
    if (index > -1) {
      this.imagesToUpload.splice(index, 1);
    }

    // this.storage.ref('post_1612398649409.jpg').delete();
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.imageCompress
            .compressFile(event.target.result, 50, 50, 40)
            .then((result) => {
              this.imgResultAfterCompress = result;
              this.croppedImage = result;
              var img = new Image();
              img.src = this.croppedImage;
              img.onload = async (rs) => {
                const img_height = rs.currentTarget["height"];
                const img_width = rs.currentTarget["width"];

                if (img_height < 150) {
                  this.toastr.warning(
                    "LatinosVentas",
                    "La imagen tiene que tener una resolución minima de 300x300px"
                  );
                  return;
                }
                if (img_width < 150) {
                  this.toastr.warning(
                    "LatinosVentas",
                    "La imagen tiene que tener una resolución minima de 300x300px"
                  );
                  return;
                }

                this.isUploading = true;
                var n = Date.now();
                this.imagesToUpload.push(result);
                this.isUploading = false;
              };
            });
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  obtenerIdioma(): string {
    return this.translate.currentLang;
  }

  delay = (ms) => new Promise((res) => setTimeout(res, ms));
}
