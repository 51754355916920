import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoadingButtonComponent } from "./loading-button/loading-button.component";
import { AnuncioCardComponent } from "./anuncio-card/anuncio-card.component";
import { RouterModule } from "@angular/router";
import { NgSelectModule } from "@ng-select/ng-select";
import { PostMasiveComponent } from "./post-masive/post-masive.component";
import { SortablejsModule } from "ngx-sortablejs";
import { TranslateModule } from "@ngx-translate/core";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    LoadingButtonComponent,
    AnuncioCardComponent,
    // PostMasiveComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    SortablejsModule.forRoot({ animation: 150 }),
    TranslateModule,
    GooglePlaceModule,
    NgSelectModule,
    NgxSpinnerModule
  ],
  exports: [LoadingButtonComponent, AnuncioCardComponent, 
    // PostMasiveComponent
  ],
})
export class UiModule {}
