import { environment } from "./../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  token: string = "Bearer " + localStorage.getItem("token");
  isConnected = false;
  validar: boolean = false;

  constructor(private http: HttpClient) {}

  getResponse(url: String, auth?: Boolean, params?: any) {
    this.token = "" + localStorage.getItem("token");
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ Authorization: this.token }),
      };
      return this.http.get<any>(`${environment.apiUrl}${url}`, httpOptions);
    } else {
      return this.http.get<any>(`${environment.apiUrl}${url}`);
    }
  }

  getResponseSingle(url: String, auth?: Boolean, params?: any) {
    return this.http.get<any>(`${url}`);
  }

  postResponse(url: String, auth?: Boolean, params?: any) {
    this.token = "" + localStorage.getItem("token");
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ Authorization: this.token }),
      };
      return this.http.post<any>(
        `${environment.apiUrl}${url}`,
        params,
        httpOptions
      );
    } else {
      return this.http.post<any>(`${environment.apiUrl}${url}`, params);
    }
  }

  putResponse(url: String, auth?: Boolean, params?: any) {
    this.token = "" + localStorage.getItem("token");
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ Authorization: this.token }),
      };
      return this.http.put<any>(
        `${environment.apiUrl}${url}`,
        params,
        httpOptions
      );
    } else {
      return this.http.put<any>(`${environment.apiUrl}${url}`, params);
    }
  }

  fileResponse(url: String, auth?: Boolean, params?: any) {
    this.token = "" + localStorage.getItem("token");
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.token,
          enctype: "multipart/form-data",
        }),
      };
      return this.http.post<any>(
        `${environment.apiUrl}${url}`,
        params,
        httpOptions
      );
    } else {
      return this.http.post<any>(`${environment.apiUrl}${url}`, params);
    }
  }

  deleteResponse(url: String, auth?: Boolean, params?: any) {
    this.token = "" + localStorage.getItem("token");
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ Authorization: this.token }),
      };
      return this.http.delete<any>(`${environment.apiUrl}${url}`, httpOptions);
    } else {
      return this.http.delete<any>(`${environment.apiUrl}${url}`);
    }
  }

  pdfResponse(url: String, auth?: Boolean, params?: any) {
    this.token = "" + localStorage.getItem("token");
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({
          Authorization: this.token,
        }),
        responseType: "blob" as "json",
      };
      return this.http.get<any>(`${environment.apiUrl}${url}`, httpOptions);
    } else {
      return this.http.get<any>(`${environment.apiUrl}${url}`);
    }
  }

  tokenDev: string = "Bearer " + localStorage.getItem("token");

  loginDev(url: String, auth?: Boolean, params?: any): Observable<any> {
    this.tokenDev = "Bearer " + localStorage.getItem("token");
    if (auth) {
      const httpOptions = {
        headers: new HttpHeaders({ Authorization: this.tokenDev }),
      };
      return this.http.post<any>(`${environment.apiUrl}${url}`, httpOptions);
    } else {
      return this.http.post<any>(`${environment.apiUrl}${url}`, params);
    }
  }

  sendTwilioVerify(url: String, auth?: Boolean, params?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:
          "Basic " +
          btoa(
            "AC1c772f01acdb64a7bebe5fc59d608246:ad6229e96dcc696b0190a51c29eeafc4"
          ),
      }),
    };
    return this.http.post<any>(`${url}`, params, httpOptions);
  }

  checkTwilioVerify(url: String, auth?: Boolean, params?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization:
          "Basic " +
          btoa(
            "AC1c772f01acdb64a7bebe5fc59d608246:ad6229e96dcc696b0190a51c29eeafc4"
          ),
      }),
    };
    return this.http.post<any>(`${url}`, params, httpOptions);
  }
}
