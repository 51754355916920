<!-- DESCUBRE -->
<section class="women-banner spad container-fluid" *ngIf="!loading && negocios.length > 0" style="padding-bottom: 0px;">
  <div>
    <br>
    <h3 class="section-title">{{'DIRECTORIOLATINO' | translate}}</h3>
  </div>
  <div class="">
    <owl-carousel-o [options]="customOptions" style=" height: 300px !important;">
      <ng-template carouselSlide [width]="item.merge" *ngFor="let item of negocios" style=" height: 300px !important;">
        <div class="row" style="margin-bottom: 100px; height: 300px;">
          <div *ngIf="negocios.length <= 0" class="center-div text-center">
            <img src="../../assets/img/notfound.png" alt="">
            <div class="h1">{{'NOHAYELEMENTOS' | translate}}</div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="product-slider shadow-box" style="min-width: 590px !important;margin-left: 10px;">
              <div class="row">
                <div class="col-lg-5  col-md-4 col-sm-4 col-xs-12">
                  <div class="pi-pic" style="background-color: #f9f9f9;cursor: pointer;">
                    <img [src]="item.logo" alt="" class="img-anuncio img-fluid">
                    <ul>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-7  col-md-8 col-sm-8 col-xs-12" style="padding: 10px 25px 5px 5px;">
                  <div class="product-item">
                    <div>
                      <span style="font-size: 20px; text-decoration: none; color:#e7ab3c;">{{item.nombre}}</span>
                      <p style="font-size: 15px; text-decoration: none; color:#292929 ;">
                        {{item.direccion}} - TEL: {{item.telefono}}</p>
                      <p
                        style="font-size: 15px; text-decoration: none; color:#282828;word-wrap: break-word;line-height: normal;">
                        {{item.descripcion | slice:0:180}}<span *ngIf="item.descripcion.length > 180"
                          style="font-size: 15px; text-decoration: none; color:#6d6d6d">...</span> </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>




</section>

<div class="text-center" style="margin-top: -100px;">
  <hr>
  <h3>{{'ENCUENTRAARTICULOS' | translate}}</h3>
  <hr>
</div>

<section class="women-banner spad container">
  <div *ngFor="let item of this.categorias" style="margin-bottom: 35px;">
    <div *ngIf="cargarAnunciosCat(item._id).length > 0" class="text-center m-1">

    </div>
    <h3 class="section-title" style="cursor:pointer;" routerLink="{{abrirProductos(item._id)}}"
      *ngIf="cargarAnunciosCat(item._id).length >= 4">
      <div *ngIf="obtenerIdioma() == 'en'">
        {{item.nombreEN}}
      </div>
      <div *ngIf="obtenerIdioma() == 'es'">
        {{item.nombre}}
      </div>
    </h3>

    <div *ngIf="cargarAnunciosCat(item._id).length >= 4">

      <owl-carousel-o [options]="customOptions3" #owlCat>

        <ng-container
          *ngFor="let item of cargarAnunciosCat(item._id).slice(0,getMax(cargarAnunciosCat(item._id))); let i = index">
          <ng-template carouselSlide [id]="item.id">
            <div class="row" style="padding: 10px;">
              <div class="col-lg-12 col-md-4 col-sm-6 col-xs-6 ">
                <div>
                  <app-anuncio-card [anuncio]="item" class="borbox"></app-anuncio-card>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>

      <owl-carousel-o [options]="customOptions2" #owlMac (changed)="changeSlide($event)"
        *ngIf="getMax2(cargarAnunciosCat(item._id)) > 4">
        <ng-container
          *ngFor="let item of cargarAnunciosCat(item._id).slice(getMax2(cargarAnunciosCat(item._id))); let i = index">
          <ng-template carouselSlide [id]="item.id">
            <div class="row" style="padding: 10px;">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-6 ">
                <div>
                  <app-anuncio-card [anuncio]="item" class="borbox"></app-anuncio-card>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</section>

<div class="m-1 ">
  <div id="adv" style="  margin: auto;width: 50%;padding: 10px;"></div>
</div>

<ngx-spinner bdColor="rgba(0,0,0,0.01)" name="spinner-popular" size="default" color="#57b1b9" type="ball-clip-rotate"
  [fullScreen]="false">
  <p style="color: #57b1b9"> Loading... </p>
</ngx-spinner>