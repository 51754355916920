


<div class="cont123">
    <div class="anuncio-box shadow-box"  style="cursor: pointer;">
        <div class="product-item">
            <div class="pi-pic">
                <div [style.background-image]="'url(' + anuncio?.imagenes[0] + ')'" [style.width]="100"
                    [style.height]="'12rem'" [style.background-size]="'cover'" [style.background-position]="'50%'"
                    [style.background-repeat]="'no-repeat'" [style.border-radius]="'0.6rem 0.6rem 0 0'" routerLink="/detalle/{{anuncio?._id}}"></div>
                <div class="icon icono-favorito">
                    <i class="icon_heart icono-fav-activo" *ngIf="anuncio?.tieneFavorito"
                        (click)="agregarFavoritos(anuncio)"></i>
                    <i class="icon_heart" *ngIf="!anuncio?.tieneFavorito" (click)="agregarFavoritos(anuncio)"></i>
                </div>
            </div>
            <a routerLink="/detalle/{{anuncio?._id}}">
                <div class="pi-text">
                    <span class="anuncio-titulo">{{anuncio?.titulo |
                        slice:0:50}}<span *ngIf="anuncio?.titulo.length >=50">...</span>
                    </span>
                    <br>
                    <span class="anuncio-ubicacion">{{anuncio.ubicacion}}</span>
                    <div class="card-data-bottom ">
                        <div *ngIf="!anuncio?.esGratuito" class="product-price">
                            {{anuncio?.precio | currency : 'USD' : 'symbol' : '1.0-0'}} <label style="font-size: 14px;">CAD</label>
                        </div>
                        <div *ngIf="anuncio?.esGratuito" class="product-price">
                            Gratis
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>