import { AuthService } from "./../../_services/auth.service";
import { environment } from "./../../../environments/environment";
import { ApiService } from "./../../_services/api.service";
import { first } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";
import Swal from 'sweetalert2';
import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { AppComponent } from "src/app/app.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-perfil",
  templateUrl: "./perfil.component.html",
  styleUrls: ["./perfil.component.css"],
})
export class PerfilComponent implements OnInit {
  perfil: any;

  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private app: AppComponent,
    private spinner: NgxSpinnerService,
    private auth: AuthService,
    private toastr: ToastrService,
    private translateService:TranslateService
  ) {
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
  }

  ngOnInit(): void {
    this.api
      .getResponse("usuarioEmail/" + localStorage.getItem("correo"), true)
      .pipe(first())
      .subscribe(
        (data) => {
          this.perfil = data;
        },
        (err) => {
          console.log(err);
        }
      );
  }

  eliminarCuenta(){
    Swal.fire({
      title:  this.translateService.instant("ELIMINARCUENTATXT"),
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText:  this.translateService.instant("DESACTIVAR") ,
      denyButtonText: this.translateService.instant("ELIMINAR"),
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        var params = {
          _id: localStorage.getItem("idUsuario"),
        }
        this.api
          .putResponse("desactivarCuenta", true, params)
          .pipe(first())
          .subscribe(
            (data) => {
              this.auth.logout();
              this.toastr.success('LatinosVentas', this.translateService.instant("CUENTADESACTIVADA"));
            },
            (err) => {
            
              this.toastr.success('LatinosVentas', JSON.stringify(err));
            }
          );
      } else if (result.isDenied) {
        Swal.fire({
          title: this.translateService.instant("ELIMINARCUENTA"),
          text: this.translateService.instant("ELIMINARCUENTATXT"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.translateService.instant("ELIMINAR")
        }).then((result) => {
          if (result.isConfirmed) {
            var params = {
              _id: localStorage.getItem("idUsuario"),
            };
            this.api
              .postResponse("eliminarCuenta", true, params)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.auth.logout();
                  this.toastr.success('LatinosVentas', this.translateService.instant("SEHAELIMINADO"));
                },
                (err) => {
                  // console.log(err);
                  // this.loadingService.dismiss();
                }
              );
          }
        })
      }
    })
  }

  logout() {
    this.auth.logout();
  }
}
