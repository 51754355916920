import { AuthService } from './../../_services/auth.service';
import { environment } from './../../../environments/environment';
import { ApiService } from './../../_services/api.service';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-misnegocios',
  templateUrl: './misnegocios.component.html',
  styleUrls: ['./misnegocios.component.css']
})
export class MisnegociosComponent implements OnInit {

  negocios: any = [];

  constructor(private api: ApiService, private toastr: ToastrService, private deviceService: DeviceDetectorService, private app: AppComponent,
    private spinner: NgxSpinnerService, private auth: AuthService,private router:Router) {
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
  }

  ngOnInit(): void {
    this.CargarNegocios();
  }

  CargarNegocios() {
    this.api.getResponse('negociosPorUsuario/' + localStorage.getItem('idUsuario'), true).pipe(first())
      .subscribe(
        data => {
          this.negocios = data;
        }, err => {
        });
  }

  logout() {
    this.auth.logout();
  }

  eliminar(id) {
    Swal.fire({
      title: '¿Deseas eliminar este negocio?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Aceptar`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.api.deleteResponse('negocios/' + id, true).pipe(first())
          .subscribe(
            data => {
              // Swal.fire('Latinosventas', '', 'success')
              this.toastr.success('LatinosVentas', 'Se ha eliminado el negocio!');
              this.CargarNegocios();
            }, err => {
            });

      } else if (result.isDenied) {
        //Swal.fire('No se ha podido eliminar', '', 'info')
        this.toastr.error('LatinosVentas', 'No se ha podido eliminar el negocioa');
      }
    })
  }

  editar(id) {
    this.router.navigateByUrl('editarnegocio/' + id);
  }

}
