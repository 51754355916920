import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/operators';
import { ApiService } from '../_services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-anunciosusuario',
  templateUrl: './anunciosusuario.component.html',
  styleUrls: ['./anunciosusuario.component.css']
})
export class AnunciosusuarioComponent implements OnInit {
  anuncios: any = [];

  constructor(private api: ApiService, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService) {
    this.activatedRoute.params.subscribe(params => {
      this.cargarAnuncios(params['user']);
    });
  }
  ngOnInit(): void {
  }

  cargarAnuncios(subcategoria): void {
    this.spinner.show();
    this.api.getResponse('obtenerAnunciosporUsuario/' + subcategoria).pipe(first()).subscribe(data => {
      this.anuncios = data;
      this.spinner.hide();
    }, err => { console.log(err), this.spinner.hide() });
  }


  Agregarfavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem('idUsuario'),
      anuncioId: item._id
    };

    if (item.tieneFavorito) {
      this.api.deleteResponse('favoritos/' + item._id, false).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            Swal.fire('LatinosVentas', 'Se ha quitado a tus favoritos', 'success');
          }, err => {
          });

    }
    else {
      this.api.postResponse('favoritos', false, usuario).pipe(first())
        .subscribe(
          data => {
            item.tieneFavorito = !item.tieneFavorito;
            Swal.fire('LatinosVentas', 'Se ha agregado a tus favoritos', 'success');
          }, error => {

          })
    }

  }


}
