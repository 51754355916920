import { AuthService } from "./../_services/auth.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "./../_services/api.service";
import { CarouselComponent, OwlOptions } from "ngx-owl-carousel-o";
import { Router, ActivatedRoute } from "@angular/router";
import { first } from "rxjs/operators";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AppComponent } from "../app.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { AnuncioService } from "../_services/models/anuncio.service";
import { Anuncio } from "../@models/anuncio";
import postscribe from "postscribe";
import { TranslateService } from "@ngx-translate/core";
import { NguCarouselConfig } from "@ngu/carousel";
import { interval } from "rxjs";

@Component({
  selector: "app-principal",
  templateUrl: "./principal.component.html",
  styleUrls: ["./principal.component.css"],
})
export class PrincipalComponent implements OnInit {
  @ViewChild("owlMac") owlMac: CarouselComponent;
  @ViewChild("owlCat") owlCat: CarouselComponent;

  customOptions: OwlOptions = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["<", ">"],
    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 1000,
    autoplaySpeed: 1000,
    autoplayHoverPause: false,
    loop: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 8,
      },
      740: {
        items: 4,
      },
      940: {
        items: 8,
      },
      1024: {
        items: 8,
      },
      1920: {
        items: 8,
      },
    },
    nav: true,
  };
  anuncios: Anuncio[] = [];
  loading: boolean = false;
  sinResultados: boolean = false;
  textoBusqueda: string = "";
  categorias: any = [];
  negocios: any = [];

  constructor(
    private api: ApiService,
    private anuncioService: AnuncioService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    private app: AppComponent,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private auth: AuthService,
    private translate: TranslateService
  ) {
    if (this.deviceService.isMobile()) {
      app.activarMobiel(true);
    }
  }

  ngOnInit(): void {
    this.cargarAnuncios(null);
    this.obtenerNegocios(0, 0, "undefined");
    this.cargarCategorias();
  }

  ngAfterViewInit() {

  }

  obtenerNegocios(ciudadId, catId, busqueda?) {
    this.api
      .getResponse("negociosPrincipal", true)
      .pipe(first())
      .subscribe(
        (data) => {
          this.negocios = data;
          this.negocios.forEach((element) => {
            if (!element.merge) {
              element.merge = 300;
            }
          });
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  cargarCategorias() {
    this.api
      .getResponse("categorias")
      .pipe(first())
      .subscribe(
        (data) => {
          this.categorias = data;
        },
        (err) => {
        }
      );
  }

  async cargarAnuncios(event) {
    this.spinner.show("spinner-popular");
    this.textoBusqueda = "";
    this.loading = true;
    var busqueda = localStorage.getItem("busqueda");
    if (busqueda == "" || busqueda == null || busqueda == undefined) {
      this.anuncioService
        .obtener("anuncios/" + localStorage.getItem("idUsuario"))
        .pipe(first())
        .subscribe(
          (data) => {
            this.loading = false;
            this.spinner.hide("spinner-popular");
            this.anuncios = data;
            if (this.anuncios.length <= 0) this.sinResultados = true;
            else this.sinResultados = false;
            if (event != null) event.target.complete();
          },
          (err) => {
            this.loading = false;
            this.spinner.hide("spinner-popular");
            this.sinResultados = true;
            event.target.complete();
          }
        );
    } else {
      this.textoBusqueda = busqueda;
      this.anuncioService
        .obtener("buscarAnuncios/" + localStorage.getItem("busqueda"))
        .pipe(first())
        .subscribe(
          (data) => {
            this.loading = false;
            this.spinner.hide("spinner-popular");
            this.anuncios = data;
            if (this.anuncios.length <= 0) this.sinResultados = true;
            else this.sinResultados = false;
            if (event != null) event.target.complete();
          },
          (err) => {
            this.loading = false;
            this.spinner.hide("spinner-popular");
            this.sinResultados = true;
            event.target.complete();
          }
        );
    }
  }

  cargarAnunciosCat(id) {
    return this.anuncios.filter((x) => x.categoriaId == id); //.slice(0, 4);
  }

  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem("idUsuario"),
      anuncioId: item._id,
    };


    if (item.tieneFavorito) {
      this.api
        .deleteResponse("favoritos/" + item._id, true)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha quitado de tus favoritos"
            );
            //Swal.fire('LatinosVentas','Se ha quitado a tus favoritos','success');
          },
          (err) => {}
        );
    } else {
      this.api
        .postResponse("favoritos", true, usuario)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha agregado a tus favoritos"
            );
            //Swal.fire('LatinosVentas','Se ha agregado a tus favoritos','success');
          },
          (error) => {}
        );
    }
  }

  obtenerIdioma(): string {
    return this.translate.currentLang;
  }

  abrirProductos(id): string {
    return "/Anuncios/" + id;
  }
  customOptions2: OwlOptions = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,

    autoWidth: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    autoplayHoverPause: true,
    loop: true,
    autoHeight: false,

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 8,
      },
      740: {
        items: 4,
      },
      940: {
        items: 8,
      },
      1024: {
        items: 4,
      },
      1920: {
        items: 6,
      },
    },
    nav: false,
    navText: ["<", ">"],
  };

  customOptions3: OwlOptions = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,

    autoWidth: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplaySpeed: 2000,
    autoplayHoverPause: true,
    loop: true,
    autoHeight: false,

    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 4,
      },
      740: {
        items: 4,
      },
      940: {
        items: 4,
      },
      1024: {
        items: 4,
      },
      1920: {
        items: 4,
      },
    },
    nav: false,
    navText: ["<", ">"],
  };

  slideTo(category: string) {
    this.owlMac.moveByDot(category);
  }

  changeSlide($event) {
    if (this.owlCat) {
      // this.category$.next($event.slides[0].id);
      this.owlCat.moveByDot(this.owlCat.dotsData.dots[$event.startPosition].id);
    }
  }

  getMax(arr) {
    if (arr.length > 8) {
      if (arr.length / 2 < 4) {
        return 4;
      }
      return arr.length / 2;
    } else {
      if(arr.length >= 4 && arr.length< 8){
      return arr.length;
      }
    }
  }

  getMax2(arr) {
    if (arr.length < 8) {
      return 0;
    } else {
      if (arr.length / 2 < 4) {
        return 4;
      }
      return arr.length / 2;
    }
  }
}
