import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacidad',
  templateUrl: './privacidad.component.html',
  styleUrls: ['./privacidad.component.css']
})
export class PrivacidadComponent implements OnInit {
  espanol:boolean = false;
  constructor(private translate:TranslateService) { }

  ngOnInit(): void {
    if(this.translate.currentLang == 'es'){
      this.espanol = true;
    }
    else{
      this.espanol = false;
    }
  }

}
