<div *ngIf="!this.isLoading">
  <div *ngIf="anuncios.length <= 0" class="center-div text-center">
    <img src="../../assets/img/notfound.png" alt="" width="100">
    <div class="h3">{{'NOHAYELEMENTOS' | translate}}</div>
  </div>
</div>
<div class="row"  *ngIf="this.masivo "> 
  <div class="col-lg-12">
    <button type="button" *ngIf="masivo" style="margin-bottom:24px;margin-left: 0px;" class="btn-post" routerLink="/PublicarMasivo">Publicar
      Masivo</button>
  </div>
</div>

<ul class="list-group" *ngIf="anuncios.length > 0">
  <li class="list-group-item" *ngFor="let item of anuncios">
    <div class="row">
      <div class="col-lg-1 col-md-1 col-sm-2">
        <a routerLink="/detalle/{{item._id}}">
          <img [src]="item.imagenes[0]" alt="">
        </a>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="pi-text">
          <a routerLink="/detalle/{{item._id}}">
            <h5>{{item.titulo | slice:0:35}} <span *ngIf="item.titulo.length >=35">...</span></h5>
          </a>
          <div *ngIf="!item.esGratuito" class="product-price">
            {{item.precio | currency : 'USD' : 'symbol' : '1.0-0'}}
          </div>
          <div *ngIf="item.esGratuito" class="product-price">
            Gratis
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-12 col-sm-12">
        <div class="icon">
          <i class="icon_trash_alt" style="cursor: pointer;" (click)="eliminar(item._id)"></i>
          <span *ngIf="item.estatus != 'EXPIRADO'" class="edit-btn" (click)="editar(item?._id)">
            <i class="icon_pencil-edit" style="cursor: pointer;" ></i>
          </span>
          <span *ngIf="item.estatus == 'EXPIRADO'" class="expirado"  style="cursor: pointer;"  (click)="reanudarAnuncio(item)"> 
            <ion-icon name="warning-outline"></ion-icon> Expirado</span>
        </div>
      </div>
    </div>
    <!-- <div style="background-color: #f9f9f9;"> -->



    <!-- </div> -->

  </li>
</ul>


<ngx-spinner bdColor="rgba(0,0,0,0.01)" size="default" color="#57b1b9" type="ball-clip-rotate" [fullScreen]="false">
  <p style="color: #57b1b9">{{'CARGANDO' | translate}}</p>
</ngx-spinner>