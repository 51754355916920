import { ToastrService } from "ngx-toastr";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ApiService } from "./../_services/api.service";
import { OwlOptions } from "ngx-owl-carousel-o";
import { environment } from "./../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { first } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { NguCarouselConfig } from "@ngu/carousel";

@Component({
  selector: "app-detalleproducto",
  templateUrl: "./detalleproducto.component.html",
  styleUrls: ["./detalleproducto.component.css"],
})
export class DetalleproductoComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoWidth: false,
    navText: ["<", ">"],
    responsive: {
      0: {
        items: 4,
      },
      400: {
        items: 4,
      },
      740: {
        items: 4,
      },
      940: {
        items: 2,
      },
    },
    nav: true,
  };
  anuncio: any = [];
  loading: boolean = false;
  sinResultados: boolean = false;
  textoBusqueda: string = "";
  mensajeForm: FormGroup;
  idUsuarioActual: string = localStorage.getItem("idUsuario");
  imagenActual: string;
  imgags = [
    "assets/bg.jpg",
    "assets/car.png",
    "assets/canberra.jpg",
    "assets/holi.jpg",
  ];
  public carouselTileItems: Array<any> = [];
  public carouselTiles = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  };
  public carouselTile: NguCarouselConfig = {
    grid: { xs: 2, sm: 3, md: 4, lg: 4, all: 0 },
    slide: 3,
    speed: 250,
    point: {
      visible: true,
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: "cubic-bezier(0, 0, 0.2, 1)",
  };
  constructor(
    private api: ApiService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.mensajeForm = new FormGroup({
      mensaje: new FormControl("", [Validators.required]),
    });
  }

  public carouselTileLoad(j) {
    // console.log(this.carouselTiles[j]);
    const len = this.carouselTiles[j].length;
    if (len <= 30) {
      for (let i = len; i < len + 15; i++) {
        this.carouselTiles[j].push(
          this.imgags[Math.floor(Math.random() * this.imgags.length)]
        );
      }
    }
  }
  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.cargarAnuncio(params["id"]);
      this.actualziarContador(params["id"]);
    });
    this.carouselTileItems.forEach((el) => {
      this.carouselTileLoad(el);
    });
  }

  actualziarContador(id) {
    this.api
      .putResponse("actualizarVistas/" + id, false)
      .pipe(first())
      .subscribe(
        (data) => {},
        (err) => {}
      );
  }

  async cargarAnuncio(id) {
    this.api
      .getResponse("anuncio/" + id, false)
      .pipe(first())
      .subscribe(
        (data) => {
          this.loading = false;
          this.anuncio = data[0];
          this.imagenActual = this.anuncio.imagenes[0];
          this.cargarAnunciosRelacionados(this.anuncio.categoriaId._id);
        },
        (err) => {}
      );
  }

  enviar(anuncio) {
    let params = {
      usuarioId: localStorage.getItem("idUsuario"),
      vendedorId: anuncio.usuarioId._id,
      anuncioId: anuncio._id,
      mensaje: this.mensajeForm.controls["mensaje"].value,
    };

    this.api
      .postResponse(
        "enviarmensaje/" + localStorage.getItem("idUsuario"),
        true,
        params
      )
      .pipe(first())
      .subscribe(
        (data) => {
          let id = "";
          if (params.usuarioId == localStorage.getItem("idUsuario")) {
            id = params.vendedorId;
          } else {
            id = params.usuarioId;
          }
          let param = {
            idUsuario: params.vendedorId,
            mensaje: this.mensajeForm.controls["mensaje"].value,
          };
          this.api
            .postResponse("notificacion", true, param)
            .pipe(first())
            .subscribe(
              (data) => {
                this.mensajeForm.controls["mensaje"].setValue("");
              },
              (err) => {}
            );
          this.mensajeForm.controls["mensaje"].setValue("");
          Swal.fire("LatinosVentas", "Se ha enviado tu mensaje", "success");
          this.mensajeForm.reset();
        },
        (err) => {}
      );
  }

  VerImagen(img) {
    this.imagenActual = img;
  }

  anunciosRealacionados: any = [];
  async cargarAnunciosRelacionados(catId) {
    this.api
      .getResponse("anuncioCategoria/" + catId, true)
      .pipe(first())
      .subscribe(
        (data) => {
          this.anunciosRealacionados = data;
          this.carouselTileItems = data
        },
        (err) => {}
      );
  }

  agregarFavoritos(item) {
    let usuario = {
      usuarioId: localStorage.getItem("idUsuario"),
      anuncioId: item._id,
    };

    if (item.tieneFavorito) {
      this.api
        .deleteResponse("favoritos/" + item._id, true)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha quitado de tus favoritos"
            );
          },
          (err) => {}
        );
    } else {
      this.api
        .postResponse("favoritos", true, usuario)
        .pipe(first())
        .subscribe(
          (data) => {
            item.tieneFavorito = !item.tieneFavorito;
            this.toastr.success(
              "LatinosVentas",
              "Se ha agregado a tus favoritos"
            );
          },
          (error) => {}
        );
    }
  }

  ObtenerUsername(nom) {
    if (nom == undefined) return "Anónimo";
    else return nom.substr(0, nom.indexOf("@"));
  }
}
